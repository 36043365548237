import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import QRCode from "react-qr-code";

import { CloseModalIcon } from "../components/CloseModalIcon";
import { getAttendeesByUser } from "../data/httpClient";
import { Attendee } from "../types";
import useEuser from "../utils/useEuser"; // access to provider via "use" hook

function AccountTickets() {
  const { euser } = useEuser();
  const [tickets, setTickets] = useState<Attendee[]>([]);
  const [qrValue, setQrValue] = useState<string | null>(null);

  const getTicketsResult = async () => {
    if (euser) {
      const result = await getAttendeesByUser(parseInt(euser.id));
      setTickets(result);
    }
  };

  useEffect(() => {
    getTicketsResult();
  }, [euser]);

  return (
    <div className="editPageWrapper">
      <h3 className="list_title">Entrance Passes</h3>
      <p>
        These are the tickets you will use as an attendee. To view a scannable
        version click &quot;QR code Pass&quot;.
      </p>

      <div className="ticket-container">
        {!tickets.length ? (
          <p>No Passes Found</p>
        ) : (
          tickets.map((t: Attendee) => {
            return (
              <div className="ticket-card" key={`ticket-${t.id}`}>
                <h4>{t.group.name}</h4>
                {t.group.round ? (
                  <p>
                    {t.group.round.name}
                    <br />
                    Time:{" "}
                    {dayjs(
                      `${t.group.round.startDate} ${t.group.round.startTime}`
                    ).format("M/D/YYYY h:mm A")}
                  </p>
                ) : (
                  <p>
                    Round: TBD
                    <br />
                    Time: TBD
                  </p>
                )}

                <button
                  className="qr-code-button"
                  type="button"
                  onClick={async () => {
                    setQrValue(t.scanCode);
                  }}
                >
                  QR code Pass
                </button>

                <h5>Group Members:</h5>

                <ul>
                  {t.group.attendees.map((attendee) => (
                    <li key={`attendee-${attendee.id}`}>
                      {attendee.user?.nameFirst || attendee.user?.nameLast
                        ? `${attendee.user.nameFirst} ${attendee.user.nameLast}`.trim()
                        : attendee.user?.email}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })
        )}
      </div>

      {/* QR Code Modal */}
      <section>
        <Modal
          isOpen={qrValue != null}
          onRequestClose={() => setQrValue(null)}
          className="standardModal qrCodeModal"
          contentLabel="QR Code Modal"
          ariaHideApp={false}
        >
          <CloseModalIcon onRequestClose={() => setQrValue(null)} />
          <div className="qrCodeModalContent">
            {qrValue && <QRCode value={qrValue} />}
          </div>
        </Modal>
      </section>
    </div>
  );
}

export default AccountTickets;
