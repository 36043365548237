import { createAttendee, getGroups, updateAttendee } from "./httpClient";

const addTeammateConfig = {
  name: "Teammate",
  onGet: getGroups,
  onCreate: createAttendee,
  onUpdate: updateAttendee,
  createAndEditFields: [
    {
      prompt:
        "In order to add an attendee to this group, enter the email address of the user.",
      fieldName: "email",
      label: "Email",
      inputType: "suggestion",
      isRequired: false,
      isMutable: true,
    },
    {
      prompt:
        "If the email address is associated with a user who is NOT yet in this system, you may provide the user's first and last name. Otherwise, the user can set these values when registering for the event.",
      fieldName: "newUserNameFirst",
      label: "First Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
    },
    {
      fieldName: "newUserNameLast",
      label: "Last Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
    },
  ],
};

export default addTeammateConfig;
