import { createUserSponsor } from "./httpClient";

const userSponsorConfig = {
  name: "user",
  onCreate: createUserSponsor,
  // onUpdate: updateUser,
  createAndEditFields: [
    {
      fieldName: "userId",
      label: "User",
      inputType: "suggestion", // [X] TODO - only partialy implemented
      isRequired: true,
      isMutable: true,
    },
    {
      fieldName: "sponsorId",
      label: "Sponsor",
      inputType: "dropdown",
      isRequired: true,
      isMutable: true,
    },
  ],
};

export default userSponsorConfig;
