export const filterUnpopulatedFields = (obj: { [key: string]: any }) => {
  const populatedFields = Object.entries(obj).filter(
    (entry) => entry[1] !== null && entry[1] !== undefined
  );
  const populatedObj: { [key: string]: any } = {};
  for (const entry of populatedFields) {
    populatedObj[entry[0]] = entry[1];
  }
  return populatedObj;
};
