import { IconContext } from "react-icons";
import { IoCloseOutline } from "react-icons/io5";

interface CloseModalIconProps {
  onRequestClose: () => void;
}

export const CloseModalIcon = ({ onRequestClose }: CloseModalIconProps) => {
  return (
    <div className="closeModalIconContainer">
      <button onClick={() => onRequestClose()}>
        <IconContext.Provider value={{ className: "icon" }}>
          <IoCloseOutline />
        </IconContext.Provider>
      </button>
    </div>
  );
};
