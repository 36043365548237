import { useEffect, useRef } from "react";

export const useClickOutside = (
  handler: () => void,
  specificTargetHandler?: (arg0: any) => void
) => {
  const domNode = useRef();

  useEffect(() => {
    const maybeHandler = (event: { target: any }) => {
      // @ts-ignore
      if (!domNode.current.contains(event.target)) {
        handler();

        if (specificTargetHandler) {
          specificTargetHandler(event.target);
        }
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};
