import { createWidget, updateWidget } from "./httpClient";

const widgetPostConfig = {
  name: "widgetPost",
  onCreate: createWidget,
  onUpdate: updateWidget,
  createAndEditFields: [
    {
      fieldName: "name",
      label: "Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
    },
    {
      fieldName: "content",
      label: "Content",
      inputType: "wysiwyg",
      isRequired: false,
      isMutable: true,
    },
    {
      fieldName: "liveFlag",
      label: "Live",
      inputType: "toggle",
      isRequired: false,
      isMutable: true,
    },
  ],
};

export default widgetPostConfig;
