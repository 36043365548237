// utility function for rendering each form field

import { FormField } from "../components/FormField";
import { Field, FormDataType } from "../types";

export const renderFormField = (
  field: Field,
  formData: FormDataType,
  setFormKeyValue: ({
    key,
    value,
  }: {
    key: string;
    value: string | boolean;
  }) => void,
  formMode: string,
  fieldOptionData?: any[],
  // Optional - can be passed in to make fields unique
  // i.e. multiple forms on one page - see ContentPage.tsx
  recordId?: number
) => {
  const inputValue = formData[field.fieldName];
  return (
    <FormField
      key={`form-${field.fieldName}`}
      id={field.fieldName}
      title={field.title}
      prompt={field.prompt}
      fieldName={field.label}
      type={field.inputType}
      helperText={field.helperText}
      inputValue={inputValue}
      setInputValue={(value: string | boolean) => {
        setFormKeyValue({ key: field.fieldName, value });
      }}
      isRequired={field.isRequired}
      isMutable={field.isMutable}
      formMode={formMode}
      fieldOptionData={fieldOptionData || field.options}
      minValue={field.minValue}
      autoComplete={field.autoComplete}
      // Set recordId to 0 if not explicitly passed in
      recordId={recordId ? recordId : 0}
    />
  );
};
