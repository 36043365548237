import "../assets/scss/main.scss";

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EuserContext } from "../data/providers/EuserProvider";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { TicketCategory } from "../types";

// ToDo: may want to make more DRY if continue to maintain this codebase; see Tickets & Registration section of EventSideBar.tsx
export const TournamentRegisterPage = () => {
  const { tournamentId } = useParams();
  const navigate = useNavigate();

  const {
    setTournamentById,
    getTicketCategoriesState,
    tournamentState,
    ticketCategoriesState,
  } = useTournamentStateContext();

  const { euser } = useContext(EuserContext);

  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    let active = true;

    (async () => {
      try {
        setIsDataLoading(true);
        const id = parseInt(tournamentId ?? "");
        // It'd be better to check "active" (to prevent race conditions) after each data fetching but before each state setting vs before an encapsulating function.
        // Checking "active" before calling setTournamentId would likely be ineffective due to the useEffect's speed of execution.
        await setTournamentById(id);
        if (active) {
          await getTicketCategoriesState(id);
        }
      } catch (error) {
        // request errors (i.e. axios errors) already print to the console and alert user of error (see httpClient.ts)
        if (!axios.isAxiosError(error)) {
          console.error("Error fetching data:", error);
          alert("There was an error fetching data.");
        }
      } finally {
        if (active) {
          setIsDataLoading(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [tournamentId]);

  const handleRegisterButtonClick = (ticketCategory: TicketCategory) => {
    if (!euser) {
      navigate("/account/signin");
    } else {
      const attendeeOrSponsor = ticketCategory.sponsorFlag
        ? "sponsor"
        : "attendee";
      navigate(
        `/tournaments/${tournamentState?.id}/admin/tickets/${attendeeOrSponsor}/new`,
        { state: { categoryId: ticketCategory.id } }
      );
    }
  };

  const allSoldOut = ticketCategoriesState
    ? ticketCategoriesState.every(
        (category) =>
          category.availableTickets <= 0 || category.availableTickets === null
      )
    : false;

  return (
    <div className="tournament-register-wrapper">
      <h3>Tickets & Registration</h3>
      <div className="category-container">
        {isDataLoading && <p>Loading...</p>}
        {/* Prevents sold out message from rendering prematurely.*/}
        {ticketCategoriesState &&
          ticketCategoriesState.length > 0 &&
          allSoldOut && (
            <div className="data-point" id="sold-out-msg">
              <p className="computed-text not-good">
                All ticket packages are sold out for this event. Please{" "}
                <a href="https://www.hcahopefund.com/golfwaitlist/">
                  join the waitlist.
                </a>
              </p>
            </div>
          )}
        {ticketCategoriesState &&
          ticketCategoriesState.map((category, index) => (
            <div key={category.id || index} className="tick-regs">
              <div className="data-point">
                <h5>{category.name}</h5>
                <p>{category.description}</p>

                {category.availableTickets > 0 &&
                category.availableTickets != null ? (
                  <>
                    <p className="computed-text">
                      Ticket Packages Available: {category.availableTickets}
                    </p>
                    <div>
                      <button
                        className="btn btn-blue"
                        onClick={() => handleRegisterButtonClick(category)}
                      >
                        Register
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="computed-text not-good">
                      Tickets are unavailable for this category; please consider
                      another option.
                    </p>
                    <div>
                      <button className="btn btnPrimaryDisabled" disabled>
                        Unavailable
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TournamentRegisterPage;
