import { useContext, useEffect, useState } from "react";

import FileImageManager from "../components/FileImageManager";
import FormFields from "../components/FormFields";
import { getWidgets, updateWidget } from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import {
  WidgetContext,
  WidgetContextInterface,
} from "../data/providers/WidgetProvider";
import widgetPostConfig from "../data/widgetPostConfig";
import { FormDataType, Widget } from "../types";
import { filterUnpopulatedFields } from "../utils/filterUnpopulatedFields";

interface WidgetDetailProps {
  tournamentId: number;
  widget: Widget;
}

export const WidgetDetailPage = ({
  tournamentId,
  widget,
}: WidgetDetailProps) => {
  const { setTournamentIdState } = useTournamentStateContext();
  const { setWidgets } = useContext(WidgetContext) as WidgetContextInterface;
  const widgetSupportsPhotos = widget.widgetType !== "Post";

  useEffect(() => {
    if (tournamentId) {
      setTournamentIdState(tournamentId);
    }
  }, [tournamentId]);

  useEffect(() => {
    if (!widget) return;
    const populatedWidgetFields = filterUnpopulatedFields(widget);
    setFormData((prev) => ({ ...prev, ...populatedWidgetFields }));
  }, [widget]);

  const [formData, setFormData] = useState<FormDataType>(
    widgetPostConfig.createAndEditFields.reduce(
      (prev, field) => ({
        ...prev,
        [field.fieldName]: "",
        tournamentId: tournamentId,
      }),
      {}
    )
  );

  const afterUpdateFunction = async () => {
    setWidgets(await getWidgets(tournamentId));
  };

  return (
    <div className="widgetDetailPageWrapper">
      <h5>Content</h5>
      <section className="widgetFormWrapper">
        <FormFields
          fields={widgetPostConfig.createAndEditFields}
          form={{ method: "edit", data: formData }}
          setFormData={(_, data) => setFormData(data)}
          formMode={"edit"}
          name={widget.name}
          onUpdateFunction={updateWidget}
          showBackButton={false}
          afterUpdateFunction={afterUpdateFunction}
          recordId={widget.id}
        />
      </section>

      {widgetSupportsPhotos ? (
        <FileImageManager
          label="Images"
          parentType="widget"
          parentId={widget.id}
          multiple={true}
        />
      ) : (
        <em>* This widget type does not support photos</em>
      )}
    </div>
  );
};
