import { useContext } from "react";

import { EuserContext } from "../data/providers/EuserProvider";

const useEuser = () => {
  const context = useContext(EuserContext);

  if (context === null) {
    throw new Error("useEuser must be used within a EuserProvider");
  }

  return context;
};

export default useEuser;
