import "../assets/scss/main.scss";

import axios from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  getAttendeeRosterDataExport,
  getComprehensiveDataExport,
} from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";

const dataExportConfig = {
  attendeeRoster: {
    nameQualifier: "attendee_roster",
    apiCall: getAttendeeRosterDataExport,
  },
  comprehensive: {
    nameQualifier: "comprehensive",
    apiCall: getComprehensiveDataExport,
  },
};

export const DataExportPage = () => {
  const { tournamentId } = useParams();
  const [disableDownloadBtn, setDisableDownloadBtn] = useState(false);
  const { setTournamentById, tournamentState } = useTournamentStateContext();

  useEffect(() => {
    (async () => {
      try {
        const tournId = parseInt(tournamentId ?? "");
        await setTournamentById(tournId);
      } catch (error) {
        // request errors (i.e. axios errors) already print to the console and alert user of error (see httpClient.ts)
        if (!axios.isAxiosError(error)) {
          console.error(error);
          alert("An error occurred.");
        }
      }
    })();
  }, [tournamentId]);

  async function downloadCsv(dataExportType: keyof typeof dataExportConfig) {
    try {
      setDisableDownloadBtn(true);
      const { nameQualifier, apiCall } = dataExportConfig[dataExportType];
      const dataExport = await apiCall(tournamentState.id);
      if (!dataExport.length) {
        alert("We couldn't find any data for that tournament.");
        return;
      }
      const columnsToExclude = ["$id"];
      const columnsToInclude = Object.keys(dataExport[0]).filter(
        (x) => !columnsToExclude.includes(x)
      );
      const csv = stringify(dataExport, {
        bom: true,
        header: true,
        columns: columnsToInclude,
        cast: {
          boolean: function (value) {
            return value ? "TRUE" : "FALSE"; // use what Excel uses for boolean values instead of the default behavior of "1" for true & an empty string for false
          },
        },
        escape_formulas: true,
      });
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.download = `${nameQualifier}_data_export_${dayjs(new Date()).format("YYYY-MM-DD-HHmmss")}`; // filename
      const blobUrl = window.URL.createObjectURL(blob);
      link.href = blobUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      alert("Download successful!");
      URL.revokeObjectURL(blobUrl);
    } catch (error: any) {
      // request errors (i.e. axios errors) already print to the console and alert user of error (see httpClient.ts)
      if (!axios.isAxiosError(error)) {
        console.error(error);
        alert("An error occurred.");
      }
    } finally {
      setDisableDownloadBtn(false);
    }
  }

  return (
    <div className="dataExportPageWrapper">
      <h5 className="title">Data Export</h5>
      <p>The buttons below download CSV data for the following tournament:</p>
      <p className="tournamentName">{tournamentState.name}</p>
      <div className="btnContainer">
        <button
          type="button"
          onClick={() => downloadCsv("attendeeRoster")}
          disabled={disableDownloadBtn}
        >
          Attendee Roster Data Export
        </button>
        <button
          type="button"
          onClick={() => downloadCsv("comprehensive")}
          disabled={disableDownloadBtn}
        >
          Comprehensive Data Export
        </button>
      </div>
    </div>
  );
};
