import { createUser, updateUser, updateUserPassword } from "./httpClient";

export const userConfig = {
  name: "user",
  onCreate: createUser,
  onUpdate: updateUser,
  onPasswordUpdate: updateUserPassword,
  createAndEditFields: [
    {
      fieldName: "email",
      label: "Email",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "email",
    },
    {
      fieldName: "nameFirst",
      label: "First Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "given-name",
    },
    {
      fieldName: "nameLast",
      label: "Last Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "family-name",
    },
    {
      fieldName: "phone",
      label: "Phone",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "tel",
    },
    // Needs to be read-only if we want user to see this.
    // Not fully implemented for user. only admin.
    {
      fieldName: "roleArray",
      label: "Role",
      helperText:
        "Hold down the Ctrl (windows) or Command (Mac) button to select multiple options.",
      inputType: "multiselect",
      isRequired: false,
      isMutable: true,
    },
  ],

  euserEditFields: [
    {
      fieldName: "email",
      label: "Email",
      // helperText: "You Cannot Edit this",
      inputType: "text",
      isRequired: false,
      isMutable: false,
      autoComplete: "email",
    },
    {
      fieldName: "nameFirst",
      label: "First Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "given-name",
    },
    {
      fieldName: "nameLast",
      label: "Last Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "family-name",
    },
    {
      fieldName: "phone",
      label: "Phone",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "tel",
    },
    // Needs to be read-only if we want user to see this.
    // Not fully implemented for user. only admin.
    // {
    //   fieldName: "roleArray",
    //   label: "Role",
    //   helperText:
    //     "Hold down the Ctrl (windows) or Command (Mac) button to select multiple options.",
    //   inputType: "multiselect",
    //   isRequired: false,
    //   isMutable: true,
    // },
  ],

  passwordUpdateFields: [
    {
      fieldName: "password",
      label: "Password",
      inputType: "password",
      isRequired: false,
      isMutable: true,
      autoComplete: "new-password",
    },
    {
      fieldName: "passwordConfirmation",
      label: "Password Confirmation",
      inputType: "password",
      isRequired: false,
      isMutable: true,
      autoComplete: "new-password",
    },
  ],
};

export default userConfig;
