import React from "react";

type DateRangeFormatterProps = {
  startDate?: string;
  endDate?: string;
};

const DateRangeFormatter: React.FC<DateRangeFormatterProps> = ({
  startDate,
  endDate,
}) => {
  const getOrdinalNum = (n: number) => {
    const ordinals = ["th", "st", "nd", "rd"];
    const value = n % 100;
    return n + (ordinals[(value - 20) % 10] || ordinals[value] || ordinals[0]);
  };

  const formatDate = (date?: string) => {
    if (!date) return "";
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return "";
    const dayWithOrdinal = getOrdinalNum(parsedDate.getDate());
    return `${new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      parsedDate
    )} ${dayWithOrdinal}, ${parsedDate.getFullYear()}`;
  };

  const startDateFormatted = startDate ? formatDate(startDate) : "";
  const endDateFormatted = endDate ? formatDate(endDate) : "";

  if (startDateFormatted && endDateFormatted) {
    // If both dates are provided and they are equal, only show one date
    if (startDateFormatted === endDateFormatted) {
      return <span>{startDateFormatted}</span>;
    } else {
      // If both dates are provided and they are different, show the date range
      return (
        <span>
          {startDateFormatted} - {endDateFormatted}
        </span>
      );
    }
  } else {
    // If only one date is provided, show that date
    return <span>{startDateFormatted || endDateFormatted}</span>;
  }
};

export default DateRangeFormatter;
