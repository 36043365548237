import React, { useEffect, useState } from "react";

import { useClickOutside } from "../utils/useClickOutside";

interface AutoPopulateInputProps {
  id: string;
  data: any;
  searchInputValue: string;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputValue: any;
  setInputValue: (value: any) => void;
  optionKeyAttribute: string;
  optionValueAttribute: string;
  returnNonMatchingInput: boolean;
}

export const AutoPopulateInput = ({
  id,
  data,
  searchInputValue,
  setSearchInputValue,
  inputValue,
  setInputValue,
  optionKeyAttribute,
  optionValueAttribute,
  returnNonMatchingInput,
}: AutoPopulateInputProps) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); //for matched suggestions
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cursor, setCursor] = useState(-1);

  useEffect(() => {
    if (!data?.length || !inputValue) return;

    setInputValue(inputValue);
    const dataMatch = data.find(
      (datum: { [key in typeof optionValueAttribute]: number | string }) =>
        datum[optionKeyAttribute] === inputValue
    );
    dataMatch && setSearchInputValue(dataMatch[optionValueAttribute]);
  }, [inputValue, data]);

  const onChange = (e: { target: { value: string } }) => {
    const userInput = e.target.value;

    if (!userInput || userInput.trim().length === 0) {
      setInputValue("");
      setSearchInputValue("");
      return;
    }

    const matchedSuggestions = data
      .sort()
      .filter((suggestion: { suggestionInfo: string }) =>
        suggestion.suggestionInfo
          .toLowerCase()
          .includes(userInput.toLowerCase())
      );
    setSearchInputValue(e.target.value); //set the input field value
    setFilteredSuggestions(matchedSuggestions); //set suggestions
    setShowSuggestions(true); //show suggestions dropdown
  };

  /* create an onClick event that will be emitted when a user clicks on a suggestion.
    then use setState to update the user’s input and reset our current state. */
  const onClick = (e: any, id: string) => {
    setFilteredSuggestions([]);
    setCursor(-1);
    setSearchInputValue(e.target.innerText);
    setInputValue(id);
    setShowSuggestions(false);
  };

  // onBlur, if we returnNonMatchingInput.
  // (currently only for email matching on group assembly)
  // we will return any value in the text input.
  const onBlur = (e: any) => {
    if (returnNonMatchingInput) {
      // console.log(`Bluring & Setting value to ${e.target.value}`)
      setInputValue(e.target.value);
    }
  };

  // NOT WORKING DEC 19 2023
  /* When a user is given two or more suggestions,
    the user can use the keyDown button to select any of the autocomplete suggestions. */
  const onKeyDown = (key: { keyCode: number }) => {
    // keyCode 13, enter keycode 9, tab
    if (key.keyCode === 13 || key.keyCode === 9) {
      setSearchInputValue(filteredSuggestions[cursor ? cursor : 0]);
      setFilteredSuggestions([]);
      setCursor(-1);
    }

    if (key.keyCode === 40) {
      setCursor(cursor + 1);
    }

    if (key.keyCode === 13) {
      setCursor(cursor - 1);
    }
  };

  const renderSuggestions = () => {
    if (filteredSuggestions.length && showSuggestions) {
      return (
        <ul className="suggestions" id="location-dropdown">
          {filteredSuggestions.map(
            (
              suggestion: {
                id: string;
                suggestionInfo: string;
                [key: string]: string;
              },
              i
            ) => {
              // console.log(suggestion.id)
              return (
                <li
                  key={`suggestion${i}`}
                  className={cursor === i ? "active" : ""}
                  onClick={(e) => onClick(e, suggestion[optionKeyAttribute])} // Pass the value back here
                >
                  {suggestion[optionValueAttribute]}
                </li>
              );
            }
          )}
        </ul>
      );
    }
  };

  // use the hook to handle the outside click
  const domNode = useClickOutside(() => {
    setShowSuggestions(false);
  });

  return (
    // @ts-ignore
    <div ref={domNode}>
      <div className="autoPopulateInputWrapper">
        <input
          id={id}
          className="autoPopulateInput"
          type="text"
          placeholder="start typing..."
          name="search"
          value={searchInputValue}
          onChange={onChange}
          onBlur={onBlur}
          onClick={() => setShowSuggestions(true)}
          onKeyDown={onKeyDown} //auto populate selection
          autoComplete="off"
        />
      </div>

      {renderSuggestions()}
    </div>
  );
};
