// ToDo: We took FortyAu's payment modal logic, changed it, and created this component.
// Their logic passed in a ticket category's embed code to the BlackbaudPayment.tsx component as its form Id.
// Admin users can edit these embed codes in the UI by navigating to the Attendee and Sponsor Ticket Categories pages.
// However, since we currently don't have valid embed codes for each ticket category, we are simply displaying an external link to the
// Blackbaud form instead.
import Modal from "react-modal";

import { CloseModalIcon } from "../components/CloseModalIcon";

interface PaymentModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  isSponsorTicketCategory: boolean | null;
}

const getOnlinePurchaseLink = (isSponsorTicketCategory: boolean | null) => {
  return isSponsorTicketCategory
    ? `https://www.hcahopefund.com/2024golfearlyregistration/`
    : `https://host.nxt.blackbaud.com/donor-form/?svcid=tcs&formId=9bc3cbd6-0e4a-4141-a9bc-f24e7915977f&envid=p-2UFUwhxy4Ue3aklntJyahA&zone=usa`;
};

export const PaymentModal = ({
  isOpen,
  onRequestClose,
  isSponsorTicketCategory,
}: PaymentModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      className="standardModal"
      contentLabel="Payment Modal"
      ariaHideApp={false}
    >
      <CloseModalIcon onRequestClose={() => onRequestClose()} />
      <div className="paymentModalContent">
        <h3 className="title">Payment Options</h3>
        <p>
          You can purchase your ticket online with a credit card or send a
          check.
        </p>
        <h5 className="paymentTypeHeading">Online</h5>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={getOnlinePurchaseLink(isSponsorTicketCategory)}
        >
          Purchase online
        </a>
        <h5 className="paymentTypeHeading">Check</h5>
        <p>
          Please make your check payable to &quot;HCA Healthcare Hope
          Fund&quot;. You can send your check either by mail or FedEx.
        </p>
        <h6 className="checkSubheading">Mail</h6>
        HCA Healthcare Hope Fund <br />
        PO Box 440252 <br />
        Nashville, TN 37203
        <h6 className="checkSubheading">FedEx</h6>
        Truist, Inc. <br />
        Attention: Lockbox Dept. <br />
        c/o HCA Hope Fund #440252 <br />
        801 Royal Parkway, Floor 1 <br />
        Nashville, TN 37214
        <div className="helpText">
          It may take some time for us to process your payment, so your reserved
          ticket may appear unpaid for awhile. If you have any questions or
          would like to request an invoice, please contact us at{" "}
          <a href="mailto:golfforhope@hcahealthcare.com?subject=Payments">
            golfforhope@hcahealthcare.com
          </a>
          .
        </div>
      </div>
    </Modal>
  );
};
