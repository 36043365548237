import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { unlinkSvg } from "../assets/icons";
import FileImageManager from "../components/FileImageManager";
import FormFields from "../components/FormFields";
import {
  deleteUserSponsor,
  getSponsorById,
  getUserById,
  getUserSponsorsBySponsor,
} from "../data/httpClient";
import { authRecover, getAllRoles } from "../data/httpClient";
import { EuserContext } from "../data/providers/EuserProvider";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import sponsorConfig from "../data/sponsorConfig";
import userConfig from "../data/userConfig";
import { FormDataType, Role, UserSponsor } from "../types";
import { EuserRole } from "../types/euserRole";

function EditSponsorOrUserPage() {
  const { state } = useLocation();

  const { euser } = useContext(EuserContext);

  // Set the type of what we are editing.
  let type: string;
  if (state?.type != null) {
    // console.log(state)
    type = state.type;
  } else {
    type = "sponsor";
  }

  const { tournamentId, userOrSponsorId } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  const [roleDropdownData, setRoleDropdownData] = useState<Role[]>([]);

  const [formData, setFormData] = useState<FormDataType>(
    (type === "sponsor"
      ? sponsorConfig
      : userConfig
    ).createAndEditFields.reduce(
      (prev, field) => ({
        ...prev,
        // @ts-ignore
        [field.fieldName]: "",
      }),
      {}
    )
  );

  const [userSponsorData, setUserSponsorData] = useState<UserSponsor[]>([]);

  useEffect(() => {
    // console.log("type", type);
    // console.log("userOrSponsorId", userOrSponsorId);
    if (userOrSponsorId) {
      getData(parseInt(userOrSponsorId));
    }
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
    }
  }, [userOrSponsorId]);

  const getData = async (userOrSponsorId: number) => {
    const roles = await getAllRoles();
    setRoleDropdownData(roles);

    let userOrSponsor = { id: 0, uid: 0, provider: "" };
    if (type === "sponsor") {
      const sponsorRecord = await getSponsorById(userOrSponsorId);
      userOrSponsor = sponsorRecord;
      getUserSponsorData();
    }
    if (type === "user") {
      const result = await getUserById(userOrSponsorId);
      // console.log(result)
      userOrSponsor = result;
    }

    setFormData(
      (type === "sponsor"
        ? sponsorConfig
        : userConfig
      ).createAndEditFields.reduce(
        (prev, field) => ({
          ...prev,
          [field.fieldName]:
            // @ts-ignore
            userOrSponsor[field.fieldName] ?? formData[field.fieldName],
          id: userOrSponsor.id,
          uid: type === "user" ? userOrSponsor.uid : null,
          provider: type === "user" ? userOrSponsor.provider : null,
        }),
        {}
      )
    );
  };

  const getUserSponsorData = async () => {
    if (
      type === "sponsor" &&
      userOrSponsorId !== undefined &&
      euser?.roles.includes(EuserRole.Admin)
    ) {
      const userSponsorRecords = await getUserSponsorsBySponsor(
        parseInt(userOrSponsorId)
      );
      setUserSponsorData(userSponsorRecords);
    }
  };

  const resetUserPassword = async (formData: FormDataType) => {
    try {
      // Your recover function call
      authRecover({
        Email: formData.email,
        IsAdminRecovery: true,
      });
    } catch (error: any) {
      // Handle the error here
      console.error("Error during recover function call:", error.message);
    }
  };

  const fieldOptionData = {
    roleArray: roleDropdownData,
  };

  return (
    <>
      <div className="editPageWrapper">
        {/* Inline title case. user -> User */}
        <h3 className="list_title">
          {" "}
          {type.charAt(0).toUpperCase() + type.slice(1)} Edit
        </h3>

        <FormFields
          fields={
            (type === "sponsor" ? sponsorConfig : userConfig)
              .createAndEditFields
          }
          form={{ method: "edit", data: formData }}
          setFormData={(_, data) => setFormData(data)}
          formMode={"edit"}
          name={(type === "sponsor" ? sponsorConfig : userConfig).name}
          onCreateFunction={
            (type === "sponsor" ? sponsorConfig : userConfig).onCreate
          }
          onUpdateFunction={
            (type === "sponsor" ? sponsorConfig : userConfig).onUpdate
          }
          fieldOptionData={fieldOptionData}
        />
      </div>

      {type === "sponsor" && userOrSponsorId && (
        <div className="editPageWrapper">
          <FileImageManager
            label={"Logo"}
            helperText={
              "*Please upload a high-resolution logo with transparent background, tightly cropped."
            }
            parentType={"sponsor"}
            parentId={parseInt(userOrSponsorId)}
            multiple={false}
          />
        </div>
      )}

      {type === "sponsor" && euser?.roles.includes(EuserRole.Admin) && (
        <div className="editPageWrapper">
          <h5 className="list_title">User Sponsor Records</h5>

          {!userSponsorData.length ? (
            <p>There are no linked users.</p>
          ) : (
            <table className="ticketsTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {userSponsorData.map((us: UserSponsor, i: number) => {
                  return (
                    <tr key={i}>
                      <td>
                        {us.user?.nameFirst} {us.user?.nameLast}
                      </td>
                      <td>{us.user?.email}</td>
                      <td>{us.user?.phone}</td>

                      <td
                        className="tableLink"
                        onClick={async () => {
                          if (
                            window.confirm("Are you sure to unlink this user?")
                          ) {
                            await deleteUserSponsor(us.id);
                            console.log("user sponsor record deleted.");
                            getUserSponsorData();
                          }
                        }}
                      >
                        <img
                          src={unlinkSvg}
                          className="iconAction"
                          alt="Unlink User"
                        />
                        &nbsp;
                        <span>Unlink User</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}

      {type === "user" && (
        <div className="editPageWrapper">
          <h5 className="list_title">Sign In Credentials</h5>

          <p>
            This will generate a temporary authentication code for the user. It
            will then be shared via email along with instructions for use.
            <br />
            This process mirrors the self-initiated recovery workflow available
            to the user, while extending code for a period of 24 hours.
          </p>

          <button
            type="button"
            className="newCategoryBtn"
            onClick={() => {
              resetUserPassword(formData);
            }}
          >
            Send AuthCode access email
          </button>
        </div>
      )}
    </>
  );
}

export default EditSponsorOrUserPage;
