import "../assets/scss/main.scss";

import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { exitSvg, hamburgerSvg } from "../assets/icons";
import { EuserContext } from "../data/providers/EuserProvider";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import EuserActionItems from "./Euser";
import TournamentActionItems from "./Tournament";

export const EditNav = () => {
  const navigate = useNavigate();
  const { tournamentId } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;

  const { tournamentState, tournamentIdState, setTournamentIdState } =
    useTournamentStateContext();

  const euserContext = useContext(EuserContext);
  const { euser, euserIsAdmin, euserIsEditor } = euserContext;

  useEffect(() => {
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
    }
  }, [tournamentId]);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [itemSelected, setItemSelected] = useState("");

  const generalEditItems = [
    {
      id: `general-item-2`,
      item: "General Info",
      link: `${tournamentIdState}/edit`,
      roles: ["Admin", "Content Editor"],
    },
    {
      id: `general-item-3`,
      item: "Attendee Ticket Categories",
      link: `${tournamentIdState}/admin/ticket_categories/attendee`,
      roles: ["Admin"],
    },
    {
      id: `general-item-4`,
      item: "Sponsor Ticket Categories",
      link: `${tournamentIdState}/admin/ticket_categories/sponsor`,
      roles: ["Admin"],
    },
    {
      id: `general-item-5`,
      item: "Schedule",
      link: `${tournamentIdState}/admin/rounds/new`,
      roles: ["Admin"],
    },
    {
      id: `general-item-6`,
      item: "Content",
      link: `${tournamentIdState}/admin/content/widgets`,
      roles: ["Admin"],
    },
  ];

  const administrationItems = [
    {
      id: "admin-item-1",
      item: "Attendee Tickets",
      link: `${tournamentIdState}/admin/tickets/attendee`,
    },
    {
      id: "admin-item-2",
      item: "Sponsor Tickets",
      link: `${tournamentIdState}/admin/tickets/sponsor`,
    },
    {
      id: "admin-item-3",
      item: "Users/Sponsors",
      link: `${tournamentIdState}/admin/user-sponsor`,
    },
    {
      id: "admin-item-4",
      item: "Mobile Check-in",
      link: `${tournamentIdState}/admin/check-in`,
    },
    {
      id: "admin-item-5",
      item: "Data Export",
      link: `${tournamentIdState}/admin/data-export`,
    },
  ];

  const renderGeneralList = () => {
    return generalEditItems.map((m, i) => {
      // look at general edit item role.
      // compare to euser?.roles
      // look for overlap
      // if user has role needed for item.
      const hasRequiredRole =
        euser &&
        euser?.roles.filter((role: string) => m.roles.includes(role)).length >
          0;

      // Only render the item if the user has the required role
      if (hasRequiredRole) {
        return (
          <li
            className={`editMenuItem ${
              ((currentPath.includes(
                `/tournaments/${tournamentIdState}/edit`
              ) &&
                m.id === "general-item-2") ||
                m.id === itemSelected) &&
              "menuItemSelected"
            }`}
            key={`general-option-${i}`}
          >
            <button
              type="button"
              className="BlueNavOption"
              onClick={() => {
                navigate(m.link, {
                  state: {
                    tournament: tournamentState,
                  },
                });
                setItemSelected(m.id);
                setShowMobileMenu(false);
              }}
            >
              {m.item}
            </button>
          </li>
        );
      }
      return null; // Return null if the user doesn't have the required role
    });
  };

  const renderAdministrationList = () => {
    return administrationItems.map((a, i) => {
      return (
        <li
          className={`editMenuItem ${
            a.id === itemSelected && "menuItemSelected"
          }`}
          key={`admin-option-${i}`}
        >
          <button
            type="button"
            className="BlueNavOption"
            onClick={() => {
              navigate(a.link);
              setItemSelected(a.id);
              setShowMobileMenu(false);
            }}
          >
            {a.item}
          </button>
        </li>
      );
    });
  };

  const renderMenu = () => {
    return (
      <>
        {<TournamentActionItems />}
        <hr className="mobileBlueNavLine" />

        {euserIsAdmin() || euserIsEditor() ? (
          <>
            <ul className="editMenu">{renderGeneralList()}</ul>
            <hr className="mobileBlueNavLine" />
          </>
        ) : null}

        {euserIsAdmin() ? (
          <>
            <h3 className="BlueNavTitle">Tournament Administration</h3>
            <ul className="editMenu">{renderAdministrationList()}</ul>
            <hr className="mobileBlueNavLine" />
          </>
        ) : null}

        {<EuserActionItems />}
      </>
    );
  };

  return (
    <>
      {/* mobile hamburger */}
      <div className="mobileBlueNavWrapper">
        <button
          type="button"
          className="mobileBlueNavOpen"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <img src={hamburgerSvg} alt="open nav menu" />
        </button>

        {showMobileMenu && (
          <section className="mobileBlueNavMenu">
            <button
              type="button"
              className="mobileBlueNavClose"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <img src={exitSvg} alt="close nav menu" />
            </button>
            {renderMenu()}
          </section>
        )}
      </div>

      {/* desktop side nav */}
      <div className="BlueNavWrapper">{renderMenu()}</div>
    </>
  );
};
