import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

import { EuserContext } from "../data/providers/EuserProvider";

function ManageButtons() {
  const { tournamentId } = useParams();

  const euserContext = useContext(EuserContext);
  const { euser, euserIsAdmin, euserIsEditor, signOut } = euserContext;

  const signOutTrigger = () => {
    signOut();
    // no need to navigate away. they are on the home page
  };

  return (
    <div>
      {/* If there is an euser in the system */}
      {euser ? (
        <div className="btn-row">
          <Link to={`/tournaments/${tournamentId}/register`}>
            <button className="btn btn-blue">Register</button>
          </Link>

          {euserIsAdmin() || euserIsEditor() ? (
            <Link to={`/tournaments/${tournamentId}/edit`}>
              <button className="btn btn-green">Admin</button>
            </Link>
          ) : null}

          <Link to={`/account/reservations`}>
            <button className="btn btn-green">Tickets</button>
          </Link>

          <Link to={`/account/edit`}>
            <button className="btn btn-green">Account</button>
          </Link>

          <button className="btn btn-white" onClick={signOutTrigger}>
            Sign Out
          </button>
        </div>
      ) : (
        // If there is not a euser
        <div className="btn-row">
          <Link to={`/account/signin`}>
            <button className="btn btn-blue">Sign In</button>
          </Link>
        </div>
      )}
    </div>
  );
}

//   http://localhost:5173/tournaments/1/edit

export default ManageButtons;
