// component for each form field

import "../assets/scss/main.scss";

import dayjs from "dayjs";
import { useState } from "react";

import { uploadSvg } from "../assets/icons";
import { AutoPopulateInput } from "./AutoPopulateInput";
import { RichEditor } from "./RichEditor";

type FormFieldProps = {
  id: string; //fieldName
  inputValue: any;
  setInputValue: (value: any) => void;
  type?: string;
  maxLength?: number;
  title?: string;
  prompt?: string;
  fieldName: string;
  placeholderText?: string;
  helperText?: string;
  isRequired?: boolean;
  isMutable?: boolean;
  formMode?: string;
  fieldOptionData?: any[];
  autoComplete?: string;
  recordId?: number;
  minValue?: number;
};

export const FormField = ({
  id,
  inputValue,
  setInputValue,
  type = "text",
  title,
  prompt,
  fieldName, //config-label
  placeholderText,
  helperText,
  isRequired = false,
  isMutable,
  formMode = "create",
  fieldOptionData,
  autoComplete,
  recordId,
  minValue = 0,
}: FormFieldProps) => {
  const [radioBtnInputValue, setRadioBtnInputValue] = useState("");

  const [checkboxData, setCheckboxData] = useState(
    fieldOptionData?.map((d) => {
      return {
        value: d,
        checked: false,
      };
    })
  );

  const [searchSuggestion, setSearchSuggestion] = useState("");

  const handleControlledInputChange = (event: any) => {
    // console.log("input change")

    setInputValue(event.target.value);

    if (type === "number") {
      // console.log(type)
      const numericalValue = Number(event.target.value);
      // Check if the value is a number and not negative
      if (!isNaN(numericalValue) && numericalValue >= 0) {
        // set what was passed in
        setInputValue(parseInt(event.target.value));
      } else {
        // else make it zero
        setInputValue(parseInt("0"));
      }
    }

    if (type === "multiselect") {
      // Assuming `event.target.options` is an array of options
      const options = event.target.options;
      const selectedValues = [];

      // Loop over each option to check if it's selected
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selectedValues.push(options[i].value); // Add selected value to array
        }
      }

      // Update the state with the new array of selected values
      setInputValue(selectedValues);
    }

    if (type === "file") {
      alert(
        "file upload not fully supported here. Look at widgets for a working implementation"
      );
    }
  };

  // handle toggle switch
  const handleToggleChange = (newValue: boolean) => {
    setInputValue(newValue);
  };

  const renderInput = (type: string) => {
    // If a form's inputType is "none", render a heading instead of a label
    if (type === "none") {
      return <h5 className="InputSectionTitle">{fieldName}</h5>;
    }

    // Pre-process date
    if (type === "date" && inputValue) {
      inputValue = dayjs(inputValue).format("YYYY-MM-DD");
    }

    // render text
    if (type === "textarea") {
      return (
        <textarea
          className="formFieldTextareaInput"
          id={`${id}-${recordId}`}
          name={fieldName}
          placeholder={placeholderText ?? "Enter"}
          onChange={handleControlledInputChange}
          value={inputValue}
          readOnly={formMode === "read" || !isMutable} // Set based on formMode
          autoComplete={autoComplete}
        />
      );
    }

    // render other items inc date & time
    if (
      type === "text" ||
      type === "date" ||
      type === "time" ||
      type === "password"
    ) {
      return (
        <input
          className="formFieldInput"
          type={type}
          id={`${id}-${recordId}`}
          name={fieldName}
          placeholder={placeholderText ?? "Enter"}
          onChange={handleControlledInputChange}
          value={inputValue}
          readOnly={formMode === "read" || !isMutable} // Set based on formMode
          autoComplete={autoComplete}
        />
      );
    }

    if (type === "number") {
      return (
        <input
          className="formFieldInput"
          type={type}
          id={`${id}-${recordId}`}
          name={fieldName}
          placeholder={placeholderText ?? "Enter"}
          onChange={handleControlledInputChange}
          value={inputValue}
          min={minValue}
          readOnly={formMode === "read" || !isMutable} // Set based on formMode
          autoComplete={autoComplete}
        />
      );
    }

    // toggle
    if (type === "toggle") {
      return (
        <div className="toggleLive">
          <span>{fieldName}</span>
          <label className="switch">
            <input
              id={`${id}-${recordId}`}
              type="checkbox"
              name={fieldName}
              checked={inputValue}
              onChange={(e) => {
                handleToggleChange(e.target.checked);
              }}
              disabled={formMode === "read" || !isMutable} // Set based on formMode
            />
            <span className="slider round"></span>
          </label>
        </div>
      );
    }

    // render dropdown
    if (type === "dropdown") {
      return (
        <select
          id={`${id}-${recordId}`}
          className="formFieldDropdown"
          onChange={handleControlledInputChange}
          disabled={formMode === "read" || !isMutable} // Set based on formMode
          value={inputValue}
        >
          <option key="select-an-option">Select an option</option>
          {fieldOptionData?.map((d: any, i) => {
            // maybe move key value attribute name identification
            // It could be set on the form config & passed in createAndEditFields
            // ID comes from form field config fieldName
            if (
              id === "sponsorId" ||
              id === "widgetTypeId" ||
              id === "ticketCategoryId"
            ) {
              return (
                <option key={`option-${i}`} value={d.id}>
                  {d.name}
                </option>
              );
            } else {
              return null;
            }
          })}
        </select>
      );
    }

    // render multiselect
    if (type === "multiselect") {
      return (
        <select
          id={`${id}-${recordId}`}
          className="formFieldDropdown"
          onChange={handleControlledInputChange}
          multiple
          value={inputValue || []} // Controls the selected options; fallback to empty array while multiple is true
          disabled={formMode === "read" || !isMutable} // Set based on formMode
        >
          {fieldOptionData?.map((d: any, i) => {
            // console.log(inputValue) // ['Admin']
            return (
              <option key={`option-${i}`} value={d.name}>
                {d.name}
              </option>
            );
          })}
        </select>
      );
    }

    // Render Radio input. (not sure if this is used.)
    if (type === "radio button") {
      return fieldOptionData?.map((d, i) => {
        return (
          <label key={`radioBtn-${i}`} className="formFieldRadioBtnContainer">
            {d}
            <input
              type="radio"
              id={`${id}-${recordId}`}
              value={d}
              onChange={(e) => {
                setRadioBtnInputValue(e.target.value);
                setInputValue(e.target.value);
              }}
              checked={d === radioBtnInputValue || d === inputValue}
              disabled={formMode === "read" || !isMutable} // Set based on formMode
            />
            <span className="checkMark"></span>
          </label>
        );
      });
    }

    // render checkboxes
    if (type === "checkbox" && checkboxData) {
      return checkboxData.map((d, i) => (
        <label key={`checkbox-${i}`} className="formFieldCheckboxContainer">
          {d.value}
          <input
            type="checkbox"
            id={`${id}-${recordId}`}
            value={d.value}
            onChange={() => {
              setCheckboxData(
                checkboxData.map((d, currentIndex) =>
                  currentIndex === i ? { ...d, checked: !d.checked } : d
                )
              );
            }}
            checked={d.checked}
            disabled={formMode === "read" || !isMutable} // Set based on formMode
          />
          <span className="checkMark"></span>
        </label>
      ));
    }

    // This is a dropdown for predictive text
    // When an admin is selecting an existing record they can
    // type, search, then select.
    // Default behavior is to render the "suggestionInfo"
    // and return the "ID"

    // every suggestion should have this mapped to the values.
    // usually a concatenation of some fields. done at the form level.
    if (type === "suggestion") {
      // default behavior is to pass back the id
      // and only return a value if there is a good match
      let optionKeyAttribute = "id";
      let returnNonMatchingInput = false;

      // optionally we can pass back other things.
      if (id === "email") {
        optionKeyAttribute = "email";
        returnNonMatchingInput = true;
      }

      return (
        <AutoPopulateInput
          id={`${id}-${recordId}`}
          data={fieldOptionData}
          searchInputValue={searchSuggestion}
          setSearchInputValue={setSearchSuggestion}
          inputValue={inputValue}
          setInputValue={setInputValue}
          optionKeyAttribute={optionKeyAttribute}
          optionValueAttribute="suggestionInfo"
          returnNonMatchingInput={returnNonMatchingInput}
        />
      );
    }

    // TODO: Figure out if wysiwyg input needs to be disabled in any forms
    if (type === "wysiwyg") {
      return (
        <>
          {/* If a form's inputType is "wysiwyg", render a heading instead of a label */}
          <h5 className="InputSectionTitle">{fieldName}</h5>
          <RichEditor inputValue={inputValue} setInputValue={setInputValue} />
        </>
      );
    }

    // WARNING!!!
    // File uploading is not that mature.
    // It only FULLY works for widgets at the moment. and that is a one to many arrangement
    // Backlog items have it planned for the tournament, but that is two fields
    // that live directly on the tournament, and we can edit them there.
    // They are not the same and barely similar.
    // Maybe we want to move all images to an image table then we can handle everything the same way as widgets.
    // Or maybe we need to do something else...
    if (type === "file") {
      return (
        <>
          <div className="customFileItput">
            {/* Hidden we will use this but call it onClick */}
            <input
              className="formFieldInput"
              type={type}
              id={`hiddenFileInput-${id}`}
              name={fieldName}
              placeholder={placeholderText ?? "Enter"}
              onChange={handleControlledInputChange}
              value={inputValue}
              readOnly={formMode === "read" || !isMutable} // Set based on formMode
            />

            <button
              className="uploadIconWrapper"
              type="button"
              onClick={() => {
                openFileUpload(id);
              }}
            >
              <p className="uploadTxt">Browse</p>
              <img src={uploadSvg} alt="Upload" className="uploadIcon" />
            </button>
          </div>
        </>
      );
    }
  };

  const openFileUpload = (id: string) => {
    document.getElementById(`hiddenFileInput-${id}`)!.click();
  };

  return (
    <div className="formField">
      {type !== "none" && type !== "wysiwyg" && fieldName !== undefined && (
        <div>
          {title && <h5 className="InputSectionTitle">{title}</h5>}
          {prompt && <h6 className="InputSectionPrompt">{prompt}</h6>}

          {type !== "toggle" && (
            <label
              htmlFor={
                type === "file"
                  ? `hiddenFileInput-${fieldName}-${recordId}`
                  : `${id}-${recordId}`
              }
              className={`label ${isRequired && "labelRequired"}`}
            >
              {fieldName}
            </label>
          )}

          {helperText && <p className="helperText">{helperText}</p>}
        </div>
      )}
      <>{renderInput(type)}</>
    </div>
  );
};
