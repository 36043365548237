import { createTicket, updateTicket } from "./httpClient";

const attendeeTicketConfig = {
  name: "ticket",
  onCreate: createTicket,
  onUpdate: updateTicket,
  createAndEditFields: [
    {
      fieldName: "ticketCategoryId",
      label: "Ticket Type",
      inputType: "dropdown",
      isRequired: true,
      isMutable: true,
    },
    {
      fieldName: "userId",
      label: "Purchaser (Ticket Holder)",
      inputType: "suggestion", // [X] TODO - only partialy implemented
      isRequired: true,
      isMutable: true,
    },
  ],
};

export default attendeeTicketConfig;
