import "../../assets/scss/main.scss";

import { checkSvg } from "../../assets/icons";
import { CheckInAttendee } from "../../types";

interface AttendeeModalProps {
  attendee: CheckInAttendee | undefined;
  updateCheckedIn: (checkedIn: boolean) => Promise<void>;
  cancel: () => void;
}

export const AttendeeModal = ({
  attendee,
  updateCheckedIn,
  cancel,
}: AttendeeModalProps) => {
  return (
    <div className="checkInAttendeeModalContent">
      <table className="selectedAttendeeTable">
        <tbody>
          <tr>
            <td>{attendee?.name}</td>
            <td>
              {attendee?.checkedIn && (
                <img src={checkSvg} className="icon" alt="checked in status" />
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="btnContainer">
        {attendee?.checkedIn ? (
          <button
            className="redBtn"
            onClick={async () => {
              await updateCheckedIn(false);
            }}
          >
            Undo Check In
          </button>
        ) : (
          <button
            className="greenBtn"
            onClick={async () => {
              await updateCheckedIn(true);
            }}
          >
            Check In
          </button>
        )}
        <button
          className="greyBtn"
          onClick={() => {
            cancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
