// import { updateEuser } from "./httpClient";

const euserConfig = {
  name: "euser",
  // onUpdate: updateEuser,
  signUpFields: [
    {
      fieldName: "nameFirst",
      label: "First Name",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "given-name",
    },
    {
      fieldName: "nameLast",
      label: "Last Name",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "family-name",
    },
    {
      fieldName: "phone",
      label: "Phone",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "tel",
    },
    {
      fieldName: "email",
      label: "Email",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "email",
    },
    {
      fieldName: "password",
      label: "Password",
      inputType: "password",
      isRequired: true,
      isMutable: true,
      autoComplete: "new-password",
    },
    {
      fieldName: "passwordConfirmation",
      label: "Password Confirmation",
      inputType: "password",
      isRequired: true,
      isMutable: true,
      autoComplete: "new-password",
    },
  ],

  signInFields: [
    {
      fieldName: "email",
      label: "Email",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "email",
    },
    {
      fieldName: "password",
      label: "Password",
      inputType: "password",
      isRequired: true,
      isMutable: true,
      autoComplete: "current-password",
    },
  ],

  recoverFields: [
    {
      fieldName: "email",
      label: "Email",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "email",
    },
  ],
};

export default euserConfig;
