import "../assets/scss/main.scss";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { editSvg, eyeSvg, trashSvg } from "../assets/icons";
import { PaymentModal } from "../components/PaymentModal";
import {
  deleteTicketCategory,
  getTournamentTicketCategories,
} from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { TicketCategory } from "../types";

function TicketCategoryPage() {
  const navigate = useNavigate();

  const { tournamentId, attendeeOrSponsor } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  const [ticketCategories, setTicketCategories] = useState<TicketCategory[]>(
    []
  );
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [isSponsorTicketCategory, setIsSponsorTicketCategory] = useState<
    boolean | null
  >(null);

  const getData = async () => {
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
      const isSponsor = attendeeOrSponsor === "sponsor";
      const ticketCategories = await getTournamentTicketCategories(
        parseInt(tournamentId),
        isSponsor
      );
      setTicketCategories(ticketCategories);
    }
  };

  useEffect(() => {
    getData();
  }, [tournamentId, attendeeOrSponsor]);

  return (
    <div className="ticketCategoryPageWrapper">
      <h5 className="ticketCategoryPageTitle">
        <span>{attendeeOrSponsor}</span> Ticket Categories{" "}
      </h5>

      <button
        className="newCategoryBtn"
        type="button"
        onClick={() => navigate("new")}
      >
        New Ticket Category
      </button>

      <table className="ticketCategoryTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ticketCategories.map((t, key) => {
            return (
              <tr key={key}>
                <td>{t.name}</td>
                <td className="descriptionTableColumn">
                  {t.description?.slice(0, 25)}...
                </td>

                <td
                  className="tableLink"
                  onClick={() => {
                    setIsSponsorTicketCategory(!!t.sponsorFlag);
                    setShowPaymentModal(true);
                  }}
                >
                  <img
                    src={eyeSvg}
                    className="iconAction"
                    alt="Open Payment Preview"
                  />
                  &nbsp;
                  <span>Payment Preview</span>
                </td>

                <td
                  className="tableLink"
                  onClick={() => {
                    navigate(`${t.id}/edit`);
                  }}
                >
                  <img
                    src={editSvg}
                    className="iconAction"
                    alt="edit tournament"
                  />
                </td>

                <td
                  className="tableLink"
                  onClick={async () => {
                    if (
                      tournamentId &&
                      window.confirm("Are you sure to delete this tournament?")
                    ) {
                      await deleteTicketCategory(t.id);
                      const isSponsor = attendeeOrSponsor === "sponsor";
                      const ticketCategories =
                        await getTournamentTicketCategories(
                          parseInt(tournamentId),
                          isSponsor
                        );
                      setTicketCategories(ticketCategories);
                    }
                  }}
                >
                  <img
                    src={trashSvg}
                    className="iconAction"
                    alt="delete tournament"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <section>
        <PaymentModal
          isOpen={showPaymentModal}
          onRequestClose={() => {
            setIsSponsorTicketCategory(null);
            setShowPaymentModal(false);
          }}
          isSponsorTicketCategory={isSponsorTicketCategory}
        />
      </section>
    </div>
  );
}

export default TicketCategoryPage;
