import { FormDataType } from "../types";

const defaultValues: { [key: string]: string | number | boolean | [] } = {
  text: "",
  textarea: "",
  number: 0,
  toggle: false,
  multiselect: [],
  suggestion: "",
  date: "",
  time: "",
  file: "",
  dropdown: "",
};

export const constructEmptyForm = (
  fields: { fieldName: string; inputType: string }[]
): FormDataType => {
  const form: FormDataType = {};
  for (const field of fields) {
    form[field.fieldName] = defaultValues[field.inputType];
  }
  return form;
};
