import { IconContext } from "react-icons";
import { IoHome } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";

function TournamentActionItems() {
  const { tournamentState, tournamentIdState } = useTournamentStateContext();
  const location = useLocation();
  const navigate = useNavigate();

  const tournamentLink = `/tournaments/${tournamentIdState}/${tournamentState.slug}`;
  const shouldShowAllTournamentLink = location.pathname !== "/tournaments";

  return (
    <section>
      {/* If there is an active tournament set */}
      {tournamentState.id !== 0 ? (
        <>
          <h3 className="BlueNavTitle">{tournamentState.name}</h3>
          <li className="editMenuItem">
            <button
              type="button"
              className="BlueNavOption"
              onClick={() => navigate(tournamentLink)}
            >
              <div className="homeItemWrapper">
                Event Page
                <IconContext.Provider
                  value={{
                    className: "homeIcon",
                  }}
                >
                  <IoHome />
                </IconContext.Provider>
              </div>
            </button>
          </li>
        </>
      ) : (
        // If there is not a tournament
        <>
          <h3 className="BlueNavTitle">HCA Hope Fund</h3>
        </>
      )}

      {shouldShowAllTournamentLink && (
        <p className="BlueNavDiscreetText">
          <Link to="/tournaments">All Tournaments</Link>
        </p>
      )}
    </section>
  );
}

export default TournamentActionItems;
