import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { ReactNode, useEffect, useState } from "react";

import { Euser } from "../../types/euser.ts";
import { EuserRole } from "../../types/euserRole.ts";
import { instance, updateUser } from "../httpClient";

// So this is called Euser... for now or maybe forever.
// Wanted to use "user" but that is taken by actual DB record matching util.

type EuserContextType = {
  euser: Euser | null;
  // updateRole: (newRole: EuserRole) => void;
  signUpEuser?: (data: object) => Promise<void>;
  signInEuser?: (data: object) => Promise<any>;
  recoverEuser?: (data: object) => Promise<any>;
  signInWithTempAuthCode?: (tempAuthCode: string) => Promise<any>;
  signOut: () => void;
  euserIsAdmin: () => boolean;
  euserIsEditor: () => boolean;
  euserIsVolunteer: () => boolean;
};

const EuserContext = React.createContext<EuserContextType>({
  euser: null,
  euserIsAdmin: () => false,
  euserIsEditor: () => false,
  euserIsVolunteer: () => false,
  signOut: () => null,
});

type EuserProviderProps = {
  children: ReactNode;
};

const EuserProvider = ({ children }: EuserProviderProps) => {
  const [euser, setEuser] = useState(() => {
    // Check for existing euser data in local storage
    const savedEuser = sessionStorage.getItem("euser");
    return savedEuser ? JSON.parse(savedEuser) : null;
  });

  // Update local storage whenever euser changes
  useEffect(() => {
    if (euser) {
      sessionStorage.setItem("euser", JSON.stringify(euser));
    } else {
      sessionStorage.removeItem("euser");
    }
  }, [euser]);

  const signUpEuser = async (data: object) => {
    let isSignUp = false;
    try {
      const {
        nameFirst,
        nameLast,
        phone,
        email,
        password,
        passwordConfirmation,
      } = data as {
        nameFirst: string;
        nameLast: string;
        phone: string;
        email: string;
        password: string;
        passwordConfirmation: string;
      };
      // sign up new user
      await instance.post(`/Auth/register`, {
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation,
      });
      isSignUp = true;
      // sign in new user
      const signInResponse = await signInEuser({
        email: email,
        password: password,
      });
      // get euser id since the "euser" state variable will not have the updated value in this run
      let euserId = null;
      if (signInResponse) {
        const token = signInResponse;
        const decoded = jwtDecode<Euser>(token);
        euserId = decoded.id;
      } else {
        throw new Error("Token not received");
      }
      // update info for new user so that first name, last name and phone are stored
      await updateUser({
        email: email, // an error is thrown if email is not included
        id: euserId,
        nameFirst: nameFirst,
        nameLast: nameLast,
        phone: phone,
      });
      return;
    } catch (error: any) {
      // request errors (i.e. axios errors) already print to the console and alert user of error (see httpClient.ts)
      if (!axios.isAxiosError(error)) {
        console.error(error);
      }

      const helpText = `If you need assistance, please contact us at golfforhope@hcahealthcare.com.`;
      // if an axios error occurred then this is the 2nd message the user has seen; hopefully this is ok since it provides useful info
      if (isSignUp) {
        alert(
          `An error occurred, but we were still able to create your account.  If you see "Sign Out" on the lefthand menu then the automatic sign-in ` +
            `was successful; if not, you can sign in by selecting "Sign In" from the menu.\n\nOnce you're signed in, select "Account" from ` +
            `the menu and enter your first name, last name, and phone number if this info is not displayed.\n\n` +
            helpText
        );
      } else {
        alert(
          `An error occurred during sign up; please try again.\n\n` + helpText
        );
      }
      // rethrow the error to be handled in the default way
      throw error;
    }
  };

  const signInEuser = async (data: object) => {
    // this statement is outside of try block since request errors (i.e. axios errors) already print to the console and alert user of error (see httpClient.ts)
    const response = await instance.post(`/Auth/login`, data);
    try {
      if (response.data) {
        const token = response.data;
        processJwt(token);
      } else {
        throw new Error("Token not received");
      }
      return response.data;
    } catch (error: any) {
      console.error(error);
      alert("An error occurred during sign in.");
      throw error;
    }
  };

  const recoverEuser = async (data: object) => {
    const response = await instance.post(`/Auth/recover`, data);
    // This should always be an ok.
    // Security measure to not disclose if a user exists or not.
    return response.data;
  };

  const signInWithTempAuthCode = async (tempAuthCode: string) => {
    const requestBody = { tempAuthCode: tempAuthCode };
    const response = await instance.post(`/Auth/loginViaAuthCode`, requestBody);
    try {
      if (response.data) {
        const token = response.data;
        processJwt(token);
      } else {
        throw new Error("Token not received");
      }
      return response.data;
    } catch (error: any) {
      console.error(error);
      alert("An error occurred during sign in.");
      throw error;
    }
  };

  const processJwt = (token: string) => {
    sessionStorage.setItem("jwtToken", token);

    const decoded = jwtDecode<Euser>(token);
    const roleArray = JSON.parse(decoded.roles.toString());

    const our_euser: Euser = {
      email: decoded.email,
      id: decoded.id,
      roles: roleArray,
      isAuthenticated: true,
    };

    setEuser(our_euser);
  };

  const signOut = () => {
    setEuser(null);
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("euser"); // Clear session storage
  };

  const euserIsAdmin = () => euser?.roles.includes(EuserRole.Admin);
  const euserIsEditor = () => euser?.roles.includes(EuserRole.ContentEditor);
  const euserIsVolunteer = () => euser?.roles.includes(EuserRole.Volunteer);

  return (
    <EuserContext.Provider
      value={{
        euser,
        signInEuser,
        signUpEuser,
        recoverEuser,
        signInWithTempAuthCode,
        signOut,
        euserIsAdmin,
        euserIsEditor,
        euserIsVolunteer,
      }}
    >
      {children}
    </EuserContext.Provider>
  );
};

export { EuserContext, EuserProvider };
