import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { arrowRightSvg, editSvg, eyeSlashSvg, trashSvg } from "../assets/icons";
import { deleteTournament, getAllTournaments } from "../data/httpClient";
import { EuserContext } from "../data/providers/EuserProvider";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { Tournament } from "../types";

function ListTournamentsPage() {
  const { euserIsAdmin, euserIsEditor } = useContext(EuserContext);

  const navigate = useNavigate();

  const [tournaments, setTournaments] = useState<Tournament[]>([]);

  const getData = async () => {
    const result = await getAllTournaments();
    setTournaments(result);
  };

  useEffect(() => {
    getData();
  }, []);

  const { setTournamentIdState } = useTournamentStateContext();

  return (
    <div className="listTournamentPage">
      <h3 className="listTitle">HCA Hope Fund Golf Tournaments</h3>
      {euserIsAdmin() && (
        <>
          <section className="btnSection">
            <button
              className="createNewBtn"
              type="button"
              onClick={() => navigate("new")}
            >
              New Tournament
            </button>
          </section>
        </>
      )}
      <h5 className="listIntro">
        Select an Tournament below by clicking &quot;Tournament Page&quot; to
        view all the information about that Tournament and to get registered
        today!
      </h5>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Description</th>
            <th>Start Date</th>
            <th></th>
            {(euserIsAdmin() || euserIsEditor()) && <th></th>}
            {euserIsAdmin() && <th></th>}
          </tr>
        </thead>
        <tbody>
          {tournaments.map((t, key) => {
            return (
              <tr key={key}>
                <td>
                  {t.liveFlag === false && (
                    <img src={eyeSlashSvg} className="icon" alt="inactive" />
                  )}
                </td>
                <td>{t.name}</td>
                <td className="descriptionTableColumn">
                  {t.description?.slice(0, 25)}...
                </td>
                <td>{dayjs(t.dateStart).format("DD/MM/YYYY")}</td>
                <td
                  className="tableLink"
                  onClick={() => {
                    setTournamentIdState(t.id); //set general tournament id state
                    navigate(`/tournaments/${t.id}/${t.slug}`);
                  }}
                >
                  <img
                    src={arrowRightSvg}
                    className="iconAction"
                    alt="edit tournament"
                  />
                  &nbsp;
                  <span>Tournament Page</span>
                </td>

                {(euserIsAdmin() || euserIsEditor()) && (
                  <td
                    className="iconAction"
                    onClick={() => {
                      setTournamentIdState(t.id); //set general tournament id state
                      navigate(`/tournaments/${t.id}/edit`);
                    }}
                  >
                    <img src={editSvg} alt="edit tournament" />
                  </td>
                )}
                {euserIsAdmin() && (
                  <td
                    className="iconAction"
                    onClick={async () => {
                      if (
                        window.confirm(
                          "Are you sure to delete this tournament?"
                        )
                      ) {
                        await deleteTournament(t.id);
                        const result = await getAllTournaments();
                        setTournaments(result);
                      }
                    }}
                  >
                    <img src={trashSvg} alt="delete tournament" />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ListTournamentsPage;
