import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { deleteRound, updateRound } from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { Round } from "../types";

interface EachRoundProp {
  round: Round;
  tournamentId: number;
}

export const EachRound = ({ round, tournamentId }: EachRoundProp) => {
  const navigate = useNavigate();

  const { getRoundsState } = useTournamentStateContext();

  return (
    <div className="roundWrapper">
      <h5>{round.name}</h5>
      <article className="roundItem">
        <h6>
          <i>Location:</i>
        </h6>
        <p>{round.location}</p>
      </article>

      <article className="roundItem">
        <h6>
          <i>Start At:</i>
        </h6>
        <p>
          {round.startDate} at{" "}
          {dayjs(`${round.startDate} ${round.startTime}`).format("h:mm A")}
        </p>
      </article>
      <article className="roundItem">
        <h6>
          <i>Description:</i>
        </h6>
        <p>{round.description}</p>
      </article>

      <article className="toggleLive">
        <h6>Live</h6>
        <label className="switch">
          <input
            type="checkbox"
            name="liveFlag"
            checked={round.liveFlag}
            onChange={async () => {
              round.liveFlag = !round.liveFlag;
              await updateRound(round);
              getRoundsState(tournamentId);
              alert("Round live flag is updated.");
            }}
          />
          <span className="slider round"></span>
        </label>
      </article>

      <section className="roundBtnGroup">
        <button
          className="roundBtn"
          type="button"
          onClick={async () => {
            await deleteRound(round.id);
            alert("Round is successfully removed.");
            getRoundsState(tournamentId);
          }}
        >
          Remove
        </button>
        <button
          className="roundBtn"
          type="button"
          onClick={() =>
            navigate(
              `/tournaments/${round.tournamentId}/admin/rounds/${round.id}/edit`
            )
          }
        >
          Edit
        </button>
      </section>
    </div>
  );
};
