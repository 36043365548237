import "../assets/scss/main.scss";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import widgetConfig from "../data/widgetConfig";
import { FormDataType, Widget, WidgetType } from "../types";
import FormFields from "./FormFields";

type CreateWidgetModalProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  widgets: Widget[];
  widgetTypes: WidgetType[];
};

export const CreateWidgetModal = ({
  setModal,
  widgets,
  widgetTypes,
}: CreateWidgetModalProps) => {
  const { tournamentId } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  useEffect(() => {
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
    }
  }, [tournamentId]);

  const [formData, setFormData] = useState<FormDataType>(
    widgetConfig.createAndEditFields.reduce(
      (prev, field) => ({
        ...prev,
        [field.fieldName]: "",
        tournamentId: tournamentId,
        position: -1,
      }),
      {}
    )
  );

  useEffect(() => {
    setFormData((prev) => ({ ...prev, position: widgets.length + 1 }));
  }, [widgets]);

  const fieldOptionData = {
    widgetTypeId: widgetTypes,
  };

  // Set data for creating or editing widgets
  formData.widgetName = "";

  return (
    <div className="createWidgetModalContent">
      <h5 className="createWidgetTitle">Create New Widget</h5>

      {widgetTypes.length && (
        <FormFields
          tournamentId={tournamentId}
          fields={widgetConfig.createAndEditFields}
          form={{ method: "create", data: formData }}
          setFormData={(_, data) => setFormData(data)}
          formMode={"create"}
          isModal
          setFormModal={setModal}
          name={widgetConfig.name}
          onCreateFunction={widgetConfig.onCreate}
          onUpdateFunction={widgetConfig.onUpdate}
          fieldOptionData={fieldOptionData}
        />
      )}
    </div>
  );
};
