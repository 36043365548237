// This really should be coming from the database if we keep it...
// Used By the testing role switcher.

export enum EuserRole {
  Admin = "Admin",
  Volunteer = "Volunteer",
  ContentEditor = "Content Editor",
  User = "User",
  Guest = "Guest",
}
