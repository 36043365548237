import "../assets/scss/main.scss";

import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import FormFields from "../components/FormFields";
import attendeeTicketConfig from "../data/attendeeTicketConfig";
import {
  getAllSponsors,
  getAllUsers,
  getTournamentTicketCategories,
  getUserById,
} from "../data/httpClient";
import { EuserContext } from "../data/providers/EuserProvider";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import sponsorTicketConfig from "../data/sponsorTicketConfig";
import { FormDataType, Sponsor, User } from "../types";
import { EuserRole } from "../types/euserRole";

export const CreateTicketPage = () => {
  const { tournamentId, attendeeOrSponsor } = useParams();
  const navigate = useNavigate();

  const { setTournamentIdState } = useTournamentStateContext();
  const location = useLocation();

  const { euser } = useContext(EuserContext);

  const [ticketCategories, setTicketCategories] = useState<User[]>([]);
  const [userData, setUserData] = useState<User[]>([]);
  const [sponsorDropdownData, setSponsorDropdownData] = useState<Sponsor[]>([]);
  const [formData, setFormData] = useState<FormDataType>(
    (attendeeOrSponsor === "sponsor"
      ? sponsorTicketConfig
      : attendeeTicketConfig
    ).createAndEditFields.reduce(
      (prev, field) => ({
        ...prev,
        // [field.fieldName]: "",
        [field.fieldName]: field.fieldName === "userParticipating" ? true : "",
        tournamentId: tournamentId && parseInt(tournamentId),
      }),
      {}
    )
  );

  const getData = async () => {
    // set the possible ticket categories.
    const ticketCategories = await getTournamentTicketCategories(
      Number(tournamentId),
      attendeeOrSponsor === "sponsor"
    );
    setTicketCategories(ticketCategories);

    // pull either all the users if admin.
    // or pull a new fresh copy of the euser.
    let userSuggestions = [];
    if (euser?.roles.includes(EuserRole.Admin)) {
      userSuggestions = await getAllUsers();
    } else {
      if (euser?.id !== undefined) {
        const euser_id = parseInt(euser.id);
        userSuggestions.push(await getUserById(euser_id));
      }
    }

    // map over the user suggestions to make them presentable
    userSuggestions.forEach((p: User) => {
      p.suggestionInfo = `${p.nameFirst} ${p.nameLast} (${p.email})`;
    });
    setUserData(userSuggestions);

    // Get all the sponsors that the user can see.
    // similar to the usersSuggestions however the filtering happens on the API.
    if (attendeeOrSponsor === "sponsor") {
      const sponsors = await getAllSponsors();
      setSponsorDropdownData(sponsors);
    }
  };

  const redirectAfterFinish = async () => {
    if (euser?.roles.includes(EuserRole.Admin)) {
      window.location.reload();
    } else {
      alert(
        `\u{1F4A1} Tip:\n\nYou can add or edit teammates and reserve which round your team will play by clicking the gear icon ` +
          `under the "Groups" column on the Tickets and Reservations page.  We'll redirect you to that page after you select "OK".`
      );
      navigate("/account/reservations");
    }
  };

  useEffect(() => {
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
    }
    getData();
  }, [tournamentId]);

  useEffect(() => {
    const isAdmin = euser?.roles.includes(EuserRole.Admin);
    // Spread our form "together"??? not the correct verbage but that is the action.
    setFormData((prevFormData) => ({
      ...prevFormData,
      // conditionally set userId if euser is not an admin
      ...(euser && !isAdmin ? { userId: parseInt(euser.id) } : {}),
    }));
  }, [euser]);

  useEffect(() => {
    location.state?.categoryId &&
      setFormData((prevFormData) => ({
        ...prevFormData,
        ticketCategoryId: location.state.categoryId,
      }));
  }, []);

  // Key is fieldName from form config
  const fieldOptionData = {
    sponsorId: sponsorDropdownData,
    ticketCategoryId: ticketCategories,
    userId: userData,
  };

  return (
    <div className="createTicketWrapper">
      <h5 className="createTicketTitle">
        New <span>{attendeeOrSponsor}</span> Ticket
      </h5>
      {attendeeOrSponsor && (
        <FormFields
          fields={
            (attendeeOrSponsor === "sponsor"
              ? sponsorTicketConfig
              : attendeeTicketConfig
            ).createAndEditFields
          }
          form={{ method: "create", data: formData }}
          setFormData={(_, data) => setFormData(data)}
          formMode={"create"}
          name={`${attendeeOrSponsor}-ticket`}
          onCreateFunction={
            (attendeeOrSponsor === "sponsor"
              ? sponsorTicketConfig
              : attendeeTicketConfig
            ).onCreate
          }
          onUpdateFunction={
            (attendeeOrSponsor === "sponsor"
              ? sponsorTicketConfig
              : attendeeTicketConfig
            ).onUpdate
          }
          fieldOptionData={fieldOptionData}
          afterCreateFunction={redirectAfterFinish}
        />
      )}
    </div>
  );
};
