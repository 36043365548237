import "../assets/scss/main.scss";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FormFields from "../components/FormFields";
import { getAllSponsors, getAllUsers } from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import userSponsorConfig from "../data/userSponsorConfig";
import { FormDataType, Sponsor, User } from "../types";

export const CreateUserSponsorLinkingPage = () => {
  const { tournamentId } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  useEffect(() => {
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
    }
    getData();
  }, [tournamentId]);

  const [formData, setFormData] = useState<FormDataType>(
    userSponsorConfig.createAndEditFields.reduce(
      (prev, field) => ({
        ...prev,
        // [field.fieldName]: "",
        [field.fieldName]: field.fieldName === "userParticipating" ? true : "",
        tournamentId: tournamentId && parseInt(tournamentId),
      }),
      {}
    )
  );

  const [userData, setUserData] = useState<User[]>([]);
  const [sponsorData, setSponsorData] = useState<Sponsor[]>([]);

  const getData = async () => {
    let users = [];
    users = await getAllUsers();

    // map over the user suggestions to make them presentable
    users.forEach((p: User) => {
      p.suggestionInfo = `${p.nameFirst} ${p.nameLast} (${p.email})`;
    });
    setUserData(users);

    // Get all the sponsors that the user can see.
    // similar to the usersSuggestions however the filtering happens on the API.
    const sponsors = await getAllSponsors();
    setSponsorData(sponsors);
  };

  // Key is fieldName from form config
  const fieldOptionData = {
    sponsorId: sponsorData,
    userId: userData,
  };

  return (
    <div className="createTicketWrapper">
      <h5 className="createTicketTitle">New User Sponsor Link</h5>

      <FormFields
        fields={userSponsorConfig.createAndEditFields}
        form={{ method: "create", data: formData }}
        setFormData={(_, data) => setFormData(data)}
        formMode={"create"}
        name={`User Sponsor Link`}
        onCreateFunction={userSponsorConfig.onCreate}
        fieldOptionData={fieldOptionData}
      />
    </div>
  );
};
