import "../assets/scss/main.scss";

import { Outlet, useLocation } from "react-router-dom";

import { Footer } from "../components/Footer";
import { EditNav } from "../navs/EditNav";
import { TournamentNav } from "../navs/TournamentNav";

function TournamentContainer() {
  const location = useLocation();
  const currentPath = location.pathname;
  const tournamentPaths = ["/tournaments", "/tournaments/", "/tournaments/new"];

  return (
    <div className="navContentContainer">
      <nav className="blueNavBar">
        {tournamentPaths.includes(currentPath) ? (
          <TournamentNav />
        ) : (
          <EditNav />
        )}
      </nav>
      <div className="mainContent">
        <div className="container">
          <Outlet></Outlet>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default TournamentContainer;
