import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import FormFields from "../components/FormFields";
import euserConfig from "../data/euserConfig"; // Form config
import { FormDataType } from "../types";
import useEuser from "../utils/useEuser"; // access to provider via "use" hook

const AccountSignIn = () => {
  const { signInEuser, signInWithTempAuthCode } = useEuser();

  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const queryParams = new URLSearchParams(location.search);
    const tempAuthCode = queryParams.get("tempAuthCode");

    if (tempAuthCode && signInWithTempAuthCode) {
      // console.log("Attempting login with tempAuthCode.")

      const autoSignIn = async () => {
        try {
          await signInWithTempAuthCode(tempAuthCode);
          afterPostFunction();
        } catch (error) {
          // console.error("Login with tempAuthCode failed.");
          // Clear query params
          window.history.pushState({}, document.title, "/account/signin");
        }
      };

      autoSignIn();
    }
    // eslint-disable-next-line no-restricted-globals
  }, [location.search]);

  const [formData, setFormData] = useState<FormDataType>(
    euserConfig.signInFields.reduce(
      (prev, field) => ({
        ...prev,
        [field.fieldName]: "",
      }),
      {}
    )
  );

  const afterPostFunction = () => {
    return new Promise<void>((resolve, reject) => {
      try {
        console.log(
          "redirecting to account/reservations via AccountSignIn.tsx"
        );
        navigate("/account/reservations");
        resolve(); // Resolve the promise when done
      } catch (error) {
        reject(error); // Reject the promise if there's an error
      }
    });
  };

  return (
    <div className="editPageWrapper">
      <h3 className="list_title">Sign In</h3>

      <div className="signInEuserWrapper">
        <FormFields
          fields={euserConfig.signInFields}
          form={{ method: "post", data: formData }}
          setFormData={(_, data) => setFormData(data)}
          formMode={"post"}
          name={euserConfig.name}
          onPostFunction={signInEuser}
          afterPostFunction={afterPostFunction}
        />
      </div>

      <div style={{ padding: "3em 0em 0em 0em" }}>
        <p>
          Forgot Password? <Link to="/account/recover">Recover Account</Link>
        </p>
      </div>
    </div>
  );
};

export default AccountSignIn;
