import React, { ReactNode, useState } from "react";

type CollapsibleContentProps = {
  title: string;
  children: ReactNode;
  expanded?: boolean;
};

const CollapsibleContent: React.FC<CollapsibleContentProps> = ({
  title,
  children,
  expanded,
}) => {
  const [isOpen, setIsOpen] = useState(expanded);

  return (
    <div className="collapsible-section">
      <button className="collapsible-title" onClick={() => setIsOpen(!isOpen)}>
        {title}
        <span className="collapsible-indicator">{isOpen ? "▲" : "▼"}</span>
      </button>
      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

export default CollapsibleContent;
