import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { EuserContext } from "../data/providers/EuserProvider";

function EuserActionItems() {
  const { euser, signOut } = useContext(EuserContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const signOutTrigger = () => {
    signOut();
    // navigate("/")
    navigate("/account/signin");
  };

  return (
    <section>
      {/* If there is an euser in the system */}
      {euser ? (
        <>
          {/* &#32; is ascii space. */}
          <p className="BlueNavDiscreetText">
            Signed in as&#32;
            <Link to="/account/edit">{euser.email}</Link>
          </p>

          {/* <TestingRoleUpdater ></TestingRoleUpdater> */}

          <li className="editMenuItem">
            <button
              type="button"
              onClick={signOutTrigger}
              className="BlueNavOption"
            >
              Sign Out
            </button>
          </li>
        </>
      ) : (
        // If there is not a euser
        <div>
          <li
            className={`editMenuItem ${
              currentPath.includes(`/account/signin`) && "menuItemSelected"
            }`}
          >
            <Link to={`/account/signin`}>
              <button type="button" className={`BlueNavOption`}>
                Sign In
              </button>
            </Link>
          </li>

          <li
            className={`editMenuItem ${
              currentPath.includes(`/account/signup`) && "menuItemSelected"
            }`}
          >
            <Link to={`/account/signup`}>
              <button type="button" className="BlueNavOption">
                New User Sign Up
              </button>
            </Link>
          </li>
        </div>
      )}
    </section>
  );
}

export default EuserActionItems;
