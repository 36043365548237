import "./assets/scss/main.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App.tsx";
import { EuserProvider } from "./data/providers/EuserProvider.tsx";
import { GroupProvider } from "./data/providers/GroupProvider.tsx";
import { TournamentStateProvider } from "./data/providers/TournamentStateProvider.tsx";
import { WidgetProvider } from "./data/providers/WidgetProvider.tsx";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <EuserProvider>
      <TournamentStateProvider>
        <GroupProvider>
          <WidgetProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </WidgetProvider>
        </GroupProvider>
      </TournamentStateProvider>
    </EuserProvider>
  </React.StrictMode>
);
