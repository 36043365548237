import axios from "axios";

export const instance = axios.create({
  baseURL: "/api",
});

// add headers for auth
instance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("jwtToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // This function is called for responses with status code 2xx
    return response;
  },
  function (error) {
    console.log(error);
    alert(getErrorMessage(error));
    return Promise.reject(error);
  }
);

const getErrorMessage = (error: any) => {
  const heading = "Error:\n";
  let message = heading;
  // "errors" could contain validation error messages from the api
  if (error.response.data?.errors) {
    const errors = Object.values(error.response.data?.errors).flat();
    message += errors.join("\n\n");
  } else {
    message += error.response.data;
  }
  message = message === heading ? "An error occurred." : message;
  return message;
};

// tournament
export const getAllTournaments = async () => {
  const response = await instance.get("/Tournament");
  return response.data;
};

export const getTournamentById = async (id: number) => {
  const response = await instance.get(`/Tournament/${id}`);
  return response.data;
};

export const createTournament = async (data: object) => {
  const response = await instance.post(`/Tournament`, data);
  return response.data;
};

export const updateTournament = async (data: object) => {
  const response = await instance.put(`/Tournament`, data);
  return response.data;
};

export const deleteTournament = async (id: number) => {
  const response = await instance.delete(`/Tournament/${id}`);
  return response.data;
};

// data export
export const getComprehensiveDataExport = async (tournamentId: number) => {
  const response = await instance.get(
    `/DataExport/comprehensive/${tournamentId}`
  );
  return response.data;
};

export const getAttendeeRosterDataExport = async (tournamentId: number) => {
  const response = await instance.get(
    `/DataExport/attendeeRoster/${tournamentId}`
  );
  return response.data;
};

// round
export const getTournamentRounds = async (tournamentId: number) => {
  const response = await instance.get(
    `/Round/query/tournament/${tournamentId}`
  );
  return response.data;
};

export const getRoundById = async (id: number) => {
  const response = await instance.get(`/Round/${id}`);
  return response.data;
};

export const createRound = async (data: object) => {
  const response = await instance.post(`/Round`, data);
  return response.data;
};

export const updateRound = async (data: object) => {
  const response = await instance.put(`/Round`, data);
  return response.data;
};

export const deleteRound = async (id: number) => {
  const response = await instance.delete(`/Round/${id}`);
  return response.data;
};

// users
export const getAllUsers = async () => {
  const response = await instance.get("/User");
  return response.data;
};

export const getUserById = async (id: number) => {
  const response = await instance.get(`/User/${id}`);
  return response.data;
};

export const createUser = async (data: object) => {
  const response = await instance.post("/User", data);
  return response.data;
};

export const updateUser = async (data: object) => {
  const response = await instance.put(`/User`, data);
  return response.data;
};

export const updateUserPassword = async (data: object) => {
  // console.log(data)
  const response = await instance.put(`/User/password`, data);
  return response.data;
};

export const deleteUser = async (id: number) => {
  const response = await instance.delete(`/User/${id}`);
  return response.data;
};

// User Sponsors
export const getUserSponsorsBySponsor = async (sponsorId: number) => {
  const response = await instance.get(
    `/UserSponsor/query/sponsor/${sponsorId}`
  );
  return response.data;
};

export const createUserSponsor = async (data: object) => {
  const response = await instance.post("/UserSponsor", data);
  return response.data;
};

export const deleteUserSponsor = async (sponsorId: number) => {
  const response = await instance.delete(`/UserSponsor/${sponsorId}`);
  return response.data;
};

// roles
export const getAllRoles = async () => {
  const response = await instance.get("/Role");
  return response.data;
};

// sponsors
export const getSponsorsByTicketCategory = async (ticketCategoryId: number) => {
  const response = await instance.get(
    `/Sponsor/query/ticketCategory/${ticketCategoryId}`
  );
  return response.data;
};

export const getAllSponsors = async () => {
  const response = await instance.get("/Sponsor");
  return response.data;
};

export const getSponsorById = async (id: number) => {
  const response = await instance.get(`/Sponsor/${id}`);
  return response.data;
};

export const createSponsor = async (data: object) => {
  const response = await instance.post("/Sponsor", data);
  return response.data;
};

export const updateSponsor = async (data: object) => {
  const response = await instance.put(`/Sponsor`, data);
  return response.data;
};

export const deleteSponsor = async (id: number) => {
  const response = await instance.delete(`/Sponsor/${id}`);
  return response.data;
};

export const getSponsorLogo = async (id: number) => {
  const response = await instance.get(`/Sponsor/logo/${id}`);
  return response.data;
};

export const createSponsorLogo = async (data: object) => {
  const response = await instance.post(`/Sponsor/logo`, data);
  return response.data;
};

export const deleteSponsorLogo = async (id: number) => {
  const response = await instance.delete(`/Sponsor/logo/${id}`);
  return response.data;
};

// Logos
export const verifyLogo = async (id: number, verifyBool: boolean) => {
  const data = {
    id: id,
    verified: verifyBool,
  };
  const response = await instance.put(`/Logo/verify`, data);
  return response.data;
};

// ticket categories
export const getTournamentTicketCategories = async (
  tournamentId: number,
  isSponsor?: boolean
) => {
  const response = await instance.get(
    `/TicketCategory/query/tournament/${tournamentId}${
      isSponsor !== undefined ? `?sponsor=${isSponsor}` : ""
    }`
  );
  return response.data;
};

export const getTicketCategoryById = async (id: number) => {
  const response = await instance.get(`/TicketCategory/${id}`);
  return response.data;
};

export const createTicketCategory = async (data: object) => {
  const response = await instance.post("/TicketCategory", data);
  return response.data;
};

export const updateTicketCategory = async (data: object) => {
  const response = await instance.put(`/TicketCategory`, data);
  return response.data;
};

export const deleteTicketCategory = async (id: number) => {
  const response = await instance.delete(`/TicketCategory/${id}`);
  return response.data;
};

// tickets
export const getTickets = async (tournamentId: number, isSponsor: boolean) => {
  const response = await instance.get(
    `/Ticket/query/tournament/${tournamentId}?sponsor=${isSponsor}`
  );
  return response.data;
};

export const getTicketsByUser = async (userId: number) => {
  const response = await instance.get(`/Ticket/query/user/${userId}`);
  return response.data;
};

export const getTicketById = async (id: number) => {
  const response = await instance.get(`/Ticket/${id}`);
  return response.data;
};

export const createTicket = async (data: object) => {
  const response = await instance.post("/Ticket", data);
  return response.data;
};

export const updateTicket = async (data: object) => {
  const response = await instance.put(`/Ticket`, data);
  return response.data;
};

export const deleteTicket = async (id: number) => {
  const response = await instance.delete(`/Ticket/${id}`);
  return response.data;
};

// ticket-groups
export const getGroups = async (ticketId: number) => {
  const response = await instance.get(`/Group/query/ticket/${ticketId}`);
  return response.data;
};

export const getGroupById = async (id: number) => {
  const response = await instance.get(`/Group/${id}`);
  return response.data;
};

export const createGroup = async (data: object) => {
  const response = await instance.post("/Group", data);
  return response.data;
};

export const updateGroup = async (data: object) => {
  const response = await instance.put(`/Group`, data);
  return response.data;
};

export const renameGroup = async (data: object) => {
  const response = await instance.put(`/Group/rename`, data);
  return response.data;
};

export const deleteGroup = async (id: number) => {
  const response = await instance.delete(`/Group/${id}`);
  return response.data;
};

export const getCheckInAttendees = async (tournamentId: number) => {
  const response = await instance.get(`/Attendee/checkin/${tournamentId}`);
  return response.data;
};

export const updateCheckInAttendee = async (
  attendeeId: number,
  checkedIn: boolean
) => {
  const response = await instance.put(`/Attendee/checkin/update`, {
    id: attendeeId,
    checkedIn: checkedIn,
  });
  return response.data;
};

// group-attendee
export const getAttendeesByUser = async (userId: number) => {
  const response = await instance.get(`/Attendee/query/user/${userId}`);
  return response.data;
};

export const getAttendeeById = async (id: number) => {
  const response = await instance.get(`/Attendee/${id}`);
  return response.data;
};

export const createAttendee = async (data: object) => {
  const response = await instance.post("/Attendee", data);
  return response.data;
};

export const updateAttendee = async (data: object) => {
  const response = await instance.put(`/Attendee`, data);
  return response.data;
};

export const deleteAttendee = async (id: number) => {
  const response = await instance.delete(`/Attendee/${id}`);
  return response.data;
};

export const getWidgets = async (tournamentId: number) => {
  const response = await instance.get(
    `/Widget/query/tournament/${tournamentId}`
  );
  return response.data;
};

export const getWidgetById = async (id: number) => {
  const response = await instance.get(`/Widget/${id}`);
  return response.data;
};

export const createWidget = async (data: object) => {
  const response = await instance.post(`/Widget`, data);
  return response.data;
};

export const updateWidget = async (data: object) => {
  const response = await instance.put(`/Widget`, data);
  return response.data;
};

export const deleteWidget = async (id: number) => {
  const response = await instance.delete(`/Widget/${id}`);
  return response.data;
};

export const reorderWidgets = async (
  widgets: { id: number; position: number }[]
) => {
  const response = await instance.put("/Widget/reorder", widgets);
  return response.data;
};

// WidgetType
export const getWidgetTypes = async () => {
  const response = await instance.get(`/WidgetType`);
  return response.data;
};

export const getWidgetTypeById = async (id: number) => {
  const response = await instance.get(`/WidgetType/${id}`);
  return response.data;
};

export const createWidgetType = async (data: object) => {
  const response = await instance.post(`/WidgetType`, data);
  return response.data;
};

export const updateWidgetType = async (data: object) => {
  const response = await instance.put(`/WidgetType`, data);
  return response.data;
};

export const deleteWidgetType = async (id: number) => {
  const response = await instance.delete(`/WidgetType/${id}`);
  return response.data;
};

export const getWidgetPhotos = async (widgetId: number) => {
  const response = await instance.get(`/WidgetPhoto/query/widget/${widgetId}`);
  return response.data;
};

export const createWidgetPhoto = async (data: object) => {
  const response = await instance.post(`/WidgetPhoto`, data);
  return response.data;
};

export const deleteWidgetPhoto = async (id: number) => {
  const response = await instance.delete(`/WidgetPhoto/${id}`);
  return response.data;
};

export const authRecover = async (data: object) => {
  const response = await instance.post(`/Auth/recover`, data);
  return response.data;
};
