// utility function to make sure the value of each field is not empty

import { Field, FormDataType } from "../types/index";

export const validateFormFields = (
  fields: Field[],
  formValues: FormDataType
) => {
  fields.forEach((field) => {
    if (!formValues[field.fieldName] && field.isRequired) {
      throw new Error(`${field.fieldName} cannot be empty`);
    }
  });

  const hasPasswordConfirmationField = fields.some(
    (field) => field.fieldName === "passwordConfirmation"
  );

  if (hasPasswordConfirmationField) {
    if (formValues["passwordConfirmation"] !== formValues["password"]) {
      throw new Error("Password and Confirmation does not match.");
    }
  }
};
