import axios from "axios";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getTicketById } from "../data/httpClient";
import {
  GroupContext,
  GroupContextInterface,
} from "../data/providers/GroupProvider";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { Group, Round } from "../types";

export const AssignRoundModal = ({
  group,
  ticketGroups,
}: {
  group: Group;
  ticketGroups: Group[];
}) => {
  const { tournamentId, ticketId } = useParams();

  const { getGroupsState, updateGroupsState } = useContext(
    GroupContext
  ) as GroupContextInterface;

  const { roundsState, getRoundsState } = useTournamentStateContext();

  const [isDataLoading, setIsDataLoading] = useState(false);

  const groupCanBeAddedToRound = (round: Round): boolean => {
    return round.attendeeSpotsAvailiable - group.metaGroupMaxAttendees >= 0;
  };

  const getTournamentId = async () => {
    let tournId = parseInt(tournamentId ?? "");
    if (!tournId) {
      const tickId = parseInt(ticketId ?? "");
      if (!tickId) {
        throw new Error("Couldn't get tournament id");
      }
      const ticketRecord = await getTicketById(tickId);
      tournId = parseInt(ticketRecord?.tournamentId);
      if (!tournId) {
        throw new Error("Couldn't get tournament id");
      }
    }
    return tournId;
  };

  useEffect(() => {
    let active = true;

    (async () => {
      try {
        setIsDataLoading(true);
        const tournId = await getTournamentId();
        if (active) {
          await getRoundsState(tournId, ticketGroups);
        }
      } catch (error) {
        // request errors (i.e. axios errors) already print to the console and alert user of error (see httpClient.ts)
        if (!axios.isAxiosError(error)) {
          console.error("Error fetching data:", error);
          alert("There was an error fetching data.");
        }
      } finally {
        if (active) {
          setIsDataLoading(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [group, ticketGroups, tournamentId, ticketId]);

  return (
    <div className="assignRoundModalContent">
      <h5>Change Round</h5>

      {/* else condition prevents any currently displayed round info from shifting on screen */}
      {isDataLoading ? <p>Loading...</p> : <p>&nbsp;</p>}

      <section className="assignRoundWrapper">
        {roundsState
          ?.filter((round) =>
            round.enabledDisabledTicketCategories.some(
              (category) =>
                category.ticketCategoryId === group.metaTicketCategoryId &&
                category.enabled
            )
          )
          .map((r: Round) => (
            <article
              className="eachRoundWrapper"
              key={`group-attendee-${r.id}`}
            >
              <section className="infoWrapper">
                <p>{r.name}</p>

                {r.id === group.roundId ? (
                  <p className="computed-text good"> Group in Round</p>
                ) : groupCanBeAddedToRound(r) ? (
                  <p className="computed-text good">
                    {r.attendeeSpotsAvailiable} Spots Available
                  </p>
                ) : (
                  <p className="computed-text not-good">
                    Insufficient Spots for Group
                  </p>
                )}

                <p>
                  Start:{" "}
                  {dayjs(`${r.startDate} ${r.startTime}`).format(
                    "M/D/YYYY h:mm A"
                  )}
                </p>
                <p className="description">{r.description}</p>
              </section>

              {r.id === group.roundId ? (
                <button
                  onClick={async () => {
                    await updateGroupsState({
                      id: group.id,
                      ticketId: ticketId,
                      roundId: null,
                      name: group.name,
                    });
                    alert("Group is removed from round successfully.");
                    if (ticketId) await getGroupsState(parseInt(ticketId));
                  }}
                  className={"removeBtn"}
                >
                  Remove Group From Round
                </button>
              ) : (
                <button
                  onClick={async () => {
                    await updateGroupsState({
                      id: group.id,
                      ticketId: ticketId,
                      roundId: r.id,
                      name: group.name,
                    });
                    alert("Group is assigned successfully.");
                    if (ticketId) await getGroupsState(parseInt(ticketId));
                  }}
                  className={
                    !groupCanBeAddedToRound(r)
                      ? "btnPrimaryDisabled"
                      : "assignBtn"
                  }
                  disabled={!groupCanBeAddedToRound(r)}
                >
                  Assign Group To Round
                </button>
              )}
            </article>
          ))}
      </section>
    </div>
  );
};
