import "../assets/scss/main.scss";

import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import FormFields from "../components/FormFields";
import { getTicketCategoryById } from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import ticketCategoryConfig from "../data/ticketCategoryConfig";
import { FormDataType } from "../types";
import { constructEmptyForm } from "../utils/constructEmptyForm";

export const EditTicketCategoryPage = () => {
  const initialized = useRef(false);

  const { tournamentId, attendeeOrSponsor, ticketCategoryId } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  const [formData, setFormData] = useState<FormDataType>(
    constructEmptyForm(ticketCategoryConfig.createAndEditFields)
  );

  const getData = async () => {
    if (ticketCategoryId) {
      const result = await getTicketCategoryById(parseInt(ticketCategoryId));
      setFormData(
        ticketCategoryConfig.createAndEditFields.reduce(
          (prev, field) => ({
            ...prev,
            [field.fieldName]:
              result[field.fieldName] ?? formData[field.fieldName],
            sponsorFlag: attendeeOrSponsor === "sponsor" ? true : false,
            id: ticketCategoryId,
            tournamentId: tournamentId && parseInt(tournamentId),
          }),
          {}
        )
      );
    }
  };

  useEffect(() => {
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
    }
  }, [tournamentId]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getData();
    }
  }, [ticketCategoryId]);

  return (
    <div className="editTicketCategoryWrapper">
      <h5 className="editTicketCategoryTitle">
        Edit <span>{attendeeOrSponsor}</span> Ticket Category
      </h5>

      {attendeeOrSponsor && (
        <FormFields
          fields={ticketCategoryConfig.createAndEditFields}
          form={{ method: "edit", data: formData }}
          setFormData={(_, data) => setFormData(data)}
          formMode={"edit"}
          name={`${attendeeOrSponsor}TicketCategory`}
          onCreateFunction={ticketCategoryConfig.onCreate}
          onUpdateFunction={ticketCategoryConfig.onUpdate}
        />
      )}
    </div>
  );
};
