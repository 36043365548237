import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FormFields from "../components/FormFields";
import { EuserContext } from "../data/providers/EuserProvider";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import tournamentConfig from "../data/tournamentConfig";
import { FormDataType } from "../types";
import { constructEmptyForm } from "../utils/constructEmptyForm";

function EditTournamentPage() {
  const { tournamentState, setTournamentById } = useTournamentStateContext();

  const { tournamentId } = useParams();

  const euserContext = useContext(EuserContext);
  const { euserIsAdmin } = euserContext;

  const [formData, setFormData] = useState<FormDataType>(
    constructEmptyForm(tournamentConfig.createAndEditFields)
  );

  useEffect(() => {
    (async () => {
      try {
        const id = parseInt(tournamentId ?? "");
        await setTournamentById(id);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("There was an error fetching data.");
      }
    })();
  }, []);

  useEffect(() => {
    setFormData(
      tournamentConfig.createAndEditFields.reduce(
        (prev, field) => ({
          ...prev,
          [field.fieldName]:
            // @ts-ignore
            tournamentState[field.fieldName] ?? formData[field.fieldName],
          id: tournamentState.id,
        }),
        {}
      )
    );
  }, [tournamentId, tournamentState]);

  return (
    <div className="ticketCategoryPageWrapper">
      <h5 className="ticketCategoryPageTitle">General Information</h5>

      <FormFields
        fields={tournamentConfig.createAndEditFields}
        form={{ method: "edit", data: formData }}
        setFormData={(_, data) => setFormData(data)}
        formMode={euserIsAdmin() ? "edit" : "read"}
        name={tournamentConfig.name}
        onCreateFunction={tournamentConfig.onCreate}
        onUpdateFunction={tournamentConfig.onUpdate}
      />
    </div>
  );
}

export default EditTournamentPage;
