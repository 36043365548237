// WidgetContent.js
import React from "react";

import WidgetPhoto from "./WidgetPhotos";

type WidgetContentProps = {
  widgetTitle: string;
  jsonContent: string;
  widgetTypeId: number;
  widgetId: number;
};

const WidgetContent: React.FC<WidgetContentProps> = ({
  widgetTitle,
  jsonContent,
  widgetId,
  widgetTypeId,
}) => {
  let content = "";

  if (jsonContent != null) {
    content = JSON.parse(jsonContent).body;
  }

  return (
    <section>
      {widgetTitle && <h3>{widgetTitle}</h3>}

      <div
        className="tournament-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {/* Big assumption - sorry! Posts are the only type that don't have photos at the moment */}
      {widgetTypeId !== 1 && (
        <WidgetPhoto widgetId={widgetId} widgetTypeId={widgetTypeId} />
      )}
    </section>
  );
};

export default WidgetContent;
