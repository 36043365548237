import "../assets/scss/main.scss";

import Modal from "react-modal";

import { FormDataType } from "../types";
import { CloseModalIcon } from "./CloseModalIcon";
import FormFields from "./FormFields";

interface FormModalProps {
  tournamentId?: string;
  formModal: boolean;
  setFormModal: React.Dispatch<React.SetStateAction<boolean>>;
  createAndEditFields: {
    title?: string;
    fieldName: string;
    label: string;
    inputType: string;
    isRequired: boolean;
    isMutable?: boolean;
  }[];
  formConfig: {
    name: string;
    onGet: (tournamentId: number) => Promise<any>;
    onCreate: (data: object) => Promise<any>;
    onUpdate: (data: object) => Promise<any>;
    createAndEditFields: {
      title?: string;
      fieldName: string;
      label: string;
      inputType: string;
      isRequired: boolean;
      isMutable?: boolean;
    }[];
  };
  formData: FormDataType;
  formMethod: string;
  setFormData: React.Dispatch<React.SetStateAction<FormDataType>>;
  title?: string;
  prompt: string;
}

export const FormModal = ({
  tournamentId,
  formModal,
  setFormModal,
  createAndEditFields,
  formConfig,
  formData,
  formMethod,
  setFormData,
  title,
  prompt,
}: FormModalProps) => {
  return (
    <Modal
      isOpen={formModal}
      onRequestClose={() => setFormModal(false)}
      className="standardModal"
      contentLabel="Schedule Rounds Modal"
      ariaHideApp={false}
    >
      <CloseModalIcon onRequestClose={() => setFormModal(false)} />
      <div className="formModalContent">
        <h5>{title}</h5>
        <span>
          <i>{prompt}</i>
        </span>

        <FormFields
          tournamentId={tournamentId}
          fields={createAndEditFields}
          form={{ method: formMethod, data: formData }}
          setFormData={(_, data) => setFormData(data)}
          formMode={"create"}
          isModal={true}
          name={formConfig.name}
          onGetFunction={formConfig.onGet}
          onCreateFunction={formConfig.onCreate}
          onUpdateFunction={formConfig.onUpdate}
          setFormModal={setFormModal}
        />
      </div>
    </Modal>
  );
};
