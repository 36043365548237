import "../assets/scss/main.scss";

import { useEffect, useState } from "react";

import FormFields from "../components/FormFields";
import { getUserById } from "../data/httpClient";
import userConfig from "../data/userConfig";
import { FormDataType } from "../types";
import { constructEmptyForm } from "../utils/constructEmptyForm";
import useEuser from "../utils/useEuser"; // access to provider via "use" hook

function AccountEdit() {
  const [formData, setFormData] = useState<FormDataType>(
    constructEmptyForm(userConfig.euserEditFields)
  );
  const [passwordFormData, setPasswordFormData] = useState<FormDataType>(
    constructEmptyForm(userConfig.passwordUpdateFields)
  );

  const { euser } = useEuser();

  // useEffect hook is used to execute code that should run after every completed render of the component.
  useEffect(() => {
    if (euser !== null) {
      getData(parseInt(euser?.id));
    }
  }, [euser]);

  const getData = async (userId: number) => {
    const result = await getUserById(userId);
    const user = result;

    setFormData(
      userConfig.euserEditFields.reduce(
        (prev, field) => ({
          ...prev,
          [field.fieldName]:
            user[field.fieldName] ?? passwordFormData[field.fieldName],
          id: user.id,
        }),
        {}
      )
    );

    setPasswordFormData(
      userConfig.passwordUpdateFields.reduce(
        (prev, field) => ({
          ...prev,
          [field.fieldName]:
            user[field.fieldName] ?? passwordFormData[field.fieldName],
          id: user.id,
        }),
        {}
      )
    );
  };

  return (
    <>
      <div className="editPageWrapper">
        <h3 className="list_title">Account Edit</h3>

        <FormFields
          fields={userConfig.euserEditFields}
          form={{ method: "edit", data: formData }}
          setFormData={(_, data) => setFormData(data)}
          formMode={"edit"}
          name={userConfig.name}
          onCreateFunction={userConfig.onCreate}
          onUpdateFunction={userConfig.onUpdate}
        />
      </div>
      <div className="editPageWrapper">
        <h3 className="list_title">Sign In Credentials</h3>
        <FormFields
          fields={userConfig.passwordUpdateFields}
          form={{ method: "edit", data: passwordFormData }}
          setFormData={(_, data) => setPasswordFormData(data)}
          formMode={"edit"}
          name={"userPassword"}
          // onCreateFunction={userConfig.onCreate}
          onUpdateFunction={userConfig.onPasswordUpdate}
        />
      </div>
    </>
  );
}

export default AccountEdit;
