import { createTicketCategory, updateTicketCategory } from "./httpClient";

export const ticketCategoryConfig = {
  name: "ticketCategory",
  onCreate: createTicketCategory,
  onUpdate: updateTicketCategory,
  createAndEditFields: [
    {
      fieldName: "name",
      label: "Title",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "off",
    },
    {
      fieldName: "description",
      label: "Description",
      inputType: "textarea",
      isRequired: false,
      isMutable: true,
      autoComplete: "off",
    },
    {
      fieldName: "numGroups",
      label: "Number of Groups per Ticket",
      inputType: "number",
      isRequired: true,
      isMutable: true,
      minValue: 1,
      autoComplete: "off",
    },
    {
      fieldName: "numAttendees",
      label: "Number of Attendees per Group",
      inputType: "number",
      isRequired: true,
      isMutable: true,
      minValue: 1,
      autoComplete: "off",
    },
    // Passing this will require our end user to do math.
    // {
    //   fieldName: "maxTotalAttendees",
    //   label: "Max Total Attendees",
    //   inputType: "number",
    //   isRequired: true,
    //   isMutable: true,
    // },
    {
      fieldName: "maxTotalTickets",
      label: "Max Total Tickets",
      helperText:
        "Total number of ticket packages available for sale. Maximum total attendees will equal (Groups per Ticket × Attendees per Group × Max Total Tickets)",
      inputType: "number",
      isRequired: true,
      isMutable: true,
      minValue: 1,
      autoComplete: "off",
    },
    {
      fieldName: "noCostFlag",
      label: "No Cost",
      inputType: "toggle",
      isRequired: false,
      isMutable: true,
    },
    {
      fieldName: "price",
      label: "Price($)",
      inputType: "number",
      isRequired: true,
      isMutable: true,
      autoComplete: "off",
    },
    {
      fieldName: "embedCode",
      label: "Embed Code",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "off",
    },
    {
      fieldName: "liveFlag",
      label: "Live",
      inputType: "toggle",
      isRequired: false,
      isMutable: true,
    },
  ],
};

export default ticketCategoryConfig;
