import "../assets/scss/main.scss";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  addTagSvg,
  checkSvg,
  deleteTagSvg,
  downloadSvg,
  editSvg,
  trashSvg,
  unverifiedSvg,
} from "../assets/icons";
import {
  deleteSponsor,
  deleteUser,
  getAllSponsors,
  getAllUsers,
  // updateSponsor,
  verifyLogo,
} from "../data/httpClient";
import { Sponsor, User } from "../types";

export const UsersOrSponsorsPage = () => {
  const { tournamentId } = useParams();
  const navigate = useNavigate();

  const [tabSelected, setTabSelected] = useState("sponsor");

  const [missingLogoSelected, setMissingLogoSelected] = useState(false);
  const [unapprovedLogoSelected, setUnapprovedLogoSelected] = useState(false);

  const [users, setUsers] = useState<User[]>([]);

  const [sponsors, setSponsors] = useState<Sponsor[]>([]);

  const getData = async () => {
    if (tabSelected === "user") {
      const result = await getAllUsers();
      setUsers(result);
    } else {
      let result = await getAllSponsors();
      if (missingLogoSelected) {
        result = sponsors.filter((sponsor: Sponsor) => {
          return !sponsor.logos.length;
        });
        // console.log("sponsors-filtered-result", result);
      }
      if (unapprovedLogoSelected) {
        result = sponsors.filter((sponsor: Sponsor) => {
          // Check if the logos array exists and has at least one item
          // at this moment in time logos are an array. We will probably be changing that to a single item.
          if (sponsor.logos && sponsor.logos.length > 0) {
            // Return true or false based on the verified property of the logo
            return !sponsor.logos[0].verified;
          }
          // If there's no logos array or it's empty, treat it as not meeting the condition
          return false;
        });
      }
      setSponsors(result);
    }
  };

  useEffect(() => {
    getData();
  }, [tabSelected, missingLogoSelected, unapprovedLogoSelected]);

  return (
    <div className="usersPageWrapper">
      <section className="tabWrapper">
        <button
          className={`userTabBtn ${tabSelected === "sponsor" && "selected"}`}
          onClick={() => setTabSelected("sponsor")}
        >
          Sponsors
        </button>
        <button
          className={`userTabBtn ${tabSelected === "user" && "selected"}`}
          onClick={() => setTabSelected("user")}
        >
          Users
        </button>
      </section>

      <section className="userContentWrapper">
        <div className="topWrapper">
          <button
            className="addNewUserBtn"
            style={{ marginRight: "1em" }}
            onClick={() => {
              navigate(`/tournaments/${tournamentId}/admin/user-sponsor/new`, {
                state: { type: tabSelected },
              });
            }}
          >
            Add new {tabSelected}
          </button>

          <button
            className="addNewUserBtn"
            onClick={() => {
              navigate(`/tournaments/${tournamentId}/admin/userSponsor/new`, {
                state: { type: tabSelected },
              });
            }}
          >
            Link User to Sponsor
          </button>

          {tabSelected === "sponsor" && (
            <article className="filterWrapper">
              <button
                className={`filterTag ${missingLogoSelected && "tagSelected"}`}
                onClick={() => {
                  setMissingLogoSelected(!missingLogoSelected);
                }}
              >
                <span>Missing logos</span>
                {missingLogoSelected ? (
                  <img src={deleteTagSvg} alt="unselected filter tag" />
                ) : (
                  <img src={addTagSvg} alt="selected filter tag" />
                )}
              </button>
              <button
                className={`filterTag ${
                  unapprovedLogoSelected && "tagSelected"
                }`}
                onClick={() => {
                  setUnapprovedLogoSelected(!unapprovedLogoSelected);
                }}
              >
                <span>Unapproved logos</span>
                {unapprovedLogoSelected ? (
                  <img src={deleteTagSvg} alt="unselected filter tag" />
                ) : (
                  <img src={addTagSvg} alt="selected filter tag" />
                )}
              </button>
            </article>
          )}
        </div>
        <div>
          {" "}
          {/* Div that wraps both forms */}
          {tabSelected === "sponsor" ? (
            // sponsors table
            <table className="usersTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Logo</th>
                  <th>Download Logo</th>
                  <th>Logo Verified</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sponsors.map((u: Sponsor, i: number) => {
                  return (
                    <tr key={i}>
                      <td className="nameColumn">{u.name}</td>
                      <td className="descriptionTableColumn">
                        {u.description.length > 140
                          ? `${u.description.slice(0, 137)}...`
                          : u.description}
                      </td>
                      <td>
                        {u.logos.length > 0 ? (
                          <>
                            {/* <p>{u.logos.length}</p>  */}
                            <img
                              src={u.logos[0]?.imageUrl}
                              style={{ maxWidth: "100px" }}
                              alt="sponsor logo"
                            />
                          </>
                        ) : (
                          <p>No logo present</p>
                        )}
                      </td>
                      <td>
                        <a
                          href={u.logos[0]?.imageUrl}
                          download
                          target="_blank"
                          style={{ cursor: "pointer", display: "inline-block" }} // Ensures the area is clickable and changes the cursor to indicate this
                          rel="noopener noreferrer"
                        >
                          <img
                            src={downloadSvg}
                            className="iconAction"
                            alt="download logo"
                          />
                        </a>
                      </td>
                      <td>
                        {u.logos && u.logos[0]?.verified ? (
                          <button
                            className="verifyBtn"
                            onClick={async () => {
                              if (
                                window.confirm(
                                  "Are you sure to unverify this logo?"
                                )
                              ) {
                                u.logos[0].verified = false;
                                await verifyLogo(u.logos[0].id, false);
                                const result = await getAllSponsors();
                                setSponsors(result);
                              }
                            }}
                          >
                            <img
                              src={checkSvg}
                              className="iconAction"
                              alt="logo is verified"
                            />
                          </button>
                        ) : (
                          <button
                            className="verifyBtn"
                            onClick={async () => {
                              if (
                                window.confirm(
                                  "Are you sure to verify this logo?"
                                )
                              ) {
                                u.logos[0].verified = true;
                                await verifyLogo(u.logos[0].id, true);
                                const result = await getAllSponsors();
                                setSponsors(result);
                              }
                            }}
                          >
                            <img
                              src={unverifiedSvg}
                              className="iconAction"
                              alt="logo is unverified"
                            />
                          </button>
                        )}
                      </td>
                      <td>
                        <img
                          src={editSvg}
                          className="iconAction"
                          alt="edit sponsor"
                          onClick={() => {
                            navigate(
                              `/tournaments/${tournamentId}/admin/user-sponsor/${u.id}/edit`,
                              {
                                state: { type: tabSelected },
                              }
                            );
                          }}
                        />
                      </td>
                      <td>
                        <img
                          src={trashSvg}
                          className="iconAction"
                          alt="delete sponsor"
                          onClick={async () => {
                            if (
                              window.confirm(
                                "Are you sure to delete this sponsor?"
                              )
                            ) {
                              await deleteSponsor(u.id);
                              const result = await getAllSponsors();
                              setSponsors(result);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            // Users table

            <table className="usersTable">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Name first</th>
                  <th>Name Last</th>
                  <th>Roles</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.map((u: User, i: number) => {
                  return (
                    <tr key={i}>
                      <td className="nameColumn">{u.email}</td>
                      <td>{u.nameFirst}</td>
                      <td>{u.nameLast}</td>
                      <td>
                        {u.roleArray && u.roleArray.length > 0
                          ? u.roleArray.join(", ")
                          : "-"}
                      </td>
                      <td>
                        <img
                          src={editSvg}
                          className="iconAction"
                          alt="edit user"
                          onClick={() => {
                            navigate(
                              `/tournaments/${tournamentId}/admin/user-sponsor/${u.id}/edit`,
                              {
                                state: { type: tabSelected },
                              }
                            );
                          }}
                        />
                      </td>
                      <td>
                        <img
                          src={trashSvg}
                          className="iconAction"
                          alt="delete user"
                          onClick={async () => {
                            if (
                              window.confirm(
                                "Are you sure to delete this user?"
                              )
                            ) {
                              await deleteUser(u.id);
                              const result = await getAllUsers();
                              setUsers(result);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>{" "}
        {/* END Div that wraps both forms */}
      </section>
    </div>
  );
};
