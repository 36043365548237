import React, { useState } from "react";

import { Group } from "../../types";
import { getGroups, renameGroup, updateGroup } from "../httpClient";

export interface GroupContextInterface {
  ticketGroups: Group[];
  setTicketGroups: React.Dispatch<React.SetStateAction<Group[]>>;
  getGroupsState: (id: number) => Promise<any>;
  updateGroupsState: (data: object) => Promise<void>;
  renameGroupState: (data: object) => Promise<void>;
}

export const GroupContext = React.createContext<GroupContextInterface | null>(
  null
);

export const GroupProvider = ({ children }: any) => {
  const [ticketGroups, setTicketGroups] = useState<Group[]>([]);

  const getGroupsState = async (ticketId: number) => {
    const result = await getGroups(ticketId);
    setTicketGroups(result);
  };

  const updateGroupsState = async (data: object) => {
    await updateGroup(data);
  };

  const renameGroupState = async (data: object) => {
    await renameGroup(data);
  };

  return (
    <GroupContext.Provider
      value={{
        ticketGroups,
        getGroupsState,
        setTicketGroups,
        updateGroupsState,
        renameGroupState,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};
