import { createSponsor, updateSponsor } from "./httpClient";

export const sponsorConfig = {
  name: "sponsor",
  onCreate: createSponsor,
  onUpdate: updateSponsor,
  createAndEditFields: [
    {
      fieldName: "name",
      label: "Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "off",
    },
    {
      fieldName: "description",
      label: "Description",
      inputType: "textarea",
      isRequired: false,
      isMutable: true,
    },
    // {
    //   fieldName: "logos",
    //   label: "Logo",
    //   helperText:
    //     "*Please upload a high-resolution logo with transparent background, tightly cropped.",
    //   inputType: "file",
    //   isRequired: false,
    //   isMutable: true,
    // },
    {
      fieldName: "website",
      label: "Website",
      inputType: "textarea",
      isRequired: false,
      isMutable: true,
    },
    {
      fieldName: "phone",
      label: "Phone",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "tel",
    },
    {
      fieldName: "contactNameFirst",
      label: "Contact First Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
    },
    {
      fieldName: "contactNameLast",
      label: "Contact Last Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
    },
    {
      fieldName: "liveFlag",
      label: "Live",
      inputType: "toggle",
      isRequired: false,
      isMutable: true,
    },
  ],
};

export default sponsorConfig;
