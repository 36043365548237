import { useEffect, useState } from "react";

import { uploadSvg } from "../assets/icons";
import {
  createSponsorLogo,
  createWidgetPhoto,
  deleteSponsorLogo,
  deleteWidgetPhoto,
  getSponsorLogo,
  getWidgetPhotos,
} from "../data/httpClient";
import { Image } from "../types";

const FileImageManager = ({
  parentType,
  parentId,
  label,
  helperText,
  multiple,
}: {
  parentType: string;
  parentId: number;
  label: string;
  helperText?: string;
  multiple: boolean;
}) => {
  const [imageData, setImageData] = useState<Image[]>([]);
  const [pendingFiles, setPendingFiles] = useState<FileStatus[]>([]);
  const [hiddenFileInputId, setHiddenFileInputId] = useState("");

  useEffect(() => {
    if (parentId && parentType) {
      getImageData();
      setHiddenFileInputId(`hiddenFileInput-widget-photos-${parentId}`);
    }
  }, [parentId, parentType]);

  // photoRecords come over from widgets. We dont use that. We use Image
  // This is obtuse but the only difference is the key names. Same data types.
  type PhotoRecord = {
    id: number;
    photoFileName: string;
    photoContentType: string;
    photoFileSize: number;
    photoUrl: string;
  };

  const getImageData = async () => {
    let imageRecords: Array<Image> = [];
    if (parentType === "widget") {
      // Loop over photoRecords and map them to new ImageRecords
      const photoRecords = await getWidgetPhotos(parentId);
      imageRecords = photoRecords.map(
        (photo: PhotoRecord): Image => ({
          id: photo.id,
          imageFileName: photo.photoFileName,
          imageContentType: photo.photoContentType,
          imageFileSize: photo.photoFileSize.toString(),
          imageUrl: photo.photoUrl,
          parentType: "widget",
          parentId: parentId,
        })
      );
    }
    if (parentType === "sponsor") {
      imageRecords = await getSponsorLogo(parentId);
    }
    setImageData(imageRecords);
  };

  const deleteImage = async (id: number) => {
    // Call the http method
    if (parentType === "widget") {
      deleteWidgetPhoto(id);
    }
    if (parentType === "sponsor") {
      deleteSponsorLogo(id);
    }
    // remove the photo from our local collection
    setImageData((currentPhotos) =>
      currentPhotos.filter((photo) => photo.id !== id)
    );
  };

  const openFileUpload = (id: string) => {
    document.getElementById(id)!.click();
  };

  interface FileStatus {
    name: string;
    status: "Pending" | "Uploading" | "Done" | "Error";
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    // array of objects to track each status item.
    let pendingFilesArray: FileStatus[] = [];

    if (files && files.length > 0) {
      // WE HAVE FILES! Save item notes before processing
      pendingFilesArray = Array.from(files).map((file) => ({
        name: file.name,
        status: "Pending",
      }));
      setPendingFiles(pendingFilesArray);

      // Pass file to upload function.
      // Also pass the pending array. Not how I would do it but this works.
      Array.from(files).forEach((file, index) =>
        uploadFile(file, index, pendingFilesArray)
      );

      // We could reload the whole page from here, I doubt that is needed.
    } else {
      setPendingFiles([]);
    }
  };

  const uploadFile = async (
    file: File,
    index: number,
    pendingFilesArray: FileStatus[]
  ) => {
    // Update status to 'Uploading'
    pendingFilesArray[index].status = "Uploading";
    setPendingFiles([...pendingFilesArray]);

    // Build a form
    const formData = new FormData();

    if (parentType === "widget") {
      formData.append("Photo", file);
      formData.append("WidgetId", parentId.toString());
    }

    if (parentType === "sponsor") {
      formData.append("Image", file);
      formData.append("SponsorId", parentId.toString());
    }

    try {
      // Post to API
      const response = await parentUploadFunction(formData); // Needs something...
      console.log("File uploaded successfully:", response);
      // Update the status
      pendingFilesArray[index].status = "Done";
      setPendingFiles([...pendingFilesArray]);
    } catch (error) {
      console.error("Error uploading file:", error);
      pendingFilesArray[index].status = "Error";
      setPendingFiles([...pendingFilesArray]);
    }
  };

  // Moving this here breaks the error feedback that the widget had before abstraction.
  const parentUploadFunction = async (formData: FormData) => {
    if (parentType === "widget") {
      createWidgetPhoto(formData);
    }
    if (parentType === "sponsor") {
      createSponsorLogo(formData);
    }
  };

  return (
    <>
      <h5>
        {label} {multiple && <>{imageData.length}</>}
      </h5>

      {helperText && <p className="helperText">{helperText}</p>}

      <section className={"existing-photos"}>
        {imageData.map((image, index) => (
          <div className="photo-container" key={index}>
            {/* {photo.id} */}
            <img
              className={"image"}
              key={index}
              src={image.imageUrl}
              alt="golf"
              id="hopefund-image"
            />
            <button
              className="delete-button"
              onClick={() => deleteImage(image.id)}
            >
              X
            </button>
          </div>
        ))}
      </section>

      <div className="customFileInput">
        {/* Hidden, we will use this but call it via onClick */}
        <input
          className={"formFieldInput"}
          type={"file"}
          accept={"image/png, image/jpeg"}
          multiple={multiple}
          id={hiddenFileInputId}
          name={"photos"}
          onChange={handleFileChange}
        />

        <div>
          {pendingFiles.length > 0 && (
            <ul>
              Pending Files:{" "}
              {pendingFiles.map((item, index) => (
                <li key={index}>
                  {item.name}: {item.status}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="uploadButtonGroup">
          <button
            className="uploadButtonIconWrapper"
            type="button"
            onClick={() => {
              openFileUpload(hiddenFileInputId);
            }}
            disabled={
              multiple === false &&
              (imageData.length > 0 || pendingFiles[0]?.status === "Done")
            }
          >
            <p className="uploadTxt">Upload</p>
            <img src={uploadSvg} alt="upload an image" className="uploadIcon" />
          </button>
        </div>
      </div>
    </>
  );
};

export default FileImageManager;
