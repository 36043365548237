import "../assets/scss/main.scss";

import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";

import { CloseModalIcon } from "../components/CloseModalIcon";
import { CreateWidgetModal } from "../components/CreateWidgetModal";
import SortableList from "../components/DragDropSortingList";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import {
  WidgetContext,
  WidgetContextInterface,
} from "../data/providers/WidgetProvider";

export const ContentPage = () => {
  const { tournamentId } = useParams();
  const [newWidgetModal, setNewWidgetModal] = useState(false);

  const { tournamentIdState, setTournamentIdState } =
    useTournamentStateContext();

  const { getWidgetTypes, widgetTypes, getWidgets, widgets } = useContext(
    WidgetContext
  ) as WidgetContextInterface;

  const getData = async () => {
    if (tournamentIdState) {
      await getWidgetTypes();
      await getWidgets(tournamentIdState);
    }
  };

  useEffect(() => {
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
    }
  }, [tournamentId]);

  useEffect(() => {
    getData();
  }, [tournamentIdState]);

  // drag and drop sorting list
  const renderWidgets = () => {
    if (widgetTypes.length)
      return (
        <div>
          <button
            type="button"
            className="newCategoryBtn"
            onClick={() => {
              setNewWidgetModal(true);
            }}
          >
            New Widget
          </button>

          <SortableList
            setShowWidgetMenu={setNewWidgetModal}
            tournamentId={tournamentIdState}
            widgets={widgets}
          />
        </div>
      );
  };

  return (
    <div className="ticketCategoryPageWrapper">
      <h5 className="ticketCategoryPageTitle">Event Content</h5>

      {renderWidgets()}
      <Modal
        isOpen={newWidgetModal}
        onRequestClose={() => setNewWidgetModal(false)}
        className="standardModal"
        contentLabel="Create Widget Modal"
        ariaHideApp={false}
      >
        <CloseModalIcon onRequestClose={() => setNewWidgetModal(false)} />
        <CreateWidgetModal
          setModal={setNewWidgetModal}
          widgets={widgets}
          widgetTypes={widgetTypes}
        />
      </Modal>
    </div>
  );
};
