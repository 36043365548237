import { createTicket, updateTicket } from "./httpClient";

const sponsorTicketConfig = {
  name: "ticket",
  onCreate: createTicket,
  onUpdate: updateTicket,
  createAndEditFields: [
    {
      fieldName: "ticketCategoryId",
      label: "Ticket Type",
      inputType: "dropdown",
      isRequired: true,
      isMutable: true,
    },
    {
      fieldName: "userId",
      label: "Purchaser (Ticket Holder)",
      inputType: "suggestion",
      isRequired: true,
      isMutable: true,
    },
    {
      fieldName: "userParticipating",
      label: "Will the ticket holder participate in the event?",
      inputType: "toggle",
      isRequired: false,
      isMutable: true,
    },
    {
      prompt:
        "If your sponsor is not listed below, please reach out to golfforhope@hcahealthcare.com.",
      fieldName: "sponsorId",
      label: "Sponsor",
      inputType: "dropdown",
      isRequired: true,
      isMutable: true,
    },
  ],
};

export default sponsorTicketConfig;
