// WidgetContent.js
import React, { useEffect, useState } from "react";

import { getWidgetPhotos } from "../../data/httpClient";
// @ts-ignore
import { WidgetPhoto } from "../../types";

type WidgetPhotoProps = {
  widgetId: number;
  widgetTypeId: number;
};

const WidgetPhotoDisplay: React.FC<WidgetPhotoProps> = ({ widgetId }) => {
  const [allPhotos, setAllPhotos] = useState<WidgetPhoto[]>([]);

  useEffect(() => {
    getWidgetPhotos(widgetId).then(setAllPhotos);
  }, [widgetId]);

  // NOTE ABOUT URL.
  // The rails app used to take full sized images and downsize smartly.
  // we have both the full size .jpg and a smaller .png in cloud storage.
  // The database thinks we have .jpgs and we do, but we load the png to save speed
  // Finishing the widget upload/edit feature should validate max image size. & this can go.
  return (
    <div className="tournament-content">
      {/* <p>{widgetTypeId}</p> */}
      {allPhotos.map((photo, index) => (
        <img
          key={index}
          src={photo.photoUrl}
          alt="golf"
          id="tournament-photo"
        />
      ))}
    </div>
  );
};

export default WidgetPhotoDisplay;
