import { createTournament, updateTournament } from "./httpClient";

const tournamentConfig = {
  name: "tournament",
  onCreate: createTournament,
  onUpdate: updateTournament,
  createAndEditFields: [
    {
      fieldName: "name",
      label: "Tournament Name",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "off",
    },
    {
      fieldName: "slug",
      label: "Tournament Slug",
      helperText:
        "unique URL for this tournament, ex hopefund.com/this_is_a_slug",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "off",
    },
    {
      fieldName: "description",
      label: "Tournament Description",
      inputType: "textarea",
      isRequired: true,
      isMutable: true,
      autoComplete: "off",
    },
    {
      fieldName: "dateStart",
      label: "Tournament Start Date",
      inputType: "date",
      isRequired: true,
      isMutable: true,
    },
    {
      fieldName: "dateEnd",
      label: "Tournament End Date",
      inputType: "date",
      isRequired: true,
      isMutable: true,
    },
    // Removed for now. Not needed for MVP.
    // {
    //   fieldName: "logo",
    //   label: "Logo",
    //   inputType: "file",
    //   isRequired: false,
    //   isMutable: true,
    // },
    // {
    //   fieldName: "batterPhoto",
    //   label: "Banner Photo",
    //   inputType: "file",
    //   isRequired: false,
    //   isMutable: true,
    // },
    {
      fieldName: "locationTitle",
      label: "Tournament Venue",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "off",
    },
    {
      fieldName: "locationAddress",
      label: "Tournament Location",
      inputType: "text",
      isRequired: false,
      isMutable: true,
      autoComplete: "street-address",
    },
    {
      fieldName: "liveFlag",
      label: "Live",
      inputType: "toggle",
      isRequired: false,
      isMutable: true,
    },
  ],
};

export default tournamentConfig;
