import "../../assets/scss/main.scss";

interface StatsModalProps {
  close: () => void;
}

export const StatsModal = ({ close }: StatsModalProps) => {
  return (
    <div className="checkInStatsModalContent">
      TBD
      <div className="btnContainer">
        <button
          className="greyBtn"
          onClick={() => {
            close();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};
