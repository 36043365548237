import "../assets/scss/main.scss";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import FormFields from "../components/FormFields";
import { getAllRoles } from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import sponsorConfig from "../data/sponsorConfig";
import userConfig from "../data/userConfig";
import { FormDataType, Role } from "../types";

export const CreateSponsorOrUserPage = () => {
  const { state } = useLocation();
  const { type } = state;

  const { tournamentId } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  const [roleDropdownData, setRoleDropdownData] = useState<Role[]>([]);

  useEffect(() => {
    if (tournamentId) setTournamentIdState(parseInt(tournamentId));

    if (type === "user") {
      getData();
    }
  }, [tournamentId]);

  const getData = async () => {
    const roles = await getAllRoles();
    setRoleDropdownData(roles);
  };

  const [formData, setFormData] = useState<FormDataType>(
    (type === "sponsor"
      ? sponsorConfig
      : userConfig
    ).createAndEditFields.reduce(
      (prev, field) => ({
        ...prev,
        [field.fieldName]: "",
      }),
      {}
    )
  );

  const fieldOptionData = {
    roleArray: roleDropdownData,
  };

  return (
    <div className="createSponsorOrUserWrapper">
      <h4>{type === "sponsor" ? "Sponsor" : "User"} Information</h4>

      <FormFields
        fields={
          (type === "sponsor" ? sponsorConfig : userConfig).createAndEditFields
        }
        form={{ method: "create", data: formData }}
        setFormData={(_, data) => setFormData(data)}
        formMode={"create"}
        name={(type === "sponsor" ? sponsorConfig : userConfig).name}
        onCreateFunction={
          (type === "sponsor" ? sponsorConfig : userConfig).onCreate
        }
        onUpdateFunction={
          (type === "sponsor" ? sponsorConfig : userConfig).onUpdate
        }
        fieldOptionData={fieldOptionData}
      />
    </div>
  );
};
