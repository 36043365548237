import "../assets/scss/main.scss";

import { useState } from "react";

import FormFields from "../components/FormFields";
import euserConfig from "../data/euserConfig"; // Form config
import { FormDataType } from "../types";
import useEuser from "../utils/useEuser"; // access to provider via "use" hook

export const AccountSignUp = () => {
  const { recoverEuser } = useEuser();

  const [formData, setFormData] = useState<FormDataType>(
    euserConfig.recoverFields.reduce(
      (prev, field) => ({
        ...prev,
        [field.fieldName]: "",
      }),
      {}
    )
  );

  const afterPostFunction = () => {
    return new Promise<void>((resolve, reject) => {
      try {
        alert(
          "Recovery process has been initiated. See your email for next step instructions."
        );
        window.location.reload();
        resolve(); // Resolve the promise when done
      } catch (error) {
        reject(error); // Reject the promise if there's an error
      }
    });
  };

  return (
    <div className="editPageWrapper">
      <h3 className="list_title">Account Recover</h3>

      <FormFields
        fields={euserConfig.recoverFields}
        form={{ method: "post", data: formData }}
        setFormData={(_, data) => setFormData(data)}
        formMode={"post"}
        name={euserConfig.name}
        onPostFunction={recoverEuser}
        afterPostFunction={afterPostFunction} // Not used here, needs cleanup
      />
    </div>
  );
};

export default AccountSignUp;
