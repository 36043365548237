import "quill/dist/quill.snow.css";
import "../assets/scss/main.scss";

import Quill from "quill";
import { useEffect, useRef, useState } from "react";

type RichEditorProps = {
  inputValue: string | null | undefined;
  setInputValue: (value: string) => void;
};

// ToDo: Ideally we'd store quill deltas (which is a subset of json) in the db vs the current json that's used.
// We'd then no longer need to use jsonToHtml, htmlToJson, getSemanticHTML (instead, we'd use getContents), and clipboard.convert.

function jsonToHtml(json: string) {
  const inputJson: { body: string } = JSON.parse(json);
  const html = inputJson.body;
  return html;
}

function htmlToJson(html: string) {
  return JSON.stringify({ body: html });
}

export const RichEditor = ({ inputValue, setInputValue }: RichEditorProps) => {
  const [htmlFromEditor, setHtmlFromEditor] = useState("");
  const containerRef = useRef(null);
  const quillRef = useRef<Quill>();
  const isDataInitialized = useRef(false);

  useEffect(() => {
    if (containerRef.current) {
      quillRef.current = new Quill(containerRef.current, {
        theme: "snow",
        bounds: containerRef.current,
      });

      quillRef.current.on("text-change", () => {
        setHtmlFromEditor(quillRef.current!.getSemanticHTML());
      });
    }

    return () => {
      containerRef.current = null;
    };
  }, []);

  // get initial value for editor to display; inputValue may not be loaded when component is mounted
  useEffect(() => {
    if (!isDataInitialized.current && inputValue && quillRef.current) {
      // ToDo: clipboard.current() is not in the quill documentation... ideally we wouldn't have to use it
      const delta = quillRef.current.clipboard.convert({
        html: jsonToHtml(inputValue),
      });
      quillRef.current.setContents(delta);
      isDataInitialized.current = true;
    }
  }, [inputValue]);

  useEffect(() => {
    setInputValue(htmlToJson(htmlFromEditor));
  }, [htmlFromEditor]);

  return (
    <div className="editor-wrapper">
      <div ref={containerRef}></div>
    </div>
  );
};
