import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { exitSvg, hamburgerSvg } from "../assets/icons";
import EuserActionItems from "./Euser";
import TournamentActionItems from "./Tournament";

export const TournamentNav = () => {
  const navigate = useNavigate();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const renderMenu = () => {
    return (
      <>
        {<TournamentActionItems />}
        <hr />

        <ul className="BlueNavMenu">
          <li className="BlueNavMenuItem">
            <button
              type="button"
              className="BlueNavOption"
              onClick={() => navigate("/tournaments")}
            >
              All Tournaments
            </button>
          </li>
        </ul>

        <hr className="mobileBlueNavLine" />
        {<EuserActionItems />}
      </>
    );
  };

  // Below bits are purely used for mobile styles both call renderMenu()

  return (
    <>
      {/* mobile hamburger */}
      <div className="mobileBlueNavWrapper">
        <button
          type="button"
          className="mobileBlueNavOpen"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <img src={hamburgerSvg} alt="open nav menu" />
        </button>

        {showMobileMenu && (
          <section className="mobileBlueNavMenu">
            <button
              type="button"
              className="mobileBlueNavClose"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <img src={exitSvg} alt="close nav menu" />
            </button>
            {renderMenu()}
          </section>
        )}
      </div>

      {/* desktop side nav */}
      <div className="BlueNavWrapper">{renderMenu()}</div>
    </>
  );
};
