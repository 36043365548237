import "../assets/scss/main.scss";

import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { backSvg } from "../assets/icons";
import { EachGroup } from "../components/EachGroup";
import { getTicketById } from "../data/httpClient";
import {
  GroupContext,
  GroupContextInterface,
} from "../data/providers/GroupProvider";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { Group } from "../types";

function TicketGroupPage() {
  const navigate = useNavigate();

  const { tournamentId, attendeeOrSponsor, ticketId } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  const { ticketGroups, getGroupsState } = useContext(
    GroupContext
  ) as GroupContextInterface;

  const getData = async () => {
    // If tournament came in from the url use it.
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
    } else {
      // get tournament from ticket payload.
      if (ticketId) {
        const ticketRecord = await getTicketById(Number(ticketId));
        setTournamentIdState(parseInt(ticketRecord.tournamentId));
      }
    }

    if (ticketId) {
      await getGroupsState(parseInt(ticketId));
    }
  };

  useEffect(() => {
    getData();
  }, [tournamentId, attendeeOrSponsor]);

  return (
    <div className="ticketGroupPageWrapper">
      <section className="topWrapper">
        <button className="backBtn" onClick={() => navigate(-1)}>
          <img src={backSvg} alt="go back" />
        </button>
        <h5 className="ticketsPageTitle">Tickets & Reservations</h5>
      </section>

      <h6>Total Groups Allowed: {ticketGroups[0]?.metaTicketNumGroup}</h6>
      <hr />

      <section className="groupsWrapper">
        {ticketGroups.map((g: Group, i: number) => (
          <article key={`group-${i}`} className="groupWrapper">
            <EachGroup group={g} ticketGroups={ticketGroups} />
          </article>
        ))}
      </section>
    </div>
  );
}

export default TicketGroupPage;
