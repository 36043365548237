// component for whole form

import "../assets/scss/main.scss";

import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CreateAndEditFieldType, Field, Group } from "../types";
import { Form, FormDataType } from "../types";
import { renderFormField } from "../utils/renderFormField";
import { validateFormFields } from "../utils/validateFormFields";

type FormFieldsProps = {
  tournamentId?: string;
  ticketId?: string;
  setTicketGroups?: React.Dispatch<React.SetStateAction<Group[]>>;
  fields: CreateAndEditFieldType[] | Field[];
  form: Form;
  setFormData: (id: string, data: FormDataType) => void;
  formMode: string;
  isCustomForm?: boolean;
  isModal?: boolean;
  name?: string;
  onGetFunction?: (id: number) => Promise<any>;
  onCreateFunction?: (data: object) => Promise<any>;
  onUpdateFunction?: (data: object) => Promise<any>;
  onPostFunction?: (data: object) => Promise<any>;
  afterCreateFunction?: (data: object) => Promise<any>;
  afterUpdateFunction?: (data: object) => Promise<any>;
  afterPostFunction?: (data: object) => Promise<any>;
  setFormModal?: React.Dispatch<React.SetStateAction<boolean>>;
  fieldOptionData?: Record<string, any[]>;
  showBackButton?: boolean;
  recordId?: number;
};

export const FormFields = ({
  tournamentId,
  ticketId,
  fields,
  form,
  setFormData,
  formMode,
  isCustomForm,
  isModal = false,
  name,
  onGetFunction,
  onCreateFunction,
  onUpdateFunction,
  onPostFunction,
  afterCreateFunction,
  afterUpdateFunction,
  afterPostFunction,
  setFormModal,
  fieldOptionData,
  showBackButton = true,
  recordId,
}: FormFieldsProps) => {
  const navigate = useNavigate();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const setFormKeyValue = ({
    key,
    value,
  }: {
    key: string;
    value: string | boolean;
  }) => {
    if (key in form.data) {
      setFormData(form.method, {
        ...form.data,
        [key]: value,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;

    try {
      validateFormFields(fields, form.data);
    } catch (err) {
      alert(err);
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = async (evt: MouseEvent<HTMLButtonElement>) => {
    try {
      evt.preventDefault();
      if (validateForm()) {
        if (form.method) {
          setSubmitDisabled(true);
          if (form.method === "post") {
            if (onPostFunction) {
              await onPostFunction(form.data);
              if (afterPostFunction) {
                await afterPostFunction(form.data);
              }
            } else {
              throw new Error("onPostFunction Required for form.method post");
            }
          }

          if (form.method === "create") {
            if (onCreateFunction) {
              // append round toggle fields
              if (name === "round") {
                form.data.enabledDisabledTicketCategories = [];
                Object.keys(form.data).forEach((k: string) => {
                  if (k.includes("ticketCategoryId")) {
                    // @ts-ignore
                    form.data.enabledDisabledTicketCategories.push({
                      ticketCategoryId: k.match(/\d+/g)![0],
                      enabled: form.data[k] ? true : false,
                    });
                  }
                });
                setFormData("", form.data);
              }
              // add group teammate
              // if (name === "addTeammate" && typeof form.data.email === "string") {
              //   // @ts-ignore
              //   form.data.userId = Number(form.data.email.match(/\d+/g)![0]);
              //   setFormData("", form.data);
              // }
              // if (name === "sponsor-ticket") {
              //   // @ts-ignore
              //   form.data.sponsorId = Number(form.data.sponsor.match(/\d+/g)![0]);
              //   setFormData("", form.data);
              // }

              await onCreateFunction(form.data);
              alert(`New ${name} successfully created.`);
              if (afterCreateFunction) {
                await afterCreateFunction(form.data);
              } else {
                window.location.reload();
              }

              if (setFormModal) {
                if (name === "round" && tournamentId && onGetFunction) {
                  await onGetFunction(parseInt(tournamentId));
                  setFormModal(false);
                }
                if (name === "addTeammate" && ticketId && onGetFunction) {
                  await onGetFunction(parseInt(ticketId));
                  setFormModal(false);
                }
              }
            } else {
              throw new Error(
                "onCreateFunction Required for form.method create"
              );
            }
          }

          if (form.method === "edit") {
            if (onUpdateFunction) {
              if (name === "round") {
                form.data.enabledDisabledTicketCategories = [];
                Object.keys(form.data).forEach((k: string) => {
                  if (k.includes("ticketCategoryId")) {
                    // @ts-ignore
                    form.data.enabledDisabledTicketCategories.push({
                      ticketCategoryId: k.match(/\d+/g)![0],
                      enabled: form.data[k] ? true : false,
                    });
                  }
                });
                setFormData("", form.data);
              }

              // if (name === "user") {
              //   console.log(form)
              // }

              await onUpdateFunction(form.data);
              alert(`${name} successfully updated.`);

              if (setFormModal) {
                if (name === "round" && tournamentId && onGetFunction) {
                  await onGetFunction(parseInt(tournamentId));
                  setFormModal(false);
                }
                if (name === "Group" && ticketId && onGetFunction) {
                  await onGetFunction(parseInt(ticketId));
                  setFormModal(false);
                }
              }
              if (afterUpdateFunction) {
                await afterUpdateFunction(form.data);
              }
            } else {
              throw new Error("onUpdateFunction Required for form.method edit");
            }
          }
        }
      }
    } finally {
      setSubmitDisabled(false);
    }
  };

  let submitText;
  if (formMode === "post") {
    submitText = "Submit";
  } else if (formMode === "edit") {
    submitText = "Save Changes";
  } else if (formMode === "create") {
    submitText = "Create";
  }

  return (
    <div className="formWrapper">
      <form>
        <div className="formFields">
          {fields.map((field) =>
            renderFormField(
              field,
              form.data,
              setFormKeyValue,
              formMode,
              fieldOptionData?.[field.fieldName],
              recordId
            )
          )}
        </div>

        {!isCustomForm && (
          <section className="btnGroup">
            {formMode !== "read" && (
              <button
                className="submitFormBtn"
                onClick={onSubmit}
                disabled={submitDisabled}
              >
                {submitText}
              </button>
            )}
            {!isModal && showBackButton && (
              <button className="backFormBtn" onClick={() => navigate(-1)}>
                Back
              </button>
            )}
            {isModal && setFormModal && (
              <button
                className="cancelFormBtn"
                onClick={() => setFormModal(false)}
              >
                Cancel
              </button>
            )}
          </section>
        )}
      </form>
    </div>
  );
};

export default FormFields;
