import "../assets/scss/main.scss";

import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { EachRound } from "../components/EachRound";
import { FormModal } from "../components/FormModal";
import { getTournamentTicketCategories } from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { GetRoundConfig } from "../data/roundConfig";
import { FormDataType, TicketCategory } from "../types";

export const ScheduleRoundsPage = () => {
  const initialized = useRef(false);

  const { tournamentState, roundsState, getRoundsState, setTournamentIdState } =
    useTournamentStateContext();

  const { tournamentId } = useParams();

  const [formModal, setFormModal] = useState(false);

  const [newCreateAndEditFields, setNewCreateAndEditFields] = useState([
    ...GetRoundConfig().createAndEditFields,
  ]);

  const [formData, setFormData] = useState<FormDataType>({});

  const getData = async () => {
    if (tournamentId) {
      const ticketCategories = await getTournamentTicketCategories(
        parseInt(tournamentId)
      );
      ticketCategories.forEach((t: TicketCategory) => {
        newCreateAndEditFields.push({
          fieldName: `ticketCategoryId-${t.id}`,
          label: t.name,
          inputType: "toggle",
          isRequired: false,
          isMutable: true,
        });
        setNewCreateAndEditFields(newCreateAndEditFields);
        setFormData(
          newCreateAndEditFields.reduce(
            (prev, field) => ({
              ...prev,
              [field.fieldName]: "",
              tournamentId: tournamentId,
            }),
            {}
          )
        );
      });
    }
  };

  useEffect(() => {
    /* line 56-57 solves the issue:
      with react strictMode, useEffect initially runs twice; causing the createAndEditFields getting duplicated data */
    if (!initialized.current) {
      initialized.current = true;
      if (tournamentId) {
        getRoundsState(parseInt(tournamentId));
        setTournamentIdState(parseInt(tournamentId));
        getData();
      }
    }
  }, []);

  return (
    <div className="ticketCategoryPageWrapper">
      <h5 className="ticketCategoryPageTitle">Schedule Rounds</h5>

      <button
        type="button"
        className="scheduleNewBtn"
        onClick={() => setFormModal(true)}
      >
        Add New
      </button>

      <hr className="separator" />

      <section>
        {roundsState.map((r, i) => (
          <div key={i}>
            {tournamentId && (
              <EachRound round={r} tournamentId={parseInt(tournamentId)} />
            )}
          </div>
        ))}
      </section>

      {/* POST form modal */}
      <FormModal
        tournamentId={tournamentId}
        formModal={formModal}
        setFormModal={setFormModal}
        createAndEditFields={newCreateAndEditFields}
        formConfig={GetRoundConfig()}
        formData={formData}
        formMethod="create"
        setFormData={setFormData}
        title="Add New Round"
        prompt={`${dayjs(tournamentState.dateStart).format(
          "M/D/YYYY"
        )} to ${dayjs(tournamentState.dateEnd).format("M/D/YYYY")} `}
      />
    </div>
  );
};
