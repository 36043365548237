import React, { ReactNode, useState } from "react";

import { hamburgerSvg, trashSvg } from "../assets/icons";
import { deleteWidget } from "../data/httpClient";

type CollapsibleContentProps = {
  title: string;
  children: ReactNode;
  widgetId: number;
};

const CollapsibleWidget: React.FC<CollapsibleContentProps> = ({
  title,
  children,
  widgetId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const expandedClassName = isOpen ? "-expanded" : "";

  return (
    <div className="collapsible-widget-section">
      <div
        className="collapsible-widget-title"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          className="collapsible-hamburger"
          src={hamburgerSvg}
          alt="hamburger drop down icon"
        />

        <span className="collapsible-widget-indicator">
          {isOpen ? "▲" : "▼"}
        </span>
        <span>{title}</span>

        <button
          className="collapsible-trashcan"
          onClick={async () => {
            if (
              window.confirm("Are you sure you want to delete this widget?")
            ) {
              await deleteWidget(widgetId);
              alert("The widget was deleted successfully.");
              window.location.reload();
            }
          }}
        >
          <img src={trashSvg} className="iconAction" alt="delete this widget" />
        </button>
      </div>
      <div className={`collapsible-widget-content${expandedClassName}`}>
        {children}
      </div>
    </div>
  );
};

export default CollapsibleWidget;
