import dayjs from "dayjs";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { EuserContext } from "../../data/providers/EuserProvider";
import { Round, Sponsor, TicketCategory, Tournament } from "../../types";
import DateRangeFormatter from "../DateRangeFormatter";
import CollapsibleContent from "./CollapsibleContent";

type EventSideBarProps = {
  tournamentData?: Tournament;
  roundsData?: Round[];
  ticketCategoriesData?: TicketCategory[];
  sponsorData?: Sponsor[];
};

const EventSideBar: React.FC<EventSideBarProps> = ({
  tournamentData,
  roundsData,
  ticketCategoriesData,
  sponsorData,
}: EventSideBarProps) => {
  const navigate = useNavigate();
  const { euser } = useContext(EuserContext);

  const handleRegisterButtonClick = (ticketCategory: TicketCategory) => {
    if (!euser) {
      navigate("/account/signin");
    } else {
      const attendeeOrSponsor = ticketCategory.sponsorFlag
        ? "sponsor"
        : "attendee";
      navigate(
        `/tournaments/${tournamentData?.id}/admin/tickets/${attendeeOrSponsor}/new`,
        { state: { categoryId: ticketCategory.id } }
      );
    }
  };

  const allSoldOut = ticketCategoriesData
    ? ticketCategoriesData.every(
        (category) =>
          category.availableTickets <= 0 || category.availableTickets === null
      )
    : false;

  return (
    <section className="event-sidebar">
      <CollapsibleContent title="Details & Location">
        {tournamentData && (
          <div className="event-info-container">
            <div className="data-point">
              <h6>Event Name</h6>
              <p>{tournamentData.name}</p>
            </div>
            <div className="data-point">
              <h6>Event Start</h6>
              <DateRangeFormatter startDate={tournamentData.dateStart} />
            </div>
            <div className="data-point">
              <h6>Event End</h6>
              <DateRangeFormatter startDate={tournamentData.dateEnd} />
            </div>
            <div className="data-point">
              <h6>Event Location Name</h6>
              <p>{tournamentData.locationTitle}</p>
            </div>
            <div className="data-point">
              <h6>Event Location Address</h6>
              <p>{tournamentData.locationAddress}</p>
            </div>
          </div>
        )}
      </CollapsibleContent>
      <CollapsibleContent title="Schedule">
        {roundsData &&
          roundsData
            .filter((round) => round.liveFlag)
            .map((round, index) => (
              <div
                key={round.id || index}
                className="event-info-container"
                style={{ marginBottom: "1rem" }}
              >
                <div className="data-point">
                  <h5>{round.name}</h5>
                  <div>
                    <p className="round-des">{round.description}</p>
                    <p className="address-loc">
                      When: <DateRangeFormatter startDate={round.startDate} />{" "}
                      at{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {dayjs(`${round.startDate} ${round.startTime}`).format(
                          "h:mm A"
                        )}
                      </span>
                    </p>
                    <p className="address-loc">
                      Ends on: <DateRangeFormatter startDate={round.endDate} />{" "}
                      at{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {dayjs(`${round.endDate} ${round.endTime}`).format(
                          "h:mm A"
                        )}
                      </span>
                    </p>
                    <p className="address-loc">Where: {round.location}</p>

                    {round.attendeeSpotsAvailiable > 0 &&
                    round.attendeeSpotsAvailiable != null ? (
                      <p className="computed-text">
                        Attendee Spots Available:{" "}
                        {round.attendeeSpotsAvailiable}
                      </p>
                    ) : (
                      <p className="computed-text not-good">
                        Please consider another option.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
      </CollapsibleContent>
      <CollapsibleContent title="Tickets & Registration">
        {/* Prevents sold out message from rendering prematurely.*/}
        {ticketCategoriesData &&
          ticketCategoriesData.length > 0 &&
          allSoldOut && (
            <div className="data-point" id="sold-out-msg">
              <p className="computed-text not-good">
                All ticket packages are sold out for this event. Please{" "}
                <a href="https://www.hcahopefund.com/golfwaitlist/">
                  join the waitlist.
                </a>
              </p>
            </div>
          )}
        {ticketCategoriesData &&
          ticketCategoriesData.map((category, index) => (
            <div
              key={category.id || index}
              className="tick-regs event-info-container"
            >
              <div className="data-point">
                <h5>{category.name}</h5>
                <p>{category.description}</p>

                {category.availableTickets > 0 &&
                category.availableTickets != null ? (
                  <>
                    <p className="computed-text">
                      Ticket Packages Available: {category.availableTickets}
                    </p>
                    <div>
                      <button
                        className="btn btn-blue"
                        onClick={() => handleRegisterButtonClick(category)}
                      >
                        Register
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="computed-text not-good">
                      Tickets are unavailable for this category; please consider
                      another option.
                    </p>
                    <div>
                      <button className="btn btnPrimaryDisabled" disabled>
                        Unavailable
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
      </CollapsibleContent>

      <CollapsibleContent title="Sponsors" expanded={true}>
        {/* <h5 className="text-center event-sponsor-header">Sponsors</h5> */}
        <div className="event-sponsors-list">
          {sponsorData &&
            sponsorData.map((sponsor, index) => (
              <div key={sponsor.id || index} className="event-sponsor">
                {sponsor.logos &&
                sponsor.logos.length > 0 &&
                sponsor.logos[0].verified ? (
                  <img
                    src={sponsor.logos[0].imageUrl} //forbiden to retrieved.
                    alt={`${sponsor.name} logo`}
                  />
                ) : (
                  <h3 className="sponsor-name">{sponsor.name}</h3>
                )}
              </div>
            ))}
        </div>
      </CollapsibleContent>
    </section>
  );
};

export default EventSideBar;
