import "../src/assets/scss/main.scss";

import { Route, Routes } from "react-router-dom";

import AccountContainer from "./container/AccountContainer";
import TournamentContainer from "./container/TournamentContainer";
import RedirectToRecentTournament from "./navs/RedirectToRecentTournament";
import AccountEdit from "./pages/AccountEdit";
import AccountRecover from "./pages/AccountRecover";
import AccountReservations from "./pages/AccountReservations";
import AccountSignIn from "./pages/AccountSignIn";
import AccountSignUp from "./pages/AccountSignUp";
import AccountTickets from "./pages/AccountTickets";
import { CheckInPage } from "./pages/CheckInPage";
import { ContentPage } from "./pages/ContentPage";
import { CreateSponsorOrUserPage } from "./pages/CreateSponsorOrUserPage";
import { CreateTicketCategoryPage } from "./pages/CreateTicketCategoryPage";
import { CreateTicketPage } from "./pages/CreateTicketPage";
import { CreateTournamentPage } from "./pages/CreateTournamentPage";
import { CreateUserSponsorLinkingPage } from "./pages/CreateUserSponsorLinkingPage";
import { DataExportPage } from "./pages/DataExportPage";
import { EditRoundPage } from "./pages/EditRoundPage";
import EditSponsorOrUserPage from "./pages/EditSponsorOrUserPage";
import { EditTicketCategoryPage } from "./pages/EditTicketCategoryPage";
import EditTournamentPage from "./pages/EditTournamentPage";
import ListTournamentsPage from "./pages/ListTournamentsPage";
import { ScheduleRoundsPage } from "./pages/ScheduleRoundsPage";
import TicketCategoryPage from "./pages/TicketCategoryPage";
import TicketGroupPage from "./pages/TicketGroupPage";
import TicketsPage from "./pages/TicketsPage";
import { TournamentPreviewPage } from "./pages/TournamentPreviewPage";
import { TournamentRegisterPage } from "./pages/TournamentRegisterPage";
import { UsersOrSponsorsPage } from "./pages/UsersOrSponsorsPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<RedirectToRecentTournament />}></Route>

      <Route path="/tournaments" element={<TournamentContainer />}>
        <Route index element={<ListTournamentsPage />}></Route>
        <Route path="new" element={<CreateTournamentPage />}></Route>
        <Route
          path=":tournamentId/edit"
          element={<EditTournamentPage />}
        ></Route>

        <Route
          path=":tournamentId/register"
          element={<TournamentRegisterPage />}
        ></Route>

        {/* content */}
        <Route
          path=":tournamentId/admin/content/widgets"
          element={<ContentPage />}
        ></Route>

        {/* ticket category */}
        <Route
          path=":tournamentId/admin/ticket_categories/:attendeeOrSponsor"
          element={<TicketCategoryPage />}
        ></Route>
        <Route
          path=":tournamentId/admin/ticket_categories/:attendeeOrSponsor/new"
          element={<CreateTicketCategoryPage />}
        ></Route>
        <Route
          path=":tournamentId/admin/ticket_categories/:attendeeOrSponsor/:ticketCategoryId/edit"
          element={<EditTicketCategoryPage />}
        ></Route>

        {/* schedule rounds */}
        <Route
          path=":tournamentId/admin/rounds/new"
          element={<ScheduleRoundsPage />}
        ></Route>
        <Route
          path=":tournamentId/admin/rounds/:roundId/edit"
          element={<EditRoundPage />}
        ></Route>

        {/* tickets */}
        <Route
          path=":tournamentId/admin/tickets/:attendeeOrSponsor"
          element={<TicketsPage />}
        ></Route>
        <Route
          path=":tournamentId/admin/tickets/:attendeeOrSponsor"
          element={<TicketsPage />}
        ></Route>
        <Route
          path=":tournamentId/admin/tickets/:attendeeOrSponsor/new"
          element={<CreateTicketPage />}
        ></Route>
        <Route
          path=":tournamentId/admin/tickets/:attendeeOrSponsor/:ticketId/groups"
          element={<TicketGroupPage />}
        ></Route>

        {/* check-in */}
        <Route
          path=":tournamentId/admin/check-in"
          element={<CheckInPage />}
        ></Route>

        {/* data export */}
        <Route
          path=":tournamentId/admin/data-export"
          element={<DataExportPage />}
        ></Route>

        {/* user/sponsor */}
        <Route
          path=":tournamentId/admin/user-sponsor"
          element={<UsersOrSponsorsPage />}
        ></Route>
        <Route
          path=":tournamentId/admin/user-sponsor/new"
          element={<CreateSponsorOrUserPage />}
        ></Route>
        <Route
          path=":tournamentId/admin/user-sponsor/:userOrSponsorId/edit"
          element={<EditSponsorOrUserPage />}
        ></Route>
        <Route
          path=":tournamentId/admin/userSponsor/new"
          element={<CreateUserSponsorLinkingPage />}
        ></Route>
      </Route>

      <Route
        path="/tournaments/:tournamentId/:uniqueSlug"
        element={<TournamentPreviewPage />}
      ></Route>

      {/* Legacy app has this under tournaments/id/account */}
      <Route path="/account" element={<AccountContainer />}>
        <Route path="/account/signin" element={<AccountSignIn />}></Route>

        <Route path="/account/signup" element={<AccountSignUp />}></Route>

        <Route path="/account/recover" element={<AccountRecover />}></Route>

        <Route path="/account/edit" element={<AccountEdit />}></Route>

        <Route
          path="/account/reservations"
          element={<AccountReservations />}
        ></Route>

        <Route path="/account/tickets" element={<AccountTickets />}></Route>

        <Route
          path="/account/tickets/:ticketId/groups"
          element={<TicketGroupPage />}
        ></Route>

        <Route
          path="/account/sponsor/:userOrSponsorId/edit"
          element={<EditSponsorOrUserPage />}
        ></Route>
      </Route>
    </Routes>
  );
}

export default App;
