import HCALogo from "./HCALogo.png";
import active from "./svg/active.svg";
import add from "./svg/add.svg";
import addTag from "./svg/addTag.svg";
import arrowRight from "./svg/arrowRight.svg";
import auth from "./svg/auth.svg";
import back from "./svg/back.svg";
import caretDown from "./svg/caret-down-outline.svg";
import check from "./svg/check.svg";
import deleteTag from "./svg/deleteTag.svg";
import dollar from "./svg/dollar.svg";
import downArrow from "./svg/downArrow.svg";
import download from "./svg/download.svg";
import edit from "./svg/edit.svg";
import exit from "./svg/exit.svg";
import eye from "./svg/eye.svg";
import eyeSlash from "./svg/eyeSlash.svg";
import fontSize from "./svg/fontSize.svg";
import hamburger from "./svg/hamburger.svg";
import inactive from "./svg/inactive.svg";
import link from "./svg/link.svg";
import setting from "./svg/setting.svg";
import trash from "./svg/trash.svg";
import unlink from "./svg/unlink.svg";
import unverified from "./svg/unverified.svg";
import upArrow from "./svg/upArrow.svg";
import upload from "./svg/upload.svg";

export const activeSvg = active;
export const addSvg = add;
export const addTagSvg = addTag;
export const arrowRightSvg = arrowRight;
export const authSvg = auth;
export const backSvg = back;
export const caretDownSvg = caretDown;
export const checkSvg = check;
export const deleteTagSvg = deleteTag;
export const dollarSvg = dollar;
export const downArrowSvg = downArrow;
export const downloadSvg = download;
export const editSvg = edit;
export const exitSvg = exit;
export const eyeSlashSvg = eyeSlash;
export const eyeSvg = eye;
export const fontSizeSvg = fontSize;
export const hamburgerSvg = hamburger;
export const inactiveSvg = inactive;
export const linkSvg = link;
export const settingSvg = setting;
export const trashSvg = trash;
export const unlinkSvg = unlink;
export const unverifiedSvg = unverified;
export const upArrowSvg = upArrow;
export const uploadSvg = upload;

export const HCALogoImg = HCALogo;
