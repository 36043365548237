import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";

import { createGroup } from "../data/httpClient";
import {
  GroupContext,
  GroupContextInterface,
} from "../data/providers/GroupProvider";
import { Attendee, FormDataType, Group } from "../types";
import { AssignRoundModal } from "./AssignRoundModal";
import { CloseModalIcon } from "./CloseModalIcon";
import FormFields from "./FormFields";
import { ManageTeammateModal } from "./ManageTeammateModal";

interface EachGroupProp {
  group: Group;
  ticketGroups: Group[];
}

export const EachGroup = ({ group, ticketGroups }: EachGroupProp) => {
  const { ticketId } = useParams();

  const { getGroupsState, renameGroupState } = useContext(
    GroupContext
  ) as GroupContextInterface;

  const [manageTeammateModal, setManageTeammateModal] = useState(false);

  const [renameGroupModal, setRenameGroupModal] = useState(false);

  const [assignRoundModal, setAssignRoundModal] = useState(false);

  const [groupAvailable, setGroupAvailable] = useState(false);

  const renderAvailabilityLabel = (
    metaGroupMaxAttendees: number,
    currentNumAttendees: number
  ) => {
    if (metaGroupMaxAttendees > currentNumAttendees) {
      setGroupAvailable(true);
      return;
    }
    if ((metaGroupMaxAttendees = currentNumAttendees)) {
      setGroupAvailable(false);
      return;
    }
  };

  useEffect(() => {
    renderAvailabilityLabel(
      group.metaGroupMaxAttendees,
      group.attendees.length
    );
  }, [group]);

  // edit group
  const editGroupFields = [
    {
      fieldName: "name",
      label: "Group Name",
      inputType: "text",
      isRequired: false,
      isMutable: true,
    },
  ];

  const [editFormData, setEditFormData] = useState<FormDataType>(
    editGroupFields.reduce(
      (prev, field) => ({
        ...prev,
        // @ts-ignore
        [field.fieldName]: group[field.fieldName],
        id: group.id,
        ticketId: ticketId,
      }),
      {}
    )
  );

  return (
    <>
      <div className="groupInfoWrapper">
        <section>
          <h5>
            {group.name}{" "}
            <span className={groupAvailable ? "available" : "full"}>
              {groupAvailable ? "Available" : "Full"}
            </span>
          </h5>
          <article className="groupItem">
            <p className="computed-text">
              {groupAvailable ? (
                <>
                  Open Spots: &nbsp;
                  {group.metaGroupMaxAttendees - group.attendees.length}
                </>
              ) : (
                ""
              )}
            </p>
            <p>
              Round: &nbsp;
              {group.round
                ? `${group.round.name} | ${dayjs(`${group.round.startDate} ${group.round.startTime}`).format("M/D/YYYY h:mm A")}`
                : "None assigned"}
            </p>
            <p>
              Teammates: &nbsp;
              {group.attendees.length
                ? group.attendees
                    .map((a: Attendee) =>
                      a.user?.nameFirst || a.user?.nameLast
                        ? `${a.user.nameFirst} ${a.user.nameLast}`.trim()
                        : a.user?.email
                    )
                    .join(", ")
                : "None assigned"}
            </p>
          </article>
        </section>

        <section className="groupBtnGroup">
          <button
            className="primaryBtn"
            type="button"
            onClick={async () => {
              setManageTeammateModal(true);
            }}
          >
            Manage Teammates
          </button>
          <button
            className="primaryBtn"
            type="button"
            onClick={() => {
              setRenameGroupModal(true);
            }}
          >
            Rename
          </button>
          <button
            className="primaryBtn"
            type="button"
            onClick={() => setAssignRoundModal(true)}
          >
            Assign a Round
          </button>
        </section>
      </div>

      <section>
        <Modal
          isOpen={manageTeammateModal}
          onRequestClose={() => setManageTeammateModal(false)}
          className="standardModal"
          contentLabel="Manage Team Modal"
          ariaHideApp={false}
        >
          <CloseModalIcon
            onRequestClose={() => setManageTeammateModal(false)}
          />
          <ManageTeammateModal
            group={group}
            setManageTeammateModal={setManageTeammateModal}
          />
        </Modal>

        <Modal
          isOpen={renameGroupModal}
          onRequestClose={() => setRenameGroupModal(false)}
          className="standardModal"
          contentLabel="Rename Group Modal"
          ariaHideApp={false}
        >
          <CloseModalIcon onRequestClose={() => setRenameGroupModal(false)} />
          <div className="renameGroupModalContent">
            <h5 className="title">Rename Group</h5>
            <FormFields
              ticketId={ticketId}
              fields={editGroupFields}
              name={"Group"}
              form={{
                method: "edit",
                data: editFormData,
              }}
              setFormData={(_, data) => setEditFormData(data)}
              formMode={"edit"}
              isModal={true}
              onGetFunction={getGroupsState}
              onCreateFunction={createGroup}
              onUpdateFunction={renameGroupState}
              setFormModal={setRenameGroupModal}
            />
          </div>
        </Modal>

        <Modal
          isOpen={assignRoundModal}
          onRequestClose={() => setAssignRoundModal(false)}
          className="standardModal"
          contentLabel="Assign Round Modal"
          ariaHideApp={false}
        >
          <CloseModalIcon onRequestClose={() => setAssignRoundModal(false)} />
          <AssignRoundModal group={group} ticketGroups={ticketGroups} />
        </Modal>
      </section>
    </>
  );
};
