// This page is intended only for mobile devices.

/* eslint-disable jsx-a11y/media-has-caption */
import "../assets/scss/main.scss";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import QrScanner from "qr-scanner";
import { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { caretDownSvg, checkSvg } from "../assets/icons";
import { AutoPopulateInput } from "../components/AutoPopulateInput";
import { AttendeeModal } from "../components/check-in/AttendeeModal";
import { RoundModal } from "../components/check-in/RoundModal";
import { StatsModal } from "../components/check-in/StatsModal";
import { getCheckInAttendees, updateCheckInAttendee } from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import {
  CheckInAttendee,
  CheckInRoundOption,
  RawCheckInAttendee,
} from "../types";

function formatAttendees(rawAttendees: RawCheckInAttendee[]) {
  const getName = (
    firstName: string | null,
    lastName: string | null,
    email: string
  ) => {
    const result = [firstName, lastName].filter((x) => !!x);
    if (result.length !== 2) {
      result.push(email);
    }
    return result.join(" ");
  };

  const attendees = rawAttendees.map((att) => {
    return {
      id: att.id,
      scanCode: att.scanCode,
      name: getName(att.user.nameFirst, att.user.nameLast, att.user.email),
      roundId: att?.group?.round?.id ?? null,
      checkedIn: att.checkedIn ?? false,
    };
  });

  return attendees.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });
}

export const CheckInPage = () => {
  const { tournamentId } = useParams();
  const { setTournamentById, getRoundsState } = useTournamentStateContext();
  const [attendees, setAttendees] = useState<CheckInAttendee[]>([]);
  const [selectedAttendee, setSelectedAttendee] = useState<CheckInAttendee>();
  const [selectedRound, setSelectedRound] = useState<CheckInRoundOption>({
    id: 0,
    name: "All Rounds",
  });
  const [nameInput, setNameInput] = useState("");
  const [nameSuggestion, setNameSuggestion] = useState("");
  const [hasCamera, setHasCamera] = useState<boolean>();
  const [showScanner, setShowScanner] = useState(true);
  const [showAttendeeModal, setShowAtendeeModal] = useState(false);
  const [showRoundModal, setShowRoundModal] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [scanCode, setScanCode] = useState("");
  const scannerRef = useRef<QrScanner>();
  const videoRef = useRef<HTMLVideoElement>(null);

  const filteredAttendees = useCallback(() => {
    let results = attendees;
    if (selectedRound.id !== 0) {
      results = results.filter((x) => x.roundId === selectedRound.id);
    }
    if (scanCode) {
      return results.filter((x) => x.scanCode === scanCode);
    } else if (nameInput) {
      return results.filter((x) => x.name === nameInput);
    } else {
      return results;
    }
  }, [attendees, scanCode, nameInput, selectedRound]);

  const nameSuggestions = useCallback(() => {
    const uniqueNames = Array.from(
      new Set(filteredAttendees().map((att: CheckInAttendee) => att.name))
    );
    return uniqueNames.map((name: string) => ({
      suggestionInfo: name,
    }));
  }, [filteredAttendees]);

  function selectAttendee(attendeeId: number) {
    try {
      if (!attendeeId) {
        new Error("Invalid attendee id");
      }
      const foundAttendees = attendees.filter((x) => x.id === attendeeId);
      if (foundAttendees.length !== 1) {
        new Error("Did not find one (and only one) attendee");
      }
      setSelectedAttendee(foundAttendees[0]);
    } catch (error) {
      console.error(error);
      toast("There was an error selecting the attendee.", { type: "error" });
    }
  }

  async function getAttendees(tournId: number) {
    setAttendees(formatAttendees(await getCheckInAttendees(tournId)));
  }

  function clearScanAndName() {
    setScanCode("");
    setNameInput("");
    setNameSuggestion("");
  }

  function onScanSuccess(result: QrScanner.ScanResult) {
    setScanCode(result?.data);
    scannerRef.current?.stop();
  }

  async function updateCheckedIn(checkedIn: boolean) {
    try {
      setShowAtendeeModal(false);
      if (!selectedAttendee?.id) {
        new Error("Invalid attendee id");
      }
      await updateCheckInAttendee(selectedAttendee!.id, checkedIn);
      setSelectedAttendee(undefined);
      clearScanAndName();
      const tournId = parseInt(tournamentId ?? "");
      await getAttendees(tournId);
      if (showScanner) {
        await scannerRef.current?.start();
      }
      toast("Success!", { type: "success", autoClose: 3000 });
    } catch (error) {
      // request errors (i.e. axios errors) already print to the console and alert user of error (see httpClient.ts)
      if (!axios.isAxiosError(error)) {
        console.error(error);
        toast("There was an error updating data.", { type: "error" });
      }
    }
  }

  function cancelAttendeeModal() {
    setShowAtendeeModal(false);
    setSelectedAttendee(undefined);
  }

  function cancelRoundModal() {
    setShowRoundModal(false);
  }

  function closeStatsModal() {
    setShowStatsModal(false);
  }

  function selectRound(roundOption: CheckInRoundOption) {
    setShowRoundModal(false);
    setSelectedRound(roundOption);
  }

  useEffect(() => {
    let active = true;

    (async () => {
      try {
        const tournId = parseInt(tournamentId ?? "");
        // It'd be better to check "active" (to prevent race conditions) after each data fetching but before each state setting vs before an encapsulating function.
        // Checking "active" before calling setTournamentId would likely be ineffective due to the useEffect's speed of execution.
        await setTournamentById(tournId);
        if (active) {
          await getRoundsState(tournId);
        }
        if (active) {
          await getAttendees(tournId);
        }
      } catch (error) {
        if (!axios.isAxiosError(error)) {
          console.error(error);
          toast("There was an error fetching data.", { type: "error" });
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [tournamentId]);

  useEffect(() => {
    let active = true;

    (async () => {
      try {
        if (videoRef.current) {
          const deviceHasCamera = await QrScanner.hasCamera();
          if (!active) {
            return;
          }
          setHasCamera(deviceHasCamera);
          if (!deviceHasCamera) {
            toast(
              "Camera not detected so unable to scan QR codes, but you can still lookup attendees by name.",
              { type: "warning" }
            );
            setShowScanner(false);
            return;
          }
          scannerRef.current = new QrScanner(videoRef.current, onScanSuccess, {
            maxScansPerSecond: 5,
          });
          await scannerRef.current.start();
        }
      } catch (error) {
        console.error(error);
        toast("There was an error initializing scanner.", { type: "error" });
      }
    })();

    return () => {
      active = false;
      scannerRef.current?.destroy();
      scannerRef.current = undefined;
    };
  }, []);

  const renderModal = () => {
    if (showAttendeeModal) {
      return (
        <AttendeeModal
          attendee={selectedAttendee}
          updateCheckedIn={updateCheckedIn}
          cancel={cancelAttendeeModal}
        />
      );
    } else if (showRoundModal) {
      return <RoundModal selectRound={selectRound} cancel={cancelRoundModal} />;
    } else if (showStatsModal) {
      return <StatsModal close={closeStatsModal} />;
    }
  };

  return (
    <div className="checkinPageWrapper">
      <div className="topOptionsContainer">
        <button
          className="truncateText"
          onClick={() => {
            setShowAtendeeModal(false);
            setShowStatsModal(false);
            setShowRoundModal(true);
          }}
        >
          {selectedRound.name}
          <img src={caretDownSvg} alt="select round" className="icon" />
        </button>
        {/* <button
          onClick={() => {
            setShowAtendeeModal(false);
            setShowRoundModal(false);
            setShowStatsModal(true);
          }}
        >
          Stats
        </button> */}
      </div>

      <div className="btnGroup">
        <button
          disabled={hasCamera ? false : true}
          className={[
            showScanner ? "selected" : "",
            hasCamera ? "" : "disabled",
          ].join(" ")}
          onClick={async () => {
            setShowScanner(true);
            clearScanAndName();
            await scannerRef.current?.start();
          }}
        >
          Scan
        </button>
        <button
          className={!showScanner ? "selected" : ""}
          onClick={() => {
            setShowScanner(false);
            clearScanAndName();
            scannerRef.current?.stop();
          }}
        >
          Name
        </button>
      </div>

      <video hidden={!showScanner} ref={videoRef} className="video"></video>

      <div hidden={showScanner} className="nameContainer">
        <label htmlFor="nameSuggestions">Name</label>
        <AutoPopulateInput
          id="nameSuggestions"
          data={nameSuggestions()}
          searchInputValue={nameSuggestion}
          setSearchInputValue={setNameSuggestion}
          inputValue={nameInput}
          setInputValue={setNameInput}
          optionKeyAttribute="suggestionInfo"
          optionValueAttribute="suggestionInfo"
          returnNonMatchingInput={false}
        />
      </div>

      {(scanCode || nameInput) && (
        <div className="clearResultsContainer">
          <button
            onClick={async () => {
              clearScanAndName();
              if (showScanner) {
                await scannerRef.current?.start();
              }
            }}
          >
            Clear Results
          </button>
        </div>
      )}

      <table className="attendeeTable">
        <thead>
          <tr>
            <th>Attendee</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!filteredAttendees()?.length ? (
            <tr>
              <td>No results</td>
            </tr>
          ) : (
            filteredAttendees().map((att: CheckInAttendee) => {
              return (
                <tr
                  key={att.id}
                  onClick={() => {
                    selectAttendee(att.id);
                    setShowRoundModal(false);
                    setShowStatsModal(false);
                    setShowAtendeeModal(true);
                  }}
                >
                  <td className="truncateText">{att.name}</td>

                  <td>
                    {att.checkedIn && (
                      <img
                        src={checkSvg}
                        className="icon"
                        alt="checked in status"
                      />
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      ></ToastContainer>

      <Modal
        isOpen={showAttendeeModal || showRoundModal || showStatsModal}
        className="mobileOnlyModal"
        ariaHideApp={false}
        overlayClassName="mobileOnlyOverlay"
      >
        {renderModal()}
      </Modal>
    </div>
  );
};
