import "../assets/scss/main.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import FormFields from "../components/FormFields";
import euserConfig from "../data/euserConfig"; // Form config
import { FormDataType } from "../types";
import useEuser from "../utils/useEuser"; // access to provider via "use" hook

export const AccountSignUp = () => {
  const { signUpEuser } = useEuser();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormDataType>(
    euserConfig.signUpFields.reduce(
      (prev, field) => ({
        ...prev,
        [field.fieldName]: "",
      }),
      {}
    )
  );

  const afterPostFunction = () => {
    return new Promise<void>((resolve, reject) => {
      try {
        alert("Successfully registered and logged in!");
        console.log(
          "redirecting to account/reservations via AccountSignUp.tsx"
        );
        navigate("/account/reservations");
        resolve(); // Resolve the promise when done
      } catch (error) {
        reject(error); // Reject the promise if there's an error
      }
    });
  };

  return (
    <div className="editPageWrapper">
      <h3 className="list_title">New User Sign Up</h3>

      <FormFields
        fields={euserConfig.signUpFields}
        form={{ method: "post", data: formData }}
        setFormData={(_, data) => setFormData(data)}
        formMode={"post"}
        name={euserConfig.name}
        onPostFunction={signUpEuser}
        afterPostFunction={afterPostFunction} // Not used here, needs cleanup
      />
    </div>
  );
};

export default AccountSignUp;
