import { createWidget, updateWidget } from "./httpClient";

const widgetConfig = {
  name: "widget",
  onCreate: createWidget,
  onUpdate: updateWidget,
  createAndEditFields: [
    {
      fieldName: "name",
      label: "Name",
      inputType: "text",
      isRequired: true,
      isMutable: true,
      autoComplete: "off",
    },
    // {
    //   fieldName: "content",
    //   label: "Content",
    //   inputType: "wysiwyg",
    //   isRequired: false,
    //   isMutable: true,
    // },
    {
      fieldName: "widgetTypeId",
      label: "Widget Type",
      inputType: "dropdown",
      isRequired: true,
      isMutable: true,
    },
  ],
};

export default widgetConfig;
