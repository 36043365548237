import React, { useContext, useState } from "react";

import { Group, Round, Sponsor, TicketCategory, Tournament } from "../../types";
import {
  getAllTournaments,
  getSponsorsByTicketCategory,
  getTournamentById,
  getTournamentRounds,
  getTournamentTicketCategories,
} from "../httpClient";

export interface TournamentStateContextInterface {
  tournamentIdState: number;
  setTournamentIdState: React.Dispatch<React.SetStateAction<number>>;
  tournamentState: Tournament;
  setTournamentById: (id: number) => Promise<any>;
  getMostRecentLiveTournament: () => Promise<Tournament>;
  roundsState: Round[];
  getRoundsState: (tournamentId: number, groups?: Group[]) => Promise<any>;
  setRoundsState: (value: React.SetStateAction<Round[]>) => void;
  //Ticket Categories
  ticketCategoriesState: TicketCategory[];
  getTicketCategoriesState: (
    tournamentId: number,
    isSponsor?: boolean
  ) => Promise<any>;
  setTicketCategoriesState: React.Dispatch<
    React.SetStateAction<TicketCategory[]>
  >;
  // Sponsors
  sponsorsState: Sponsor[];
  getSponsorsState: (ticketCategoryId: number) => Promise<void>;
}

export const TournamentStateContext =
  React.createContext<TournamentStateContextInterface | null>(null);

export const TournamentStateProvider = ({ children }: any) => {
  const [tournamentIdState, setTournamentIdState] = useState<number>(0);
  const [tournamentState, setTournamentState] = useState<Tournament>({
    id: 0,
    name: "",
    description: "",
    liveFlag: false,
    dateStart: "",
    dateEnd: "",
    locationTitle: "",
    locationAddress: "",
    bannerPhotoFileName: "",
    bannerPhotoContentType: "",
    bannerPhotoFileSize: 0,
    bannerPhotoUpdatedAt: null,
    slug: "",
    createdAt: "",
    updatedAt: "",
    rounds: [],
    logos: [],
  });
  const [roundsState, setRoundsState] = useState<Round[]>([]);
  const [ticketCategoriesState, setTicketCategoriesState] = useState<
    TicketCategory[]
  >([]);
  const [sponsorsState, setSponsorsState] = useState<Sponsor[]>([]);

  // Might need to use useCallback for each get below

  async function setTournamentById(id: number) {
    const result = await getTournamentById(id);
    if (!result) {
      throw new Error("couldn't find tournament");
    }
    setTournamentState(result);
    setTournamentIdState(result.id);
  }

  async function getMostRecentLiveTournament() {
    const tournaments: Tournament[] = await getAllTournaments();
    if (!tournaments || tournaments.length === 0) {
      throw new Error("couldn't find most recent live tournament");
    }
    // Sort live tournaments by dateStart in descending order
    const sortedTournaments = tournaments
      .filter((t) => t.liveFlag)
      .sort(
        (a: Tournament, b: Tournament) =>
          new Date(b.dateStart).getTime() - new Date(a.dateStart).getTime()
      );
    return sortedTournaments[0];
  }

  // rounds associated with the selected tournament
  const getRoundsState = async (
    tournamentId: number,
    ticketGroups?: Group[]
  ) => {
    const tournamentRounds = await getTournamentRounds(tournamentId);
    tournamentRounds.forEach((r: Round) => (r.numUserGroupAssigned = 0));
    tournamentRounds.forEach((r: Round, i: number) => {
      ticketGroups?.forEach((g: Group) => {
        if (r.id === g?.roundId) {
          tournamentRounds[i].numUserGroupAssigned += 1;
        }
      });
    });
    setRoundsState(tournamentRounds);
  };

  const getTicketCategoriesState = async (
    tournamentId: number,
    isSponsor?: boolean
  ) => {
    try {
      const ticketCategories = await getTournamentTicketCategories(
        tournamentId,
        isSponsor
      );
      setTicketCategoriesState(ticketCategories);
    } catch (error) {
      console.error("Error fetching ticket categories:", error);
      // Handle error appropriately
    }
  };

  const getSponsorsState = async (ticketCategoryId: number) => {
    const sponsorsData = await getSponsorsByTicketCategory(ticketCategoryId);
    setSponsorsState(sponsorsData);
  };

  return (
    <TournamentStateContext.Provider
      value={{
        tournamentIdState,
        setTournamentIdState,
        tournamentState,
        setTournamentById,
        getMostRecentLiveTournament,
        roundsState,
        getRoundsState,
        setRoundsState,
        ticketCategoriesState,
        getTicketCategoriesState,
        setTicketCategoriesState,
        sponsorsState,
        getSponsorsState,
      }}
    >
      {children}
    </TournamentStateContext.Provider>
  );
};

export const useTournamentStateContext = () => {
  const context = useContext(TournamentStateContext);
  if (!context) {
    throw new Error(
      "TournamentStateContext must be used inside TournamentStateProvider"
    );
  }
  return context;
};
