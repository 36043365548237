import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  addTagSvg,
  deleteTagSvg,
  editSvg,
  settingSvg,
  trashSvg,
} from "../assets/icons";
import { PaymentModal } from "../components/PaymentModal";
import {
  deleteTicket,
  getAllSponsors,
  getTicketsByUser,
} from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { Sponsor, Ticket } from "../types";
import useEuser from "../utils/useEuser"; // access to provider via "use" hook

function AccountReservations() {
  const navigate = useNavigate();
  const { getMostRecentLiveTournament } = useTournamentStateContext();
  const { euser } = useEuser();
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [sponsorData, setSponsorData] = useState<Sponsor[]>([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isSponsorTicketCategory, setIsSponsorTicketCategory] = useState<
    boolean | null
  >(null);
  const [tournamentRegisterUrl, setTournamentRegisterUrl] = useState("/");
  const [upcomingTournamentId, setUpcomingTournamentId] = useState<number>();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [filterTags, setFilterTags] = useState([
    {
      name: "Upcoming",
      selected: true,
    },
    {
      name: "Past",
      selected: false,
    },
  ]);

  // ToDo: assumes that there is no more than one upcoming tournament (doesn't use any start dates)
  const getTicketsResult = async (upcomingTournId: number | undefined) => {
    if (euser) {
      let result = await getTicketsByUser(parseInt(euser.id));

      setTickets(result);

      // BASIC FILTERS
      filterTags
        .filter((f) => f.selected)
        .forEach((f) => {
          result = result.filter((t: Ticket) => {
            if (f.name === "Upcoming") {
              return t.tournamentId === upcomingTournId;
            } else {
              return t.tournamentId !== upcomingTournId;
            }
          });
        });
      setTickets(result);
      // END BASIC FILTERS
    }
  };

  const getSponsorData = async () => {
    const sponsors = await getAllSponsors();
    setSponsorData(sponsors);
  };

  const getTournamentRegisterUrl = (tournamentId: number | undefined) => {
    const url = tournamentId ? `/tournaments/${tournamentId}/register` : "/";
    setTournamentRegisterUrl(url);
  };

  const userCanAdministerSponsor = (sponsorId: number): boolean => {
    const exists = sponsorData.some((s) => s.id === sponsorId);
    return exists;
  };

  const numberToCurrency = (num: number, digits: number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: digits,
    });
    return formatter.format(num);
  };

  useEffect(() => {
    let active = true;

    (async () => {
      try {
        setIsDataLoading(true);
        const tournament = await getMostRecentLiveTournament();
        // It'd be better to check "active" (to prevent race conditions) after each data fetching but before each state setting vs before an encapsulating function.
        if (active) {
          await getTicketsResult(tournament?.id);
        }
        if (active) {
          await getSponsorData();
        }
        if (active) {
          getTournamentRegisterUrl(tournament?.id);
          setUpcomingTournamentId(tournament?.id);
        }
      } catch (error) {
        // request errors (i.e. axios errors) already print to the console and alert user of error (see httpClient.ts)
        if (!axios.isAxiosError(error)) {
          console.error("Error fetching data:", error);
          alert("There was an error fetching data.");
        }
      } finally {
        if (active) {
          setIsDataLoading(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [euser, filterTags]);

  return (
    <div className="editPageWrapper">
      <h3 className="list_title">Tickets and Reservations</h3>
      <p>
        Here you can manage the tickets you&apos;ve reserved. These could be
        tickets you plan to attend with yourself or tickets you plan to invite
        other attendees to use. In order to invite other attendees and manage
        groups click the group option. If you want to view tickets you are using
        as an attendee or view your scannable QR codes, head to the
        &quot;Entrance Passes&quot; page. Need help managing your tickets? Email{" "}
        <a href="mailto:golfforhope@hcahealthcare.com?subject=Need%20help%20managing%20my%20tickets">
          golfforhope@hcahealthcare.com
        </a>
        .
      </p>

      {/* BASIC FILTERS */}
      <article className="filterWrapper">
        {filterTags.map((f, i) => {
          return (
            <button
              key={`ticket-filterTag-${i}`}
              className={`filterTag ${f.selected && "tagSelected"}`}
              onClick={() => {
                filterTags[i].selected = !filterTags[i].selected;
                // deselect other tags if one is selected
                if (filterTags[i].selected === true) {
                  filterTags.forEach((x) => {
                    if (x.name !== filterTags[i].name) {
                      x.selected = false;
                    }
                  });
                }
                setFilterTags([...filterTags]);
              }}
            >
              <span>{f.name}</span>
              {f.selected ? (
                <img src={deleteTagSvg} alt="unselected filter tag" />
              ) : (
                <img src={addTagSvg} alt="selected filter tag" />
              )}
            </button>
          );
        })}
      </article>
      {/* END BASIC FILTERS */}

      <table className="ticketsTable">
        <thead>
          <tr>
            <th>Event</th>
            <th>Ticket Category</th>
            <th>Sponsor</th>
            <th>Groups</th>
            <th>Price</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isDataLoading && (
            <tr>
              <td>Loading...</td>
            </tr>
          )}

          {!isDataLoading && !tickets.length && (
            <tr>
              {!filterTags.find(
                (tag) => tag.name === "Past" && tag.selected === true
              ) ? (
                <td>
                  <a
                    href={tournamentRegisterUrl}
                    title={"register for an event"}
                  >
                    Register
                  </a>{" "}
                  to get tickets to an event!
                </td>
              ) : (
                <td>No tickets found.</td>
              )}
            </tr>
          )}

          {!isDataLoading && tickets.length
            ? tickets.map((t: Ticket, i: number) => {
                return (
                  <tr key={i}>
                    <td>{t.tournament?.name}</td>

                    <td>{t.ticketCategory?.name}</td>

                    {t.sponsor ? (
                      <>
                        {userCanAdministerSponsor(t.sponsorId) ? (
                          <td className="tableLink">
                            {t.sponsor && (
                              <span
                                onClick={() => {
                                  navigate(
                                    `/account/sponsor/${t.sponsorId}/edit`
                                  );
                                }}
                              >
                                <img
                                  src={editSvg}
                                  alt="manage sponsor"
                                  className="small"
                                />
                                &nbsp;
                                {t.sponsor?.name}
                              </span>
                            )}
                          </td>
                        ) : (
                          <td>{t.sponsor?.name}</td>
                        )}
                      </>
                    ) : (
                      <td></td>
                    )}

                    {!isDataLoading &&
                    t.tournamentId === upcomingTournamentId ? (
                      <td className="tableLink">
                        <span
                          onClick={() => {
                            navigate(`/account/tickets/${t.id}/groups`);
                          }}
                        >
                          <img
                            src={settingSvg}
                            className="iconAction"
                            alt="manage ticket groups"
                          />
                          &nbsp;
                          {t.groups.length}&nbsp;Group
                          {t.groups.length > 1 ? "s" : null}
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span>
                          {t.groups.length}&nbsp;Group
                          {t.groups.length > 1 ? "s" : null}
                        </span>
                      </td>
                    )}

                    <td>{numberToCurrency(t.ticketCategory.price, 0)}</td>

                    <td>
                      {t.paid ? (
                        <div>
                          Paid:{" "}
                          <span style={{ whiteSpace: "nowrap" }}>
                            {t.datePaid}
                          </span>
                        </div>
                      ) : (
                        t.tournamentId === upcomingTournamentId && (
                          // show button
                          <button
                            className="primaryBtn"
                            type="button"
                            onClick={() => {
                              setIsSponsorTicketCategory(!!t.sponsorId);
                              setShowPaymentModal(true);
                            }}
                          >
                            Begin Payment
                          </button>
                        )
                      )}
                    </td>

                    {/* admin users can also cancel vs delete a ticket; see TicketsPage.tsx */}
                    {!t.paid && t.tournamentId === upcomingTournamentId ? (
                      <td
                        className="iconAction"
                        onClick={async () => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this ticket?"
                            )
                          ) {
                            await deleteTicket(t.id);
                            console.log("Ticket deleted successfully.");
                            const tournament =
                              await getMostRecentLiveTournament();
                            getTicketsResult(tournament?.id);
                          }
                        }}
                      >
                        <img src={trashSvg} alt="delete ticket" />
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                );
              })
            : ""}
        </tbody>
      </table>

      <section>
        <PaymentModal
          isOpen={showPaymentModal}
          onRequestClose={() => {
            setIsSponsorTicketCategory(null);
            setShowPaymentModal(false);
          }}
          isSponsorTicketCategory={isSponsorTicketCategory}
        />
      </section>
    </div>
  );
}

export default AccountReservations;
