import "../assets/scss/main.scss";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  activeSvg,
  addTagSvg,
  deleteTagSvg,
  dollarSvg,
  editSvg,
  inactiveSvg,
  settingSvg,
  trashSvg,
} from "../assets/icons";
import {
  deleteTicket,
  getTickets,
  getTournamentTicketCategories,
  updateTicket,
} from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { Ticket, TicketCategory } from "../types";

function TicketsPage() {
  const navigate = useNavigate();

  const { tournamentId, attendeeOrSponsor } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  const [filterTags, setFilterTags] = useState([
    {
      name: "Paid",
      selected: false,
    },
    {
      name: "Unpaid",
      selected: false,
    },
    {
      name: "Cancelled",
      selected: false,
    },
    {
      name: "Not Cancelled",
      selected: false,
    },
  ]);

  // ticketCategories decides how many tickets tables we list
  const [tabs, setTabs] = useState<{ name: string; selected: boolean }[]>([]);

  const [tickets, setTickets] = useState<Ticket[]>([]);

  const isSponsor = attendeeOrSponsor === "sponsor" ? true : false;

  const getTabs = async () => {
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
      const ticketCategories = await getTournamentTicketCategories(
        parseInt(tournamentId),
        isSponsor
      );
      setTabs(
        ticketCategories.map((t: TicketCategory, i: number) => {
          return {
            name: t.name,
            selected: i === 0 ? true : false,
          };
        })
      );
    }
  };

  const getTicketsResult = async () => {
    if (tournamentId) {
      let result = await getTickets(parseInt(tournamentId), isSponsor);
      const selectedTabObj = tabs.find(
        (t: { name: string; selected: boolean }) => t.selected
      );
      result = result.filter(
        (t: Ticket) => t.ticketCategory?.name === selectedTabObj?.name
      );
      setTickets(result);
      filterTags
        .filter((f) => f.selected)
        .forEach((f) => {
          result = result.filter((t: Ticket) => {
            switch (f.name) {
              case "Paid":
                return t.paid;
              case "Unpaid":
                return !t.paid;
              case "Cancelled":
                return t.isCancelled;
              case "Not Cancelled":
                return !t.isCancelled;
              default:
            }
          });
        });
      setTickets(result);
    }
  };

  useEffect(() => {
    getTabs();
  }, [tournamentId, attendeeOrSponsor]);

  useEffect(() => {
    getTicketsResult();
  }, [tournamentId, attendeeOrSponsor, tabs, filterTags]);

  return (
    <div className="ticketsPageWrapper">
      <h5 className="ticketsPageTitle">
        <span>{attendeeOrSponsor}</span> Tickets{" "}
      </h5>

      <button
        className="newTicketBtn"
        type="button"
        onClick={() => navigate("new")}
      >
        New Ticket
      </button>

      <article className="filterWrapper">
        {filterTags.map((f, i) => {
          return (
            <button
              key={`ticket-filterTag-${i}`}
              className={`filterTag ${f.selected && "tagSelected"}`}
              onClick={() => {
                filterTags[i].selected = !filterTags[i].selected;
                setFilterTags([...filterTags]);
              }}
            >
              <span>{f.name}</span>
              {f.selected ? (
                <img src={deleteTagSvg} alt="unselected filter tag" />
              ) : (
                <img src={addTagSvg} alt="selected filter tag" />
              )}
            </button>
          );
        })}
      </article>

      {
        <section className="ticketTabWrapper">
          {tabs.map((t: { name: string; selected: boolean }, i: number) => (
            <button
              key={`tickets-tab-${i}`}
              className={`userTabBtn ${t.selected && "selected"}`}
              onClick={() => {
                tabs.forEach((t) => (t.selected = false));
                tabs[i].selected = true;
                setTabs([...tabs]);
              }}
            >
              {t.name}
            </button>
          ))}
        </section>
      }

      <table className="ticketsTable">
        <thead>
          <tr>
            <th>Ticket Holder</th>
            {attendeeOrSponsor === "sponsor" && <th>Sponsor</th>}
            {attendeeOrSponsor === "sponsor" && <th>Contact</th>}
            <th>Groups</th>
            <th>Payment</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!tickets.length ? (
            <tr>
              <td>No results</td>
            </tr>
          ) : (
            tickets.map((t: Ticket, i: number) => {
              return (
                <tr key={i}>
                  <td>
                    {t.user?.nameFirst} {t.user?.nameLast}
                  </td>

                  {attendeeOrSponsor === "sponsor" && (
                    <td className="tableLink">
                      {t.sponsor && (
                        <span
                          className="iconAction"
                          onClick={() => {
                            navigate(`/account/sponsor/${t.sponsorId}/edit`);
                          }}
                        >
                          <img
                            src={editSvg}
                            alt="manage sponsor"
                            className="small"
                          />
                          &nbsp;
                          {t.sponsor?.name}
                        </span>
                      )}
                    </td>
                  )}

                  {attendeeOrSponsor === "sponsor" && (
                    <td>
                      {t.sponsor?.contactNameFirst} {t.sponsor?.contactNameLast}
                    </td>
                  )}

                  <td
                    className="icon tableLink"
                    onClick={() => {
                      navigate(`${t.id}/groups`);
                    }}
                  >
                    <img src={settingSvg} alt="manage ticket groups" />
                    &nbsp;
                    {t.groups.length} Group{t.groups.length > 1 ? "s" : null}
                  </td>

                  <td
                    className="icon tableLink"
                    onClick={async () => {
                      if (
                        window.confirm(
                          `Are you sure to mark this ticket ${
                            t.paid ? "unpaid" : "paid"
                          }?`
                        )
                      ) {
                        t.paid = !t.paid;
                        // datePaid must be null for api to set it when applicable
                        t.datePaid = null;
                        await updateTicket(t);
                        getTicketsResult();
                      }
                    }}
                  >
                    <img src={dollarSvg} alt="manage paid/unpaid status" />
                    &nbsp;
                    {t.paid ? "Paid" : "Unpaid"}
                  </td>

                  <td
                    className="icon tableLink"
                    onClick={async () => {
                      if (
                        window.confirm(
                          `Are you sure to mark this ticket ${
                            t.isCancelled ? "active" : "cancelled"
                          }?`
                        )
                      ) {
                        t.isCancelled = !t.isCancelled;
                        await updateTicket(t);
                        getTicketsResult();
                      }
                    }}
                  >
                    <img
                      src={t.isCancelled ? inactiveSvg : activeSvg}
                      alt="Cancel Ticket"
                    />
                    &nbsp;
                    {t.isCancelled ? "Cancelled" : "Active"}
                  </td>

                  <td
                    className="iconAction"
                    onClick={async () => {
                      if (
                        window.confirm(
                          "Are you sure you want to delete this ticket?"
                        )
                      ) {
                        await deleteTicket(t.id);
                        console.log("Ticket deleted successfully.");
                        getTicketsResult();
                      }
                    }}
                  >
                    <img src={trashSvg} alt="delete ticket" />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TicketsPage;
