import "../../assets/scss/main.scss";

import { useTournamentStateContext } from "../../data/providers/TournamentStateProvider";
import { CheckInRoundOption, Round } from "../../types";

interface RoundModalProps {
  selectRound: (roundOption: CheckInRoundOption) => void;
  cancel: () => void;
}

export const RoundModal = ({ selectRound, cancel }: RoundModalProps) => {
  const { roundsState } = useTournamentStateContext();

  return (
    <div className="checkInRoundModalContent">
      <div className="chipContainer">
        <button
          className="chip"
          onClick={() =>
            selectRound({
              id: 0,
              name: "All Rounds",
            })
          }
        >
          All Rounds
        </button>
        {roundsState.map((r: Round) => {
          return (
            <button
              key={r.id}
              className="chip"
              onClick={() =>
                selectRound({
                  id: r.id,
                  name: r.name,
                })
              }
            >
              {r.name}
            </button>
          );
        })}
      </div>
      <div className="btnContainer">
        <button
          className="greyBtn"
          onClick={() => {
            cancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
