import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import addTeammateConfig from "../data/addTeammateConfig";
import { deleteAttendee, getAllUsers } from "../data/httpClient";
import {
  GroupContext,
  GroupContextInterface,
} from "../data/providers/GroupProvider";
import { Attendee, FormDataType, Group, User } from "../types";
import FormFields from "./FormFields";

interface ManageTeammateModalProps {
  group: Group;
  setManageTeammateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ManageTeammateModal = ({
  group,
  setManageTeammateModal,
}: ManageTeammateModalProps) => {
  const { ticketId } = useParams();

  const { getGroupsState } = useContext(GroupContext) as GroupContextInterface;

  const [formData, setFormData] = useState<FormDataType>(
    addTeammateConfig.createAndEditFields.reduce(
      (prev, field) => ({
        ...prev,
        [field.fieldName]: "",
        groupId: group.id,
        email: "",
      }),
      {}
    )
  );

  const [userData, setUserData] = useState<User[]>([]);

  const getData = async () => {
    const userSuggestions = await getAllUsers();
    userSuggestions.forEach((u: User) => {
      u.suggestionInfo = u.email;
    });
    setUserData(userSuggestions);
  };

  useEffect(() => {
    getData();
  }, []);

  // Key is fieldName from form config
  const fieldOptionData = {
    email: userData,
  };

  return (
    <div className="manageTeammateModalContent">
      <h5>{group.name}</h5>

      {group.metaGroupMaxAttendees - group.attendees.length > 0 ? (
        <>
          <section className="addNewTeammateFormWrapper">
            <FormFields
              ticketId={ticketId}
              fields={addTeammateConfig.createAndEditFields}
              name={addTeammateConfig.name}
              form={{ method: "create", data: formData }}
              setFormData={(_, data) => setFormData(data)}
              formMode={"create"}
              isModal={true}
              onGetFunction={getGroupsState}
              onCreateFunction={addTeammateConfig.onCreate}
              onUpdateFunction={addTeammateConfig.onUpdate}
              setFormModal={setManageTeammateModal}
              fieldOptionData={fieldOptionData}
            />
          </section>
        </>
      ) : (
        <>
          <p>
            Your group is full, but if you remove a teammate, you will have an
            option to replace them.
          </p>
        </>
      )}

      <section className="teammateWrapper">
        <h5>Teammates</h5>
        {group.attendees.length
          ? group.attendees.map((a: Attendee) => (
              <article
                className="eachTeammateWrapper"
                key={`group-attendee-${a.id}`}
              >
                <p>
                  {a.user?.nameFirst || a.user?.nameLast
                    ? `${a.user.nameFirst} ${a.user.nameLast}`.trim()
                    : a.user?.email}
                </p>
                <button
                  onClick={async () => {
                    await deleteAttendee(a.id);
                    alert(`Teammate is successfully deleted.`);
                    if (ticketId) {
                      await getGroupsState(parseInt(ticketId));
                    }
                  }}
                >
                  Remove Teammate
                </button>
              </article>
            ))
          : "No teammate currently"}
      </section>
    </div>
  );
};
