import "../assets/scss/main.scss";

import { useState } from "react";

import FormFields from "../components/FormFields";
import tournamentConfig from "../data/tournamentConfig";
import { FormDataType } from "../types";

export const CreateTournamentPage = () => {
  const [formData, setFormData] = useState<FormDataType>(
    tournamentConfig.createAndEditFields.reduce(
      (prev, field) => ({
        ...prev,
        [field.fieldName]: "",
      }),
      {}
    )
  );

  return (
    <div className="createTournamentWrapper">
      <h5>Tournament Information</h5>

      <FormFields
        fields={tournamentConfig.createAndEditFields}
        form={{ method: "create", data: formData }}
        setFormData={(_, data) => setFormData(data)}
        formMode={"create"}
        name={tournamentConfig.name}
        onCreateFunction={tournamentConfig.onCreate}
        onUpdateFunction={tournamentConfig.onUpdate}
      />
    </div>
  );
};
