import { createRound, updateRound } from "./httpClient";
import { useTournamentStateContext } from "./providers/TournamentStateProvider";

export const GetRoundConfig = () => {
  const { getRoundsState } = useTournamentStateContext();

  const roundConfig = {
    name: "round",
    onGet: getRoundsState,
    onCreate: createRound,
    onUpdate: updateRound,
    createAndEditFields: [
      {
        fieldName: "name",
        label: "Round Name",
        inputType: "text",
        isRequired: true,
        isMutable: true,
        autoComplete: "off",
      },
      {
        fieldName: "location",
        label: "Round Location",
        inputType: "text",
        isRequired: true,
        isMutable: true,
      },
      {
        fieldName: "startDate",
        label: "Round Start Date",
        inputType: "date",
        isRequired: true,
        isMutable: true,
      },
      {
        fieldName: "startTime",
        label: "Round Start Time",
        inputType: "time",
        isRequired: true,
        isMutable: true,
      },
      {
        fieldName: "endDate",
        label: "Round End Date",
        inputType: "date",
        isRequired: true,
        isMutable: true,
      },
      {
        fieldName: "endTime",
        label: "Round End Time",
        inputType: "time",
        isRequired: true,
        isMutable: true,
      },
      {
        fieldName: "endDateVisible",
        label: "End Date Visible?",
        inputType: "toggle",
        isRequired: false,
        isMutable: true,
      },
      {
        fieldName: "description",
        label: "Round Description",
        inputType: "textarea",
        isRequired: true,
        isMutable: true,
      },
      {
        title: "Attendees",
        fieldName: "maxAttendees",
        label: "Max Attendees",
        inputType: "number",
        isRequired: true,
        isMutable: true,
      },
      {
        fieldName: "liveFlag",
        label: "Live",
        inputType: "toggle",
        isRequired: false,
        isMutable: true,
      },
      {
        fieldName: "",
        label: "Allowed Ticket Types",
        inputType: "none",
        isRequired: false,
      },
    ],
  };
  return roundConfig;
};
