import React, { useCallback, useState } from "react";

// @ts-ignore
import { Widget, WidgetPhoto, WidgetType } from "../../types";
import {
  getWidgetPhotos as getWidgetPhotosFromApi,
  getWidgets as getWidgetsFromApi,
  getWidgetTypes as getWidgetTypesFromApi,
} from "../httpClient";

export interface WidgetContextInterface {
  widgetTypes: WidgetType[];
  getWidgetTypes: () => Promise<void>;
  widgets: Widget[];
  setWidgets: React.Dispatch<React.SetStateAction<Widget[]>>;
  getWidgets: (tournamentId: number) => Promise<void>;
  getWidgetPhotos: (widgetId: number) => Promise<void>;
  widgetPhotos: WidgetPhoto[];
}

export const WidgetContext = React.createContext<WidgetContextInterface | null>(
  null
);

export const WidgetProvider = ({ children }: any) => {
  const [widgetTypes, setWidgetTypes] = useState<WidgetType[]>([]);
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const [widgetPhotos, setWidgetPhotos] = useState<WidgetPhoto[]>([]);

  const getWidgetTypes = useCallback(async () => {
    const widgetTypes = await getWidgetTypesFromApi();
    setWidgetTypes(widgetTypes);
  }, []);

  const getWidgets = useCallback(async (tournamentId: number) => {
    const widgets = await getWidgetsFromApi(tournamentId);
    setWidgets(widgets);
  }, []);

  const getWidgetPhotos = useCallback(async (widgetId: number) => {
    const widgetPhotosData = await getWidgetPhotosFromApi(widgetId);
    setWidgetPhotos(widgetPhotosData);
  }, []);

  return (
    <WidgetContext.Provider
      value={{
        widgetTypes,
        getWidgetTypes,
        widgets,
        setWidgets,
        getWidgets,
        getWidgetPhotos,
        widgetPhotos,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};
