import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";

function RedirectToRecentTournament() {
  const navigate = useNavigate();
  const { getMostRecentLiveTournament } = useTournamentStateContext();

  useEffect(() => {
    (async () => {
      try {
        const tournament = await getMostRecentLiveTournament();
        navigate(`/tournaments/${tournament.id}/${tournament.slug}`);
      } catch (error) {
        console.error("Error fetching tournament:", error);
        alert("There was an error fetching data.");
      }
    })();
  }, [navigate]);

  return <div>Loading...</div>;
}

export default RedirectToRecentTournament;
