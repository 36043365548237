import "../assets/scss/main.scss";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FormFields from "../components/FormFields";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import ticketCategoryConfig from "../data/ticketCategoryConfig";
import { FormDataType } from "../types";

export const CreateTicketCategoryPage = () => {
  const { tournamentId, attendeeOrSponsor } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  useEffect(() => {
    if (tournamentId) {
      setTournamentIdState(parseInt(tournamentId));
    }
  }, [tournamentId]);

  const [formData, setFormData] = useState<FormDataType>(
    ticketCategoryConfig.createAndEditFields.reduce(
      (prev, field) => ({
        ...prev,
        [field.fieldName]: "",
        sponsorFlag: attendeeOrSponsor === "sponsor" ? true : false,
        tournamentId: tournamentId && parseInt(tournamentId),
      }),
      {}
    )
  );

  return (
    <div className="createTicketCategoryWrapper">
      <h5 className="createTicketCategoryTitle">
        New <span>{attendeeOrSponsor}</span> Ticket Category
      </h5>

      <FormFields
        fields={ticketCategoryConfig.createAndEditFields}
        form={{ method: "create", data: formData }}
        setFormData={(_, data) => setFormData(data)}
        formMode={"create"}
        name={ticketCategoryConfig.name}
        onCreateFunction={ticketCategoryConfig.onCreate}
        onUpdateFunction={ticketCategoryConfig.onUpdate}
      />
    </div>
  );
};
