import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { HCALogoImg } from "../assets/icons";
import DateRangeFormatter from "../components/DateRangeFormatter";
import { Footer } from "../components/Footer";
import EventSideBar from "../components/tournament-preview/EventSideBar";
import WidgetContent from "../components/tournament-preview/WidgetContent";
import { getSponsorsByTicketCategory } from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import {
  WidgetContext,
  WidgetContextInterface,
} from "../data/providers/WidgetProvider";
import ManageButtons from "../navs/ManageButtons";
import { Sponsor } from "../types";

export const TournamentPreviewPage = () => {
  const { tournamentId } = useParams();
  const navigate = useNavigate();

  const {
    setTournamentById,
    getRoundsState,
    getTicketCategoriesState,
    tournamentState,
    roundsState,
    ticketCategoriesState,
  } = useTournamentStateContext();

  const { getWidgets, widgets } = useContext(
    WidgetContext
  ) as WidgetContextInterface;

  const [allSponsors, setAllSponsors] = useState<Sponsor[]>([]);

  useEffect(() => {
    let active = true;

    (async () => {
      try {
        const id = parseInt(tournamentId ?? "");
        // It'd be better to check "active" (to prevent race conditions) after each data fetching but before each state setting vs before an encapsulating function.
        // Checking "active" before calling setTournamentId would likely be ineffective due to the useEffect's speed of execution.
        await setTournamentById(id);
        if (active) {
          await getRoundsState(id);
        }
        if (active) {
          await getTicketCategoriesState(id);
        }
        if (active) {
          await getWidgets(id);
        }
      } catch (error: any) {
        if (error?.message === "couldn't find tournament") {
          console.log(error.message + " so redirecting to home page");
          navigate("/"); // the home page tries to pull up the most recent live tournament
          // request errors (i.e. axios errors) already print to the console and alert user of error (see httpClient.ts)
        } else if (!axios.isAxiosError(error)) {
          console.error("Error fetching data:", error);
          alert("There was an error fetching data.");
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [tournamentId]);

  useEffect(() => {
    let active = true;

    (async () => {
      if (ticketCategoriesState?.length) {
        // get sponsors
        let accumulatedSponsors: Sponsor[] = [];
        for (const categoryId of ticketCategoriesState.map((e) => e.id)) {
          const sponsorsData = await getSponsorsByTicketCategory(categoryId);
          accumulatedSponsors = [...accumulatedSponsors, ...sponsorsData];
        }

        if (active) {
          setAllSponsors(accumulatedSponsors);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [JSON.stringify(ticketCategoriesState)]);

  return (
    <>
      <section className="tournament-banner">
        <div className="header-in-banner">
          <a href={"/"} title={"Go to homepage"}>
            <img src={HCALogoImg} alt="HCA logo" />
          </a>
          <ManageButtons />
        </div>
        {tournamentState ? (
          <div className="inner-banner">
            <h1 className="tournament-title">{tournamentState.name}</h1>
            <h2 className="tournament-dates">
              <DateRangeFormatter
                startDate={tournamentState.dateStart}
                endDate={tournamentState.dateEnd}
              />
            </h2>
            <h6 className="tournament-locationTitle">
              {tournamentState.locationTitle}
            </h6>
          </div>
        ) : (
          <p>Loading tournament data...</p>
        )}
      </section>

      <div className="main">
        <EventSideBar
          tournamentData={tournamentState}
          roundsData={roundsState}
          ticketCategoriesData={ticketCategoriesState}
          sponsorData={allSponsors}
          // sponsorData={sponsorsState}
        />

        <section className="widget-container">
          {tournamentState ? (
            <div>
              <h3>About The Event</h3>
              <p className="tournament-content">
                {tournamentState.description}
              </p>
            </div>
          ) : (
            <p>Loading description...</p>
          )}

          {widgets
            .filter((widget) => widget.liveFlag)
            .map((widget) => {
              return (
                <WidgetContent
                  key={widget.id}
                  widgetTypeId={widget.widgetTypeId}
                  widgetId={widget.id}
                  widgetTitle={widget.name}
                  jsonContent={widget.content}
                />
              );
            })}
          <Footer></Footer>
        </section>
      </div>
    </>
  );
};
