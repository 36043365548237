export const Footer = () => {
  return (
    <div className="footer">
      <hr />
      <p>
        <a
          href="https://www.hcahopefund.com/privacy-policy/"
          target="_blank"
          rel="external nofollow noopener noreferrer"
        >
          Privacy Policy
        </a>
        <span className="vertical-bar">&#124;</span>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="f-cmp-cookie-pref-tab">
          Cookie Preferences
        </a>
      </p>
    </div>
  );
};
