import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { exitSvg, hamburgerSvg } from "../assets/icons";
import { EuserContext } from "../data/providers/EuserProvider";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import EuserActionItems from "./Euser";
import TournamentActionItems from "./Tournament";

export const AccountNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { tournamentState } = useTournamentStateContext();

  const { euser } = useContext(EuserContext);

  interface LinkItem {
    id: string;
    item: string;
    link: string;
  }

  const accountEditItems: LinkItem[] = [
    {
      id: `general-item-2`,
      // item: "Reservations",
      item: "Tickets and Reservations",
      link: `/account/reservations`,
    },
    {
      id: `general-item-3`,
      // item: "Tickets",
      item: "Entrance Passes",
      link: `/account/tickets`,
    },
    {
      id: `general-item-1`,
      item: "Account",
      link: `/account/edit`,
    },
  ];

  const renderGeneralList = (linkItems: LinkItem[]) => {
    return linkItems.map((m, i) => {
      return (
        <li
          className={`editMenuItem ${
            currentPath === m.link && "menuItemSelected"
          }`}
          key={`general-option-${i}`}
        >
          <button
            type="button"
            className="BlueNavOption"
            onClick={() => {
              navigate(m.link, {
                state: {
                  tournament: tournamentState,
                },
              });
              setShowMobileMenu(false);
            }}
          >
            {m.item}
          </button>
        </li>
      );
    });
  };

  const renderMenu = () => {
    return (
      <>
        {<TournamentActionItems />}

        {/* account edit items visible if the euser is present */}
        {euser ? (
          <div>
            <hr className="mobileBlueNavLine" />
            {renderGeneralList(accountEditItems)}
          </div>
        ) : null}

        {/* Euser Action items */}
        <hr className="mobileBlueNavLine" />
        {<EuserActionItems />}
      </>
    );
  };

  // Below bits are purely used for mobile styles both call renderMenu()

  return (
    <>
      {/* mobile hamburger */}
      <div className="mobileBlueNavWrapper">
        <button
          type="button"
          className="mobileBlueNavOpen"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <img src={hamburgerSvg} alt="open nav menu" />
        </button>

        {showMobileMenu && (
          <section className="mobileBlueNavMenu">
            <button
              type="button"
              className="mobileBlueNavClose"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <img src={exitSvg} alt="close nav menu" />
            </button>
            {renderMenu()}
          </section>
        )}
      </div>

      {/* desktop side nav */}
      <div className="BlueNavWrapper">{renderMenu()}</div>
    </>
  );
};
