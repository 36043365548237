import "../assets/scss/main.scss";

import { Outlet } from "react-router-dom";

import { Footer } from "../components/Footer";
import { AccountNav } from "../navs/AccountNav";

function AccountContainer() {
  return (
    <div className="navContentContainer">
      <nav className="blueNavBar">
        <AccountNav />
      </nav>
      <div className="mainContent">
        <div className="container">
          <Outlet></Outlet>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default AccountContainer;
