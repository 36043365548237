import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import React, { useContext, useEffect, useState } from "react";

import { reorderWidgets as putReorderWidgets } from "../data/httpClient";
import {
  WidgetContext,
  WidgetContextInterface,
} from "../data/providers/WidgetProvider";
import { WidgetDetailPage } from "../pages/WidgetDetailPage";
import { Widget } from "../types";
import CollapsibleWidget from "./CollapsibleWidget";

type SortableListProps = {
  setShowWidgetMenu: React.Dispatch<React.SetStateAction<boolean>>;
  tournamentId: number;
  widgets: Widget[];
};

const SortableList = ({ tournamentId, widgets }: SortableListProps) => {
  const { setWidgets } = useContext(WidgetContext) as WidgetContextInterface;

  const [listItems, setListItems] = useState<
    { id: string; content: React.JSX.Element }[]
  >([]);

  const setData = async () => {
    setListItems(
      widgets
        .sort((a, b) => a.position - b.position)
        .map((widget) => {
          return {
            id: `widget-${widget.id}`,
            content: getWidgetTab(widget),
          };
        })
    );
  };

  useEffect(() => {
    if (widgets && widgets.length && tournamentId) {
      setData();
    }
  }, [widgets, tournamentId]);

  const getWidgetTab = (widget: Widget) => {
    return (
      <CollapsibleWidget
        widgetId={widget.id}
        title={widget.widgetType + ": " + widget.name}
      >
        <div className="contentItemWrapper">
          <WidgetDetailPage tournamentId={tournamentId} widget={widget} />
        </div>
      </CollapsibleWidget>
    );
  };

  const onDragEnd = (result: DropResult) => {
    // Item was dropped outside of a droppable area
    if (!result.destination) {
      return;
    }
    reorderListItems(result.source.index, result.destination.index);
    reorderWidgets(result.source.index + 1, result.destination.index + 1); // +1 compensates for 1-indexing of widget positions
  };

  const reorderListItems = (source: number, destination: number) => {
    const updatedList = Array.from(listItems);
    const [removed] = updatedList.splice(source, 1);
    updatedList.splice(destination, 0, removed);
    setListItems(updatedList);
  };

  const reorderWidgets = async (source: number, destination: number) => {
    if (source === destination) return;

    const updatedWidgets: Widget[] = [];
    for (const widget of widgets) {
      const updatedWidget = { ...widget };
      if (widget.position === source)
        // this is the widget we have moved
        updatedWidget.position = destination;
      else if (widgetPositionShouldDecrement(widget, source, destination))
        updatedWidget.position--;
      else if (widgetPositionShouldIncrement(widget, source, destination))
        updatedWidget.position++;
      updatedWidgets.push(updatedWidget);
    }
    setWidgets(updatedWidgets);
    await putReorderWidgets(updatedWidgets);
  };

  const widgetPositionShouldDecrement = (
    widget: Widget,
    source: number,
    destination: number
  ) => {
    return (
      source < destination &&
      widget.position > source &&
      widget.position <= destination
    );
  };

  const widgetPositionShouldIncrement = (
    widget: Widget,
    source: number,
    destination: number
  ) => {
    return (
      source > destination &&
      widget.position < source &&
      widget.position >= destination
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <ul {...provided.droppableProps} ref={provided.innerRef}>
            {listItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <li
                    className={`draggable-item ${
                      snapshot.isDragging ? "dragged-item" : ""
                    }`}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    {item.content}
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableList;
