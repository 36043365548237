import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import FormFields from "../components/FormFields";
import { getRoundById } from "../data/httpClient";
import { useTournamentStateContext } from "../data/providers/TournamentStateProvider";
import { GetRoundConfig } from "../data/roundConfig";
import { CreateAndEditFieldType, FormDataType } from "../types";
import { constructEmptyForm } from "../utils/constructEmptyForm";

export const EditRoundPage = () => {
  const initialized = useRef(false);

  const { tournamentId, roundId } = useParams();

  const { setTournamentIdState } = useTournamentStateContext();

  const [formData, setFormData] = useState<FormDataType>(
    constructEmptyForm(GetRoundConfig().createAndEditFields)
  );

  const [newCreateAndEditFields, setNewCreateAndEditFields] = useState<
    CreateAndEditFieldType[]
  >([...GetRoundConfig().createAndEditFields]);

  const getData = async () => {
    if (roundId) {
      const round = await getRoundById(parseInt(roundId));

      round.enabledDisabledTicketCategories.forEach(
        (t: {
          ticketCategoryId: number;
          enabled: boolean;
          sponsorFlag: boolean;
          name: string;
        }) => {
          newCreateAndEditFields.push({
            fieldName: `ticketCategoryId-${t.ticketCategoryId}`,
            label: `${t.ticketCategoryId}`,
            inputType: "toggle",
            isRequired: false,
            isMutable: true,
            enabled: t.enabled,
          });
          setNewCreateAndEditFields(newCreateAndEditFields);
          const tempFormData = newCreateAndEditFields.reduce(
            (prev, field) => ({
              ...prev,
              // @ts-ignore
              [field.fieldName]: field.fieldName.includes("ticketCategoryId")
                ? field.enabled
                : round[field.fieldName] ?? formData[field.fieldName],
              id: round.id,
              tournamentId: round.tournamentId,
            }),
            {}
          );
          setFormData(tempFormData);
        }
      );
    }
  };

  useEffect(() => {
    // with react strictMode, useEffect initially runs twice; causing the createAndEditFields getting duplicated data *
    if (!initialized.current) {
      initialized.current = true;
      getData();
      if (tournamentId) {
        setTournamentIdState(parseInt(tournamentId));
      }
    }
  }, [roundId]);

  return (
    <>
      <div className="editPageWrapper">
        <FormFields
          fields={newCreateAndEditFields}
          form={{ method: "edit", data: formData }}
          setFormData={(_, data) => setFormData(data)}
          formMode={"edit"}
          name={GetRoundConfig().name}
          onCreateFunction={GetRoundConfig().onCreate}
          onUpdateFunction={GetRoundConfig().onUpdate}
        />
      </div>
    </>
  );
};
